import DynamicButton from "../components/common/DynamicButton";
import { useNavigate } from "react-router-dom";
import { Topic } from "../utils/types";
import { useUserContext } from "../components/Providers/UserContext";
import Loader from "../components/common/Loader";
import { TopicCard } from "../components/Topic/TopicCard";

interface TopicProps {
  topicList: Topic[];
  isLoading: boolean;
}

const Topics = ({ topicList, isLoading }: TopicProps) => {
  const { hasRole } = useUserContext();
  const navigate = useNavigate();

  return (
    <section className="topic-page-container">
      <div className="topic-page-content">
        {hasRole("Admin") && (
          <div className="new-topic-bar">
            <DynamicButton
              key={1}
              size="medium"
              type="primary"
              addIcon={true}
              onClick={() => navigate("/topicadd")}
            >
              New topic
            </DynamicButton>
          </div>
        )}

        {topicList &&
          topicList.map((topic) => <TopicCard topic={topic} key={topic.id} />)}
        {!topicList && isLoading && <Loader />}
      </div>
    </section>
  );
};

export default Topics;
