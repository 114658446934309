import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import TitleBar from '../components/common/TitleBar';
import BackButton from '../components/layout/BackButton';
import DynamicButton from '../components/common/DynamicButton';
import { useSessionContext } from '../providers/SessionContext';
import CustomSelect from '../components/form/CustomSelect';
import TopicSelect from '../components/form/TopicSelect';
import TutorSelect from '../components/form/TutorSelect';
import SingleSelectBtns from '../components/form/SingleSelectBtns';
import PriceLocationSelect from '../components/form/PriceLocationSelect';
import { TimezoneDateTimePicker } from '../components/form/TimezoneDateTimePicker';
import { useUserContext } from '../components/Providers/UserContext';
import useFetchGSELevel from 'Query/useFetchGSELevel';
import useFetchCategory from 'Query/useFetchCategory';
import useFetchVendors from 'Query/useFetchVendors';
import { saveSession } from '../utils/Network';
import { Topic, Tutor, Vendor, Location } from '../utils/types';
import { isCategory, isVendor, isLocation } from '../utils/utilsts';
import { useTimeZoneContext } from 'providers/TimeZoneProvider';

interface SessionAddProps {
  tutorList: Tutor[];
  topicList: Topic[];
}

export interface SessionData {
  date: Date;
  id?: number | undefined;
  topic?: Topic | null;
  maxParticipants: number;
  // timezone: TimeZone;
  vendorData?: Vendor | null;
  tutor?: Tutor | null;
  category?:
    | {
        id: number;
        category: string;
      }
    | undefined;
  GSELevel?:
    | {
        id: number;
        level: string;
        minGSE: number;
        maxGSE: number;
      }
    | undefined;
  location?:
    | {
        id: number;
        location: string;
        code: string;
      }
    | undefined;
  joinLink?: string;
}

const SessionAdd = ({ tutorList, topicList }: SessionAddProps) => {
  const { session, setSession } = useSessionContext();
  const { timezoneOffset } = useTimeZoneContext();
  const navigate = useNavigate();
  const { userToken } = useUserContext();
  const { data: GSELevels } = useFetchGSELevel(userToken);
  const { data: Categories } = useFetchCategory(userToken);
  const { data: Vendors } = useFetchVendors(userToken);

  const [selectedGSELevel, setSelectedGSELevel] = useState(session?.GSELevel);
  const [selectedCategory, setSelectedCategory] = useState(session?.category);
  const [selectedLocation, setSelectedLocation] = useState(session?.location);
  const [selectedVendor, setSelectedVendor] = useState(session?.vendorData || null);

  const topicListInitialFilter = topicList.filter((topic) => !topic.contentSensitivity);
  const [topicListFilter, setTopicListFilter] = useState(topicListInitialFilter);
  const [tutorListFilter, setTutorListFilter] = useState(tutorList);

  const [sessionData, setSessionData] = useState<SessionData>({
    date: session?.date || new Date(new Date(new Date().setHours(12, 0, 0, 0))),
    // utcTimeDateTimezoneString: session?.utcTimeDateTimezoneString || ,
    id: session?.id,
    maxParticipants: session?.maxParticipants || 6,
    topic: session?.topic || null,
    vendorData: session?.vendorData || selectedVendor,
    tutor: session?.tutor || null,
    // timezone: session?.timezone || { label: "US/Eastern", offset: "(UTC -5)" },
    category: session?.category || selectedCategory,
    GSELevel: session?.GSELevel || selectedGSELevel,
    location: session?.location || selectedLocation,
  });

  // form validation error states:
  const [isShowTopicRequired, setIsShowTopicRequired] = useState<boolean>(false);
  const [isShowVendorRequired, setIsShowVendorRequired] = useState<boolean>(false);

  const verifyRequiredFields = (data: SessionData): boolean => {
    // console.log('sessionData: ', data);

    if (!data.date) {
      return false;
    }
    if (data.maxParticipants && typeof data.maxParticipants !== 'number') {
      return false;
    }
    if (!data.topic) {
      return false;
    }
    if (!data.GSELevel) {
      return false;
    }
    if ((data.category && !isCategory(data.category)) || !data.category) {
      return false;
    }
    if (data.location !== undefined && !isLocation(data.location)) {
      return false;
    }
    if ((data.vendorData && !isVendor(data.vendorData)) || !data.vendorData) {
      return false;
    }
    return true; // All checks passed
  };

  const getFullGSEByMax = (max: number) => {
    return GSELevels?.find((sLevel) => {
      return max >= sLevel.minGSE && max <= sLevel.maxGSE;
    });
  };

  const [isCreateSessionDisabled, setIsCreateSessionDisabled] = useState<boolean>(
    !verifyRequiredFields(sessionData),
  );

  const handleTopicChange = (selectedTopic: Topic) => {
    if (isShowTopicRequired) {
      setIsShowTopicRequired(false);
    }
    setSelectedCategory(selectedTopic.category);
    if (!sessionData.GSELevel) {
      const GSELevelFromTopic = getFullGSEByMax(selectedTopic.GSELevel.maxGSE);
      let updatedTopicList: Topic[] | undefined = filterTopicByCategory(
        topicListInitialFilter,
        selectedTopic.category.id,
      );
      if (GSELevelFromTopic) {
        updatedTopicList = filterTopicByGSE(
          updatedTopicList,
          GSELevelFromTopic.minGSE,
          GSELevelFromTopic.maxGSE,
        );
      }
      setSelectedGSELevel(GSELevelFromTopic);
      if (updatedTopicList) {
        setTopicListFilter(updatedTopicList);
      }
      setSessionData((prevData) => ({
        ...prevData,
        topic: selectedTopic,
        GSELevel: GSELevelFromTopic,
        category: selectedTopic.category,
      }));
    } else {
      setSessionData((prevData) => ({
        ...prevData,
        topic: selectedTopic,
        category: selectedTopic.category,
      }));
    }
    setIsCreateSessionDisabled(!verifyRequiredFields(sessionData));
  };

  const handleTutorChange = (selectedTutor: Tutor | undefined | null) => {
    if (selectedTutor) {
      setSessionData((prevData) => ({
        ...prevData,
        tutor: selectedTutor,
        vendorData: selectedTutor.vendorData,
        location: selectedTutor.location,
      }));
      setSelectedVendor(selectedTutor.vendorData);
      if (selectedTutor.location && selectedTutor.location.location === 'Other') {
        setSelectedLocation(undefined);
      } else {
        setSelectedLocation(selectedTutor.location);
      }
      setIsCreateSessionDisabled(!verifyRequiredFields(sessionData));
    }
  };

  const queryClient = useQueryClient();

  const sessionMutation = useMutation({
    mutationFn: () => saveSession(sessionData, userToken),
    onSuccess: (newSession) => {
      queryClient.setQueryData(
        ['sessions', dayjs(newSession.date).format('YYYY-MM-DD'), timezoneOffset],
        (oldSessions: SessionData[]) => [...oldSessions, newSession],
      );
    },
    onSettled: (updatedSession) => {
      queryClient.invalidateQueries({
        queryKey: ['sessions', dayjs(updatedSession.date).format('YYYY-MM-DD')],
      });
    },
  });

  useMemo(() => {
    setSessionData((prevFormData) => ({
      ...prevFormData,
      category: selectedCategory,
      GSELevel: selectedGSELevel,
      location: selectedLocation?.location === 'Any location' ? undefined : selectedLocation,
      vendorData: selectedVendor,
    }));
  }, [selectedGSELevel, selectedLocation, selectedCategory, selectedVendor]);

  const filterTopicByCategory = (topics: Topic[], categoryId: number) => {
    return topics.filter((topic) => topic.category.id === categoryId);
  };

  const filterTutorByCategory = (tutors: Tutor[], categoryId: number) => {
    return tutors?.filter((tutor) =>
      tutor.category.some((sCategory) => sCategory.id === categoryId),
    );
  };

  const filterTopicByGSE = (topics: Topic[] | undefined, minGSE: number, maxGSE: number) => {
    return topics?.filter((topic) => {
      for (let i = topic.GSELevel.minGSE; i <= topic.GSELevel.maxGSE; i++) {
        if (i >= minGSE && i <= maxGSE) {
          return true;
        }
      }
      return false;
    });
  };

  const filterTutorByGSE = (tutors: Tutor[], gseLevelId: number) => {
    return tutors.filter((tutor) => tutor.GSELevel.some((sLevel) => sLevel.id === gseLevelId));
  };

  const filterTutorByLocation = (tutors: Tutor[], locationId: number) => {
    return tutors.filter((sTutor) => sTutor.location?.id === locationId);
  };

  const filterTutorByVendor = (tutors: Tutor[], vendorId: number) => {
    return tutors.filter((sTutor) => sTutor.vendorData.id === vendorId);
  };

  const handleCategoryChange = (name: string, category: string) => {
    const sCategory = Categories?.find((cat) => cat.category === category);
    if (sCategory) {
      setSelectedCategory(sCategory);

      let updatedTopicList: Topic[] | undefined = filterTopicByCategory(
        topicListInitialFilter,
        sCategory.id,
      );
      if (selectedGSELevel) {
        updatedTopicList = filterTopicByGSE(
          updatedTopicList,
          selectedGSELevel.minGSE,
          selectedGSELevel.maxGSE,
        );
      }
      if (updatedTopicList) {
        setTopicListFilter(updatedTopicList);
      }
      // reset topic
      setSessionData((prevData) => ({
        ...prevData,
        topic: null,
      }));

      checkTutorByCategory(sCategory.id);
    }
  };

  const handleGSELevelChange = (name: string, cLevel: string) => {
    const sGSELevel = GSELevels?.find((level) => level.level === cLevel);
    if (sGSELevel) {
      setSelectedGSELevel(sGSELevel);

      let updatedTopicList: Topic[] | undefined = filterTopicByGSE(
        topicListInitialFilter,
        sGSELevel.minGSE,
        sGSELevel.maxGSE,
      );
      if (selectedCategory && updatedTopicList) {
        updatedTopicList = filterTopicByCategory(updatedTopicList, selectedCategory.id);
      }
      if (updatedTopicList) {
        setTopicListFilter(updatedTopicList);
      }
      // reset topic
      setSessionData((prevData) => ({
        ...prevData,
        topic: null,
      }));

      checkTutorByGSE(sGSELevel.id);
    }
  };

  const checkTutorByGSE = (GSELevelId: number) => {
    if (!sessionData?.tutor) {
      return;
    } else if (GSELevelId) {
      if (!sessionData.tutor.GSELevel?.some((level) => level.id === GSELevelId)) {
        setSessionData((prevData) => ({
          ...prevData,
          tutor: null,
          vendorData: null,
        }));
      }
    }
  };

  const checkTutorByCategory = (categoryId: number) => {
    if (!sessionData?.tutor) {
      return;
    } else if (categoryId) {
      if (!sessionData.tutor.GSELevel?.some((category) => category.id === categoryId)) {
        setSessionData((prevData) => ({
          ...prevData,
          tutor: null,
          vendorData: null,
        }));
      }
    }
  };

  const checkTutorFilterChangeByLocation = (location?: string) => {
    if (!sessionData?.tutor) {
      return;
    } else if (location) {
      // case 2: either sessionData location or vendor does not match current tutor, reset tutor:
      if (
        sessionData.vendorData?.id !== sessionData.tutor.vendorData.id ||
        location !== sessionData.tutor.location?.location
      ) {
        setSessionData((prevData) => ({
          ...prevData,
          tutor: null,
          vendorData: null,
        }));
      }
    }
  };

  const checkTutorFilterChangeByVendor = (vendorId: number) => {
    if (!sessionData?.tutor) {
      return;
    } else if (sessionData.vendorData) {
      // case 2: either sessionData location or vendor does not match current tutor, reset tutor:
      if (vendorId !== sessionData.tutor.vendorData.id) {
        setSessionData((prevData) => ({
          ...prevData,
          tutor: null,
        }));
      }
    }
  };

  const handleLocationChange = (selectedLocation: Location | null) => {
    if (selectedLocation?.location) {
      setSelectedLocation(selectedLocation);
      checkTutorFilterChangeByLocation(selectedLocation.location);
    } else {
      setSelectedLocation(undefined);
    }
  };

  const handleVendorSelect = (name: string, vendor: string) => {
    const sVendor = Vendors?.find((ven) => ven.vendor === vendor);
    if (sVendor) {
      setSelectedVendor(sVendor);
      checkTutorFilterChangeByVendor(sVendor?.id);
    }
    checkTutorFilterChangeByLocation();
  };

  const handleSubmit = () => {
    if (!sessionData.vendorData) {
      setIsShowVendorRequired(true);
    }
    if (!sessionData.topic) {
      setIsShowTopicRequired(true);
    } else {
      sessionMutation.mutate();
      navigate('/publicsessions');
    }
  };

  useEffect(() => {
    // console.log('sesssionData: ', sessionData);
    setIsCreateSessionDisabled(!verifyRequiredFields(sessionData));
  }, [sessionData]);

  const filterTutorList = () => {
    let updatedTutorList = [...tutorList];
    if (sessionData.category?.id) {
      updatedTutorList = filterTutorByCategory(updatedTutorList, sessionData.category.id);
    }
    if (sessionData.GSELevel?.id) {
      updatedTutorList = filterTutorByGSE(updatedTutorList, sessionData.GSELevel.id);
    }
    if (sessionData.vendorData) {
      updatedTutorList = filterTutorByVendor(updatedTutorList, sessionData.vendorData.id);
    }
    if (sessionData.location) {
      updatedTutorList = filterTutorByLocation(updatedTutorList, sessionData.location.id);
    }
    setTutorListFilter(updatedTutorList);
  };

  useEffect(() => {
    // filter tutors when affecting fields change:
    filterTutorList();
  }, [sessionData?.GSELevel, sessionData.category, sessionData.vendorData, sessionData.location]);

  const handleBackButtonClick = () => {
    setSession(null);
  };

  const handleChangeCustom = (name: string, value: string) => {
    setSessionData((prevData) => ({ ...prevData, [name]: value }));
    setIsCreateSessionDisabled(!verifyRequiredFields(sessionData));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <section className="session-edit-section">
        <div className="session-edit-container">
          <BackButton navigatePath={'/publicsessions'} onClick={handleBackButtonClick} />
          <div className="session-add-title">Create new session</div>
          <div className="session-add-data-container">
            <div className="session-add-info-container">
              <TimezoneDateTimePicker handleChangeCustom={handleChangeCustom} name={'date'} />
            </div>
            <div>
              <TitleBar
                title={'Learner proficiency and content type'}
                iconType={'teachingContent'}
              />
              <div className="session-add-data-content">
                {Categories && (
                  <div>
                    <SingleSelectBtns
                      onChange={handleCategoryChange}
                      value={sessionData.category?.category}
                      label={'Category'}
                      name={'category'}
                      selectOptions={Categories.map((category) => category.category)}
                    />
                  </div>
                )}
                {GSELevels && (
                  <div>
                    <SingleSelectBtns
                      onChange={handleGSELevelChange}
                      value={sessionData.GSELevel?.level}
                      label={'CEFR/GSE level'}
                      name={'GSELevel'}
                      selectOptions={GSELevels.map((level) => level.level)}
                    />
                  </div>
                )}
                <div>
                  <TopicSelect
                    topicList={topicListFilter}
                    onTopicChange={handleTopicChange}
                    value={sessionData.topic}
                    language={'en_us'}
                    error={isShowTopicRequired}
                  />
                </div>
              </div>
            </div>
            <div className="session-add-info-container">
              <TitleBar title={'Tutor'} iconType={'person'} />
              <div>
                <PriceLocationSelect
                  onChange={handleLocationChange}
                  sessionLocation={sessionData?.location}
                  withAny
                  name={'location'}
                  label={'Regional tutor rate'}
                />
              </div>

              <div>
                {Vendors && (
                  <CustomSelect
                    onChange={handleVendorSelect}
                    value={sessionData.vendorData?.vendor}
                    name={'vendorData'}
                    selectOptions={Vendors.map((vendor) => vendor.vendor).sort((a, b) =>
                      a.localeCompare(b),
                    )}
                    label={'Vendor'}
                    error={isShowVendorRequired}
                  />
                )}
              </div>
              <div>
                <TutorSelect
                  tutorList={tutorListFilter}
                  optional
                  onTutorChange={handleTutorChange}
                  value={sessionData?.tutor}
                  disable={!sessionData.GSELevel || !sessionData.category}
                  disableMsg={'Please select Category and CEFR/GSE level first'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="session-add-action-btn-container">
          <div className="topic-edit-action-btns">
            <div className="submit-btns">
              <DynamicButton
                size="large"
                type="primary"
                disable={isCreateSessionDisabled}
                onClick={() => handleSubmit()}
              >
                {session ? 'Save changes' : 'Publish session'}
              </DynamicButton>
              <DynamicButton
                size="large"
                type="tertiary"
                onClick={() => {
                  navigate('/publicsessions');
                }}
              >
                Cancel
              </DynamicButton>
            </div>
          </div>
        </div>
      </section>
    </LocalizationProvider>
  );
};

export default SessionAdd;
