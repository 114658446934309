import { useNavigate } from "react-router-dom";

interface BackButtonProps {
  navigatePath: string;
  onClick?: () => void;
}

function BackButton({ navigatePath, onClick }: BackButtonProps) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate(navigatePath);
  };

  return (
    <div className="back-container" onClick={handleClick}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.705 7.41L14.295 6L8.29498 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z"
          fill="#007A9C"
        />
      </svg>
      <div className="back-text">Back</div>
    </div>
  );
}

export default BackButton;
