import { useEffect, useState, useMemo } from 'react';
import TitleBar from '../common/TitleBar';
import Avatar from '../common/Avatar';
import CustomSelect from '../form/CustomSelect';
import PinkBtn from '../common/PinkBtn';
import DynamicButton from '../common/DynamicButton';
import TextFieldCustom from '../form/TextFieldCustom';
import blankAvatarImage from '../../assets/image/blank-avatar-24.png';
import fileUploadIcon from '../../assets/icons/file_upload.png';
import fileUpdateIcon from '../../assets/icons/edit-32px.png';
import fileDeleteIcon from '../../assets/icons/delete-32px.png';
import 'react-week-calendar/dist/style.css';
import { Box } from '@mui/material';
import BackButton from '../layout/BackButton';
import { useTutorContext } from '../../providers/TutorContext';
import { Category, GSELevel, Genders, TimeZone, Location } from '../../utils/types';
import { allGSELevels, allCategories, isVendor } from '../../utils/utilsts';
import { TimezonePicker } from '../form/TimezonePicker';
import { useUserContext } from '../../components/Providers/UserContext';
import PriceLocationSelect from '../form/PriceLocationSelect';
import useFetchGSELevel from 'Query/useFetchGSELevel';
import useFetchCategory from 'Query/useFetchCategory';
import useFetchVendors from 'Query/useFetchVendors';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { tutoringBasePath, updateTutor, uploadThumbnail } from '../../utils/Network';
import useFetchTutors from 'Query/useFetchTutors';

export type TutorAddEdit = {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  thumb?: string;
  gender?: Genders;
  timeZone?: TimeZone;
  location?: Location;
  vendorData?: {
    id: number;
    vendor: string;
  };
  vendorId?: number;
  GSELevel?: GSELevel[];
  category?: Category[];
};

const TutorAdd = () => {
  const { tutor, setTutor } = useTutorContext();
  const navigate = useNavigate();
  const { userToken } = useUserContext();
  const { data: GSELevels } = useFetchGSELevel(userToken);
  const { data: Categories } = useFetchCategory(userToken);
  const { data: Vendors } = useFetchVendors(userToken);
  const { data: tutorsList } = useFetchTutors(userToken);
  const [selectedGSELevels, setSelectedGSELevels] = useState(tutor?.GSELevel || allGSELevels);
  const [selectedCategories, setSelectedCategories] = useState(tutor?.category || allCategories);
  const [selectedLocation, setSelectedLocation] = useState(tutor?.location);
  const [selectedVendor, setSelectedVendor] = useState(tutor?.vendorData);

  const isValidEmail = (value: string) => {
    return /\S+@\S+\.\S+/.test(value);
  };
  const isExistEmail = (value: string) => {
    return tutorsList?.some((tutor) => tutor.email === value);
  };

  const [tutorData, setTutorData] = useState<TutorAddEdit>({
    id: tutor?.id,
    firstName: tutor?.firstName,
    lastName: tutor?.lastName,
    thumb: tutor?.thumb,
    gender:
      tutor && tutor.gender
        ? Genders[tutor.gender as unknown as 'MALE' | 'FEMALE' | 'OTHER' | 'NOTSAY']
        : Genders.NOTSAY,
    timeZone: tutor?.timeZone,
    vendorData: selectedVendor,
    vendorId: tutor?.vendorId,
    email: tutor?.email,
    GSELevel: selectedGSELevels,
    category: selectedCategories,
    location: selectedLocation,
  });

  const queryClient = useQueryClient();

  const tutorMutation = useMutation({
    mutationFn: () => updateTutor(tutorData, userToken),
    onSuccess: (updatedTutor) => {
      queryClient.setQueryData(['tutors'], (oldTutorList: TutorAddEdit[]) =>
        oldTutorList.map((cTutor: TutorAddEdit) =>
          cTutor.id === updatedTutor.id ? updatedTutor : cTutor,
        ),
      );
    },
  });

  const handleGSELevelClick = (level: GSELevel) => {
    setSelectedGSELevels((prevSelectedLevels) =>
      prevSelectedLevels && prevSelectedLevels.some((sGSElv) => sGSElv.id === level.id)
        ? prevSelectedLevels.filter((l) => l.id !== level.id)
        : prevSelectedLevels && [
            ...prevSelectedLevels,
            {
              id: level.id,
              level: level.level,
              code: level.code,
              minGSE: level.minGSE,
              maxGSE: level.maxGSE,
            },
          ],
    );
    setIsCreateProfileDisabled(!verifyRequiredFields(tutorData));
  };

  const handleCategoryClick = (category: Category) => {
    setSelectedCategories((prevSelectedCategories) =>
      prevSelectedCategories && prevSelectedCategories.some((sCat) => sCat.id === category.id)
        ? prevSelectedCategories.filter((c) => c.id !== category.id)
        : prevSelectedCategories && [
            ...prevSelectedCategories,
            { id: category.id, category: category.category },
          ],
    );
    setIsCreateProfileDisabled(!verifyRequiredFields(tutorData));
  };

  const handleLocationChange = (selectedLocation: Location | null) => {
    // const sLocation = Locations?.find((loc) => locationFormatted.includes(loc.location));
    if (selectedLocation) {
      setSelectedLocation(selectedLocation);
      setIsCreateProfileDisabled(!verifyRequiredFields(tutorData));
    }
  };

  // const locationFormatted = (location: Location | undefined) => {
  //   if (location) {
  //     return `${location?.code?.length ? location.code + ' - ' : ''}${location.location}`;
  //   }
  //   return 'Other';
  // };

  // const locationsFormatted = () => {
  //   if (Locations) {
  //     return Locations?.map((loc) => `${loc.code.length ? loc.code + ' - ' : ''}${loc.location}`);
  //   }
  //   return [];
  // };

  useMemo(() => {
    setTutorData((prevFormData) => ({
      ...prevFormData,
      category: selectedCategories,
      GSELevel: selectedGSELevels,
      location: selectedLocation,
      vendorData: selectedVendor,
    }));
  }, [selectedGSELevels, selectedLocation, selectedCategories, selectedVendor]);

  const [emailErrorMsg, setEmailErrorMsg] = useState<undefined | string>(undefined);

  const verifyRequiredFields = (data: TutorAddEdit): boolean => {
    if (!data.thumb) {
      return false;
    }
    if (!data.firstName?.trim()) {
      return false;
    }
    if (!data.lastName?.trim()) {
      return false;
    }
    if (!data.gender) {
      return false;
    }
    if (!data.timeZone) {
      return false;
    }
    if (!data.location) {
      return false;
    }
    if (!data.email || emailErrorMsg) {
      return false;
    }
    if ((data.vendorData && !isVendor(data.vendorData)) || !data.vendorData) {
      return false;
    }
    if (!data.vendorId) {
      return false;
    }
    if (!data.GSELevel || !data.GSELevel.length) {
      return false;
    }
    if (!data.category || !data.category.length) {
      return false;
    }

    return true; // All checks passed
  };

  const [isCreateProfileDisabled, setIsCreateProfileDisabled] = useState<boolean>(
    !verifyRequiredFields(tutorData),
  );

  useEffect(() => {
    setIsCreateProfileDisabled(!verifyRequiredFields(tutorData));
    // console.log('tutorData: ', tutorData);
  }, [tutorData]);

  const handleChangeCustom = (name: string, value: string) => {
    setTutorData((prevData) => ({
      ...prevData,
      [name]: name === 'vendorId' ? (value ? parseInt(value) : '') : value,
    }));
    setIsCreateProfileDisabled(!verifyRequiredFields(tutorData));
  };

  const handleVendorSelect = (name: string, vendor: string) => {
    const sVendor = Vendors?.find((ven) => ven.vendor === vendor);
    if (sVendor) {
      setSelectedVendor(sVendor);
    }
  };

  const handleEmailChange = (name: string, value: string) => {
    // Basic email validation using regex

    setTutorData((prevData) => ({
      ...prevData,
      email: value,
    }));
    let updatedEmailErrMsg = isValidEmail(value) ? undefined : 'Invalid email format';
    if (!updatedEmailErrMsg && !tutorData.id) {
      updatedEmailErrMsg = !isExistEmail(value) ? undefined : 'Email address already exist';
    }
    setEmailErrorMsg(updatedEmailErrMsg);
  };

  const handleTimeZoneChange = (val: TimeZone) => {
    setTutorData((prevData) => ({
      ...prevData,
      timeZone: val,
    }));
  };

  const handleEditCreateTutor = () => {
    tutorMutation.mutate();
    navigate('/tutor');
  };

  const handleBackButtonClick = () => {
    setTutor(tutor ? tutor : null);
    navigate(tutor ? '/tutorview' : '/tutor');
  };

  const onThumbUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target.files?.[0];
    if (fileInput && tutorData.firstName) {
      const thumb = await uploadThumbnail(fileInput, tutorData.firstName, userToken);
      if (thumb) {
        handleChangeCustom('thumb', `${tutoringBasePath()}/images/thumbs/${thumb}`);
      }
    }
  };

  return (
    <section className="tutor-edit-section">
      <div className="tutor-edit-container">
        <BackButton
          navigatePath={tutor ? '/tutorview' : '/tutor'}
          onClick={handleBackButtonClick}
        />
        <div className="actions-bar">
          <div className="tutor-name"> {tutor ? 'Edit tutor profile' : 'Add tutor'}</div>
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
          }}
        >
          <div className="general-title">
            <TitleBar title={'General'} iconType={'person'} />
            <div className="general-details inputs">
              <div className="profile-photo">
                <div className="photo-side">
                  <p>profile photo</p>
                  <Avatar width={72} thumb={tutorData.thumb || blankAvatarImage} />
                </div>
                <div className="upload-btn-side">
                  {tutorData.thumb ? (
                    <>
                      <DynamicButton
                        type={'quaternary'}
                        size={'medium'}
                        disable={!tutorData.firstName?.trim()}
                      >
                        <img
                          src={fileUpdateIcon}
                          alt=""
                          className="upload-img-icon dynamic-btn-img-quaternary"
                        />
                        Update picture
                        <input
                          type="file"
                          onChange={(e) => {
                            onThumbUpload(e);
                          }}
                          hidden
                        />
                      </DynamicButton>
                      <DynamicButton
                        type={'quaternary'}
                        size={'medium'}
                        onClick={() => handleChangeCustom('thumb', '')}
                      >
                        <img
                          src={fileDeleteIcon}
                          alt=""
                          className="upload-img-icon dynamic-btn-img-quaternary"
                        />
                        Remove picture
                      </DynamicButton>
                    </>
                  ) : (
                    <>
                      <DynamicButton
                        type={'quaternary'}
                        size={'medium'}
                        disable={!tutorData.firstName?.trim()}
                      >
                        <input
                          hidden
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            onThumbUpload(e);
                          }}
                        />
                        <img
                          src={fileUploadIcon}
                          alt=""
                          className="upload-img-icon dynamic-btn-img-quaternary"
                        />
                        Upload picture
                      </DynamicButton>
                      <p>JPG, GIF or PNG</p>
                    </>
                  )}
                </div>
              </div>
              <div className="inputs-list">
                <div className="name-inputs">
                  <TextFieldCustom
                    label="First Name"
                    name="firstName"
                    value={tutorData.firstName}
                    onChange={handleChangeCustom}
                  />
                  <TextFieldCustom
                    label="Last Name"
                    name="lastName"
                    value={tutorData.lastName}
                    onChange={handleChangeCustom}
                  />
                </div>

                <CustomSelect
                  onChange={handleChangeCustom}
                  value={tutorData.gender}
                  name={'gender'}
                  selectOptions={Object.values(Genders)}
                  label={'Gender'}
                />

                <TimezonePicker changeTimeZone={handleTimeZoneChange} value={tutorData.timeZone} />

                <PriceLocationSelect
                  onChange={handleLocationChange}
                  tutorLocation={tutorData?.location}
                  name={'location'}
                  label={'Regional tutor rate'}
                />

                {Vendors && (
                  <CustomSelect
                    onChange={handleVendorSelect}
                    value={tutorData?.vendorData?.vendor}
                    name={'vendor'}
                    selectOptions={Vendors.map((vendor) => vendor.vendor).sort((a, b) =>
                      a.localeCompare(b),
                    )}
                    label={'Vendor'}
                  />
                )}

                <TextFieldCustom
                  label="Vendor Id"
                  name="vendorId"
                  isNumber
                  value={tutorData.vendorId?.toString() || ''}
                  onChange={handleChangeCustom}
                />
              </div>
            </div>
          </div>

          <div className="contact-details">
            <TitleBar title={'Contact details'} iconType={'contact'} />
            <div className="contact-details inputs-list">
              <TextFieldCustom
                label="Email"
                name="email"
                value={tutorData.email}
                onChange={handleEmailChange}
                error={!!emailErrorMsg}
                helperText={emailErrorMsg}
              />
            </div>
          </div>
          <div className="teaching-content">
            <TitleBar title={'Teaching content'} iconType={'teachingContent'} />
            <div className="teaching-content-input">
              <p className="form-label">CEFR/GSE level</p>
              <div className="choice-btns-list">
                {GSELevels?.map((level, index) => (
                  <PinkBtn
                    small
                    key={index}
                    isSelected={
                      selectedGSELevels &&
                      selectedGSELevels?.some((sGSElv) => sGSElv.id === level.id)
                    }
                    onClick={() => handleGSELevelClick(level)}
                  >
                    {level.level}
                  </PinkBtn>
                ))}{' '}
              </div>
            </div>

            <div className="teaching-content-input">
              <p className="form-label">Category</p>
              <div className="choice-btns-list">
                {Categories?.map((category, index) => (
                  <PinkBtn
                    key={index}
                    isSelected={selectedCategories?.some((sCat) => sCat.id === category.id)}
                    onClick={() => handleCategoryClick(category)}
                    globeIcon={category.category === 'General English'}
                    grammarIcon={category.category === 'Grammar'}
                    briefcaseIcon={category.category === 'Workplace English'}
                    pinDropIcon={category.category === 'Specific purpose'}
                  >
                    {category.category}
                  </PinkBtn>
                ))}{' '}
              </div>
            </div>
          </div>

          {/* <div className="availability">
            <TitleBar title={"Availability"} iconType={"availability"} />
            <p className="sub-title">Suitable times for live sessions</p>
            <p>
              Select suitable time slots. These will be considered when
              allocating live sessions.
            </p>
            <AvailabilitySelector
              onSelectionChange={(availability) =>
                onSelectionChange(availability)
              }
            />
          </div> */}
        </Box>
        <div className="submit-btns">
          <DynamicButton
            size="large"
            type="primary"
            disable={isCreateProfileDisabled}
            onClick={() => handleEditCreateTutor()}
          >
            {tutor ? 'Save changes' : 'Create profile'}
          </DynamicButton>
          <DynamicButton size="large" type="tertiary" onClick={handleBackButtonClick}>
            Cancel
          </DynamicButton>
        </div>
      </div>
    </section>
  );
};

export default TutorAdd;
