import { useQuery } from "@tanstack/react-query";
import { tutoringApiUrl } from "../utils/Network";
import { MondlyTopic } from "../utils/types";


const useFetchMondlyTopics = (token: string) => useQuery<MondlyTopic[]>({
    queryKey: ['mondlyTopics'],
    queryFn: () =>
        fetch(`${tutoringApiUrl()}/_internal/getMondlyTopics`,
    //   fetch(`http://localhost:8080/_internal/getMondlyTopics`, 
      {method: 'Get', 
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }}).then((res) =>
        res.json(),
      ),
      staleTime: Infinity,
      gcTime: 3600 + 300,
  });

export default useFetchMondlyTopics;