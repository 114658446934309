import { useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import PublicSessions from './pages/PublicSessions';
import PrivateSessions from './pages/PrivateSessions';
import Tutors from './pages/Tutors';
import TutorAddEdit from './components/Tutor/TutorAddEdit';
import TutorView from './components/Tutor/TutorView';
import TopicView from './components/Topic/TopicView';
import Topics from './pages/Topics';
import SessionAdd from './pages/SessionAdd';
import Playground from './pages/Playground';
import Analytics from './pages/Analytics';
import Block from './pages/Block';
import { UserProvider, useUserContext } from './components/Providers/UserContext';
import { TimeZoneProvider } from './providers/TimeZoneProvider';
import { TutorProvider } from './providers/TutorContext';
import { LoginScreen } from '@yoda/components-core';
import NavHeader from './components/layout/NavHeader';
import { SessionProvider } from 'providers/SessionContext';
import TopicAdd from './pages/TopicAdd';
import { TopicProvider } from 'providers/TopicContext';
import { PrivateGroupsProvider } from './providers/privateGroupsContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useFetchTopics from './Query/useFetchTopics';
import useFetchTutors from './Query/useFetchTutors';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});
const AppBody = () => {
  const { pathname } = useLocation();
  const { userToken, user, hasRole, azureAPI, init } = useUserContext();
  const [fullPath] = useState<string>(window.location.href.replace(window.location.origin, ''));

  const {
    data: allTopics = [],
    error: TopicsError,
    isPending: PendingTopics,
  } = useFetchTopics(userToken);

  if (TopicsError) {
    console.log('An error has occurred: ' + TopicsError.message);
  }

  const {
    data: tutorList = [],
    error: TutorsError,
    isPending: PendingTutors,
  } = useFetchTutors(userToken);

  if (TutorsError) {
    console.log('An error has occurred: ' + TutorsError.message);
  }

  const queryParams = new URLSearchParams(window.location.search);
  const noAzureLogin = queryParams.get('noAzure') && queryParams.get('noAzure') === '1';

  if (!noAzureLogin) {
    if (!init) return null;
    if (!user && pathname !== '/') {
      return <Navigate replace to="/" />;
    }

    if (!user) {
      return <LoginScreen azureAPI={azureAPI} redirectTo={fullPath} />;
    }

    if (user && !(hasRole('Admin') || hasRole('Tutor'))) {
      return (
        <div
          style={{
            width: 500,
            margin: '100px auto',
            textAlign: 'center',
            fontSize: 20,
          }}
        >
          You do not have permission to access the Tutoring Admin. Please go to MyAccess and request
          access
        </div>
      );
    }
  }

  return (
    <div className="page-container">
      <NavHeader />
      {user ? (
        <Routes>
          {/* public */}
          <Route path="/" element={<PublicSessions />} />
          <Route path="/publicsessions" element={<PublicSessions />} />
          <Route
            path="/privatesessions/:groupId/*"
            element={<PrivateSessions tutorList={tutorList} topicList={allTopics} />}
          />
          <Route
            path="/sessionadd"
            element={<SessionAdd tutorList={tutorList} topicList={allTopics} />}
          />
          {/* tutor */}
          <Route
            path="/tutor"
            element={<Tutors tutorList={tutorList} isLoading={PendingTutors} />}
          />
          <Route path="/tutoradd" element={<TutorAddEdit />} />
          <Route path="/tutorview" element={<TutorView />} />
          {/* topic */}
          <Route
            path="/topics"
            element={<Topics topicList={allTopics} isLoading={PendingTopics} />}
          />
          <Route path="/topicview" element={<TopicView />} />
          <Route path="/topicadd" element={<TopicAdd />} />
          {/* playground */}
          <Route path="/playground" element={<Playground />} />
          <Route path="/block" element={<Block />} />
          <Route path="/analytics" element={<Analytics />} />
        </Routes>
      ) : (
        <div className="page-container-loader">Loader</div>
      )}
    </div>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <TutorProvider>
          <SessionProvider>
            <TopicProvider>
              <TimeZoneProvider>
                <PrivateGroupsProvider>
                  <AppBody />
                </PrivateGroupsProvider>
              </TimeZoneProvider>
            </TopicProvider>
          </SessionProvider>
        </TutorProvider>
      </UserProvider>
    </QueryClientProvider>
  );
}

export default App;
