/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import TextBtn from '../common/TextBtn';
import DynamicButton from '../common/DynamicButton';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import utc from 'dayjs/plugin/utc';
import { useTimeZoneContext } from '../../providers/TimeZoneProvider';
import { useUserContext } from '../Providers/UserContext';
import { getDailySessionsExists } from '../../utils/Network';
import useRemoveAllUpcomingSessionsByGroup from '../../useMutation/useRemoveAllUpcomingSessionsByGroup';
import { Children } from '@utils/types';

type DateHasSessionsMap = {
  [key: string]: boolean;
};
interface SessionDashboardDateNavProps {
  addBtnRoute?: string;
  dailySessionsDate: Date | string | dayjs.Dayjs;
  setDailySessionsDate: (date: dayjs.Dayjs) => void;
  groupId?: number | null;
  orgData?: Children | null;
  isPrivateGroups?: boolean;
  groupTimezoneOffset?: number | null;
}

const SessionDashboardDateNav = ({
  addBtnRoute,
  dailySessionsDate,
  setDailySessionsDate,
  groupId = null,
  orgData = null,
  isPrivateGroups = false,
  groupTimezoneOffset = null,
}: SessionDashboardDateNavProps) => {
  dayjs.extend(utc);
  const navigate = useNavigate();
  const { userToken } = useUserContext();
  const { timezoneOffset } = useTimeZoneContext();
  const [dashboardDate, setDashboardDate] = useState(
    isPrivateGroups ? dayjs(dailySessionsDate) : dayjs(),
  );
  const { mutateAsync: removeAllUpcomingSessionsPrivate } = useRemoveAllUpcomingSessionsByGroup();

  // add btn private groups:
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [sessionsMap, setSessionsMap] = useState<DateHasSessionsMap>({});
  const handleAddPrivateClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  const open = Boolean(anchor);
  const id = open ? 'add-popup' : undefined;

  const handleDateChange = (val: dayjs.Dayjs) => {
    setDashboardDate(val);
    setDailySessionsDate(val);
  };

  useEffect(() => {
    handleDateChange(dayjs());
  }, []);

  const datePickerCustomStyles = {
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      fontFamily: 'OpenSans-SemiBold',
      border: 'none',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-colorPrimary.MuiInputBase-adornedEnd':
      {
        border: 'none',
        boxShadow: 'none',
      },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiButtonBase-root': {
      color: '#151515',
      fontSize: '16px',
      display: 'flex',
      gap: '8px',
      '&:hover': {
        background: 'none',
        color: '#505759',
      },
    },
    '& .MuiInputBase-input': {
      display: 'none',
    },
  };

  const dateWithIcon = () => {
    return (
      <>
        {dayjs(dashboardDate).format('D MMMM YYYY')}
        <CalendarMonthOutlinedIcon />
      </>
    );
  };

  const onRemoveAllUpcomingSessionsPrivate = async () => {
    if (groupId) {
      const removeSessionsMutationData: {
        startDate: string;
        groupId: number;
        userToken: string;
      } = {
        startDate: dayjs(dashboardDate).format('YYYY-MM-DD'),
        groupId,
        userToken,
      };
      await removeAllUpcomingSessionsPrivate(removeSessionsMutationData);
    }
  };

  const getFirstAndLastMonthDates = (
    date: dayjs.Dayjs,
  ): { firstDate: string; lastDate: string } => {
    const firstDate = date.startOf('month').format('YYYY-MM-DD');
    const lastDate = date.endOf('month').add(1, 'day').format('YYYY-MM-DD'); // notice lastDate one adds 1 day! reason: getDailySessionsExists call is until rather than including
    return { firstDate, lastDate };
  };

  const getMonthHasSessions = async (date?: string) => {
    const rangeDates = getFirstAndLastMonthDates(date ? dayjs(date) : dayjs(dailySessionsDate));
    if (isPrivateGroups) {
      if (groupId) {
        if (rangeDates) {
          const callRes = await getDailySessionsExists(
            rangeDates.firstDate,
            rangeDates.lastDate,
            groupTimezoneOffset || 0,
            userToken,
            groupId,
          );
          if (callRes) {
            setSessionsMap(callRes);
          }
        }
      } else {
        console.log('Missing groupId and/or groupTimezoneOffset', groupId, groupTimezoneOffset);
      }
    } else {
      if (rangeDates) {
        const callRes = await getDailySessionsExists(
          rangeDates.firstDate,
          rangeDates.lastDate,
          timezoneOffset || 0,
          userToken,
        );
        if (callRes) {
          setSessionsMap(callRes);
        }
      }
    }
  };

  useEffect(() => {
    getMonthHasSessions();
  }, [groupId, timezoneOffset]);

  const isDateTrue = (dateMap: DateHasSessionsMap, date: string): boolean => {
    return dateMap[date] === true;
  };

  const CustomPickersDay = (props: any) => {
    const { day, ...other } = props;
    return (
      <PickersDay
        {...other}
        day={day}
        className={
          sessionsMap && isDateTrue(sessionsMap, dayjs(day).format('YYYY-MM-DD'))
            ? 'dot-indicator'
            : ''
        }
        sx={{
          fontFamily: 'OpenSans',
          '&:hover': {
            fontWeight: '600',
            color: '#9E007E',
            border: '2px solid',
            borderColor: '#9E007E',
            backgroundColor: 'rgba(158,0, 26,0.05)',
          },
          '&.Mui-selected': {
            color: 'white',
            backgroundColor: '#9E007E !important',
            '&:hover': {
              backgroundColor: '#722351',
              border: 'none',
              fontWeight: '500',
            },
          },
          '&:disabled': {
            color: 'rgba(145, 145, 145, 1)',
          },
          '&.MuiPickersDay-today': {
            border: 'none',
            backgroundColor: '#E6E6E6',
            '&:hover': {
              fontWeight: '600',
              color: '#9E007E',
              border: '2px solid',
              borderColor: '#9E007E',
              backgroundColor: 'rgba(158,0, 26,0.05)',
            },
          },
          '&.dot-indicator': {
            '&::before': {
              borderRadius: '100%',
              position: 'absolute',
              background: '#007A9C',
              content: '""',
              height: '5px',
              width: '5px',
              left: '45%',
              bottom: '5%',
            },
          },
        }}
      />
    );
  };

  const onFetchMonthHasSessions = (month?: dayjs.Dayjs) => {
    getMonthHasSessions(
      month
        ? dayjs(month).startOf('month').format('YYYY-MM-DD')
        : dayjs(dailySessionsDate).format('YYYY-MM-DD'),
    );
  };

  return (
    <div className="session-dashboard-date-nav-container">
      <div className="date-nav">
        <div onClick={() => handleDateChange(dayjs())}>
          <TextBtn>Today</TextBtn>
        </div>
        <div onClick={() => handleDateChange(dayjs(dashboardDate).add(-24, 'hour'))}>
          <TextBtn chevronLeftIcon>{''}</TextBtn>
        </div>
        <div onClick={() => handleDateChange(dayjs(dashboardDate).add(24, 'hour'))}>
          <TextBtn chevronRightIcon>{''}</TextBtn>
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={datePickerCustomStyles}
            value={dashboardDate}
            format="D MMMM YYYY"
            onMonthChange={onFetchMonthHasSessions}
            onOpen={onFetchMonthHasSessions}
            onChange={(newValue) => {
              if (newValue) {
                handleDateChange(dayjs(newValue));
              }
            }}
            slots={{
              openPickerIcon: dateWithIcon,
              day: CustomPickersDay,
            }}
            slotProps={{
              layout: {
                sx: {
                  borderRadius: '8px',
                },
              },
            }}
          />
        </LocalizationProvider>
      </div>
      {addBtnRoute && (
        <div>
          <DynamicButton
            size="medium"
            type="primary"
            addIcon={true}
            onClick={() => navigate(addBtnRoute)}
          >
            Add single session
          </DynamicButton>
        </div>
      )}
      {groupId && (
        <>
          <DynamicButton
            size={'medium'}
            type="quaternary"
            onClick={() => onRemoveAllUpcomingSessionsPrivate()}
          >
            Delete all upcoming sessions (Dev mode)
          </DynamicButton>
          {orgData && orgData.licenses && orgData.licenses.length > 0 && (
            <div aria-describedby={id} onClick={handleAddPrivateClick}>
              <DynamicButton size="medium" type="primary" addIcon longPadding>
                Add
              </DynamicButton>
            </div>
          )}
          <BasePopup
            id={id}
            open={open}
            anchor={anchor}
            placement={'bottom-end'}
            style={{ zIndex: '5' }}
          >
            <div
              className="private-groups-add-sessions flex column"
              style={{
                background: '#FEFEFE',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                marginTop: '8px',
                borderRadius: '4px',
              }}
            >
              <button
                type="button"
                className="popup-btn-upper"
                onClick={() => navigate('addmultipleprivatesession')}
              >
                Add multiple sessions
              </button>
              <button
                type="button"
                className="popup-btn-bottom"
                onClick={() => navigate(`/privatesessions/${groupId}/addprivatesession`)}
              >
                Add single session
              </button>
            </div>
          </BasePopup>
        </>
      )}
    </div>
  );
};

export default SessionDashboardDateNav;
