import { useQuery } from '@tanstack/react-query';
import { getSessionList } from '../utils/Network';
import { Session } from '@utils/types';
import { TIME } from '../utils/constants';
import dayjs from 'dayjs';

const STALE_TIME = TIME.minute * 5;
const CACHE_TIME = TIME.minute + 5 * TIME.second;

const useFetchDailySessions = (
  userToken: string,
  date: Date | string | dayjs.Dayjs,
  offset?: number,
) =>
  useQuery<Session[]>({
    queryKey: ['sessions', dayjs(date).format('YYYY-MM-DD'), offset],
    queryFn: () => getSessionList(userToken, date, offset ? offset : 0),
    staleTime: STALE_TIME,
    gcTime: CACHE_TIME,
    enabled: !!userToken,
    refetchOnWindowFocus: false,
  });

export default useFetchDailySessions;
