import { useQuery } from "@tanstack/react-query";
import { getTutors } from "../utils/Network";
import { TIME } from "../utils/constants";
import { Tutor } from "@utils/types";

const STALE_TIME = TIME.day * 5;
const CACHE_TIME = TIME.day + 5 * TIME.second;

const useFetchTutors = (userToken: string) =>
  useQuery<Tutor[]>({
    queryKey: ['tutors'],
    queryFn: () => getTutors(userToken),
    staleTime: STALE_TIME,
    gcTime: CACHE_TIME,
    enabled: !!userToken,
    refetchOnWindowFocus: false,
  });

export default useFetchTutors;
