// import FileUploadProgress from "../components/form/FileUploadProgress";
// import LicenseDetails from '../components/private/LicenseDetails';

const Playground = () => {
  // const privateGroupsFlat = flatPrivateGroups;
  // const organizedPrivateGroups: any = organizeNodes(privateGroupsFlat);

  return (
    <div className="playground" style={{ width: '1000px', margin: 'auto' }}>
      <h1>Playground</h1>
      {/* <FileUploadProgress
        fileType={"pdf"}
        uploadProgress={100}
        fileName={"TestFile"}
      />
      <FileUploadProgress
        fileType={"pdf"}
        uploadProgress={50}
        fileName={"TestFile"}
      /> */}
      {/* <LicenseDetails /> */}
    </div>
  );
};

export default Playground;
