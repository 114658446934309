import { useQuery } from "@tanstack/react-query";
import { tutoringApiUrl } from "../utils/Network";
import { GSELevel } from "../utils/types";


const useFetchGSELevel = (token: string) => useQuery<GSELevel[]>({
    queryKey: ['gseLevel'],
    queryFn: () =>
      fetch(`${tutoringApiUrl()}/_internal/getLevels`, 
      // fetch(`http://localhost:8080/_internal/getLevels`, 
      {method: 'Get', 
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }}).then((res) =>
        res.json(),
      ),
      staleTime: Infinity,
      gcTime: 3600 + 300,
  });

export default useFetchGSELevel;