import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Language } from "../../utils/types";
import useFetchLanguages from "Query/useFetchLanguage";
import { useUserContext } from "../../components/Providers/UserContext";

interface LanguageSelectProps {
  onLanguageChange: (language: Language) => void;
  language?: Language;
  langView?: boolean;
  supporedLanguages?: string[];
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({
  onLanguageChange,
  language,
  langView,
  supporedLanguages,
}: LanguageSelectProps) => {
  const { userToken } = useUserContext();
  const { data: languageList } = useFetchLanguages(userToken);
  const [selectedValue, setSelectedValue] = useState<string>(
    languageList ? languageList[0].code : "en_us"
  );

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setSelectedValue(value);
    const selectedLanguage = languageList?.find(
      (language) => language.code === value
    );
    if (selectedLanguage) {
      onLanguageChange(selectedLanguage);
    }
  };

  useEffect(() => {
    if (language) {
      setSelectedValue(language.code);
    }
  }, [language]);

  const dropDownStyle = {
    minWidth: 120,
    margin: "16px 0 8px",

    "&.language-view": {
      width: "440px",
    },
  };

  return (
    <section className="textfield-custom">
      <Box className={langView ? "language-view" : ""} sx={dropDownStyle}>
        <FormControl fullWidth>
          <Select value={selectedValue} onChange={handleChange}>
            {languageList?.map((language) => (
              <MenuItem
                key={language.code}
                value={language.code}
                disabled={supporedLanguages
                  ? !supporedLanguages?.some(sLang => sLang === language.code)
                  : false}
              >
                {language.language}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </section>
  );
};

export default LanguageSelect;
