import { useQueryClient, useMutation } from '@tanstack/react-query';
import { addOrgMargin } from '../utils/Network';

type UpdateOrgMargin = {
  rootId: number;
  margin: number | null;
  userToken: string;
};

const _updateOrgMargin = async (data: UpdateOrgMargin) => {
  return addOrgMargin(data.rootId, data.margin, data.userToken);
};

const useUpdateOrgMargin = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: _updateOrgMargin,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ['privateGroups'],
      });
    },
  });
};

export default useUpdateOrgMargin;
