import { useQuery } from "@tanstack/react-query";
import { tutoringApiUrl } from "../utils/Network";
import { Location } from "../utils/types";


const useFetchLocations = (token: string) => useQuery<Location[]>({
    queryKey: ['locations'],
    queryFn: () =>
        fetch(`${tutoringApiUrl()}/_internal/getLocations`,
    //   fetch(`http://localhost:8080/_internal/getLocations`, 
      {method: 'Get', 
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }}).then((res) =>
        res.json(),
      ),
      staleTime: Infinity,
      gcTime: 3600 + 300,
  });

export default useFetchLocations;