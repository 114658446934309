import blankAvatarImage from "../../assets/image/blank-avatar-24.png";

interface AvatarProps {
  width: number;
  thumb?: string | null;
}

const Avatar = ({ width, thumb = null }: AvatarProps) => {
  return (
    <div
      style={{
        width: width,
        height: width,
        overflow: "hidden",
        borderRadius: "50%",
      }}
    >
      <img
        style={{ objectFit: "cover", width: "100%", height: "100%" }}
        alt="thumb"
        src={thumb ? thumb : blankAvatarImage}
      />
    </div>
  );
};

export default Avatar;
