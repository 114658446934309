import {
  createContext,
  useContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Tutor } from "../utils/types";
import { useUserContext } from "../components/Providers/UserContext";
import useFetchTutors from "Query/useFetchTutors";

// Create a context
type TutorContextType = {
  tutor: Tutor | null;
  setTutor: Dispatch<SetStateAction<Tutor | null>>;
  setTutorById: (id: number) => void;
};

const TutorContext = createContext<TutorContextType | undefined>(undefined);

// Create a provider component
type TutorProviderProps = {
  children: ReactNode;
};

export const TutorProvider = ({ children }: TutorProviderProps) => {
  const [tutor, setTutor] = useState<Tutor | null>(null);
  const { userToken } = useUserContext();

  const { data: allTutors = []} = useFetchTutors(userToken);

  const setTutorById = (id: number) => {
    if (allTutors?.length) {
      const chosenTutor = allTutors.find((tutor) => tutor.id === id);
      if (chosenTutor) {
        setTutor(chosenTutor);
      }
    }
  };


  return (
    <TutorContext.Provider
      value={{
        tutor,
        setTutor,
        setTutorById,
      }}
    >
      {children}
    </TutorContext.Provider>
  );
};

// Custom hook to use the tutor context
export const useTutorContext = () => {
  const context = useContext(TutorContext);
  if (!context) {
    throw new Error("useTutorContext must be used within a TutorProvider");
  }
  return context;
};
