import { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import 'moment-timezone/builds/moment-timezone-with-data';
import { ExclusionInterval } from '@utils/types';

interface DateTimePickerProps {
  handleChangeCustom: (name: string, value: string) => void;
  name: string;
  timezone: string;
  licensesEndDate: string | undefined;
  disablePast?: boolean;
  exclusionDates?: ExclusionInterval[];
  schedulingWindowsDisableDays: number[] | undefined;
}

export const DateTimePicker = ({
  handleChangeCustom,
  name,
  timezone,
  licensesEndDate,
  disablePast = false,
  exclusionDates = [],
  schedulingWindowsDisableDays = [],
}: DateTimePickerProps) => {
  // initial timezone(user local):
  const formatTimeZoneOffset = () => {
    const offsetMinutes = dayjs().tz(timezone).utcOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesPart = Math.abs(offsetMinutes) % 60;

    const formattedOffset =
      (offsetMinutes > 0 ? '+' : '-') +
      (offsetHours < 10 ? '0' : '') +
      offsetHours +
      ':' +
      (offsetMinutesPart < 10 ? '0' : '') +
      offsetMinutesPart;

    return formattedOffset;
  };

  const [selectedTime, setSelectedTime] = useState<Date | null | dayjs.Dayjs>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null | dayjs.Dayjs>(null);
  const [formattedDate, setFormattedDate] = useState<string>('');
  const [formattedTime, setFormattedTime] = useState<string>('');
  const formattedTimezone = formatTimeZoneOffset();

  const combineAndConvertToUTC = (): Date | undefined => {
    if (formattedDate && formattedTime && formattedTimezone) {
      const combinedDateTimeString = `${formattedDate} ${formattedTime}`;
      const combinedDateTimeTimezoneString = `${formattedDate} ${formattedTime} ${formattedTimezone}`;
      const combinedDateTime = new Date(combinedDateTimeString);
      // Check if the Date object is valid
      if (isNaN(combinedDateTime.getTime())) {
        console.error('Invalid date or time format');
        handleChangeCustom(name, '');
        return undefined;
      }
      const utcTime = new Date(combinedDateTimeTimezoneString).toISOString();
      handleChangeCustom(name, utcTime);
    }
  };

  useMemo(() => {
    combineAndConvertToUTC();
  }, [formattedDate, formattedTime, formattedTimezone]);

  const handleTimeChange = (newTime: Date | null | dayjs.Dayjs) => {
    if (newTime) {
      const formattedHour = dayjs(newTime).format('HH');
      const formattedMinutes = dayjs(newTime).format('mm');
      const formattedTime = `${formattedHour}:${formattedMinutes}`;
      setFormattedTime(formattedTime);
      setSelectedTime(newTime);
      combineAndConvertToUTC();
    }
  };

  const handleDateChange = (newDate: Date | null | dayjs.Dayjs) => {
    if (newDate !== null) {
      const formattedDate = dayjs(newDate).format('YYYY-MM-DD');
      setFormattedDate(formattedDate);
      setSelectedDate(dayjs(newDate));
      combineAndConvertToUTC();
    }
  };

  // mui customization:

  const customBoxSx = { display: 'flex', flexDirection: 'column', gap: '16px' };

  const checkDisableDate = (date: dayjs.Dayjs): boolean => {
    // disable weekdays by schedulingWindow:
    const day = date.day();
    if (schedulingWindowsDisableDays?.some((el) => el === day)) {
      return true;
    }
    // exclusion dates logic:
    if (exclusionDates.length > 0) {
      return exclusionDates.some((range: ExclusionInterval) =>
        dayjs(date).isBetween(
          dayjs(range.startDate),
          dayjs(range.endDate),
          null,
          '[]', // Inclusive of start and end date
        ),
      );
    }
    return false;
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Backspace') {
      event.preventDefault();
    }
  };

  return (
    <section className="timezone-date-time-picker">
      <Box sx={customBoxSx}>
        <Box mt={2} sx={{ width: '100%' }}>
          <p className="input-label">Date</p>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dayjs(selectedDate) || dayjs()}
              onChange={(newDate) => {
                handleDateChange(newDate);
              }}
              maxDate={licensesEndDate ? dayjs(licensesEndDate) : undefined}
              disablePast={disablePast}
              shouldDisableDate={checkDisableDate}
              format={'DD/MM/YYYY'}
              slots={{
                textField: (params) => (
                  <TextField variant="outlined" {...params} onKeyDown={handleKeyDown} />
                ),
                openPickerIcon: CalendarMonthOutlinedIcon,
              }}
              slotProps={{
                openPickerIcon: {
                  style: {
                    color: 'black',
                  },
                },
                layout: {
                  sx: {
                    borderRadius: '8px',
                  },
                },
                day: {
                  sx: {
                    fontFamily: 'OpenSans',
                    '&:hover': {
                      fontWeight: '600',
                      color: '#9E007E',
                      border: '2px solid',
                      borderColor: '#9E007E',
                      backgroundColor: 'rgba(158,0, 26,0.05)',
                    },
                    '&.Mui-selected': {
                      color: 'white',
                      backgroundColor: '#9E007E !important',
                      '&:hover': {
                        backgroundColor: '#722351',
                        border: 'none',
                        fontWeight: '500',
                      },
                    },
                    '&:disabled': {
                      color: 'rgba(145, 145, 145, 1)',
                    },
                    '&.MuiPickersDay-today': {
                      border: 'none',
                      backgroundColor: '#E6E6E6',
                      '&:hover': {
                        fontWeight: '600',
                        color: '#9E007E',
                        border: '2px solid',
                        borderColor: '#9E007E',
                        backgroundColor: 'rgba(158,0, 26,0.05)',
                      },
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
          {licensesEndDate && (
            <div className="flex gap-8 align-center">
              <InfoOutlinedIcon fontSize="small" />
              <p className="info-title" style={{ margin: '8px 0' }}>
                License ends on {dayjs(licensesEndDate).format('DD/MM/YYYY')}
              </p>
            </div>
          )}
        </Box>

        <Box mt={2} sx={{ marginTop: 0, width: '100%' }}>
          <p className="input-label">Time</p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              value={selectedTime}
              onChange={handleTimeChange}
              ampm={false}
              slots={{
                textField: (params) => (
                  <TextField variant="outlined" {...params} onKeyDown={handleKeyDown} />
                ),
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </section>
  );
};
