export const TIME = {
  millisecond: 1,
  get second() {
    return 1000 * this.millisecond;
  },
  get minute() {
    return 60 * this.second;
  },
  get hour() {
    return 60 * this.minute;
  },
  get day() {
    return 24 * this.hour;
  },
  get week() {
    return 7 * this.day;
  },
  get month() {
    return 30 * this.day;
  },
  get year() {
    return 365 * this.day;
  },
};
