import { ReactNode } from "react";
import Button from "@mui/material/Button";
// import addIconImage from "../../assets/image/add-24.png";
// import nextIconImage from "../../assets/image/next-24.png";
// import AddRoundedIcon from "@mui/icons-material/AddRounded";
// import ReportSharpIcon from "@mui/icons-material/ReportSharp";
// import ReportOffOutlinedIcon from "@mui/icons-material/ReportOffOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import SpellcheckOutlinedIcon from "@mui/icons-material/SpellcheckOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

interface TextBtnProps {
  children: ReactNode;
  addIcon?: boolean;
  nextIcon?: boolean;
  upIcon?: boolean;
  reportIcon?: boolean;
  reportOffIcon?: boolean;
  small?: boolean;
  btnGroup?: boolean;
  globeIcon?: boolean;
  grammarIcon?: boolean;
  briefcaseIcon?: boolean;
  pinDropIcon?: boolean;
  calendarIcon?: boolean;
  chevronLeftIcon?: boolean;
  chevronRightIcon?: boolean;
}

const TextBtn = ({
  children,
  small = false,
  // addIcon = false,
  // nextIcon = false,
  // upIcon = false,
  // reportIcon = false,
  // reportOffIcon = false,
  // btnGroup = false,
  globeIcon = false,
  grammarIcon = false,
  briefcaseIcon = false,
  pinDropIcon = false,
  calendarIcon = false,
  chevronLeftIcon = false,
  chevronRightIcon = false,
}: TextBtnProps) => {
  const customStyle = {
    color: "black",
    fontFamily: "OpenSans-SemiBold",
    fontSize: "16px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#505759",
    },
    "&.chevron": {
      borderRadius: "40px",
      width: "34px",
      minWidth: "unset",
      "&:hover": {
        backgroundColor: "#F5F5F5",
        color: "black",
      },
    },
  };
  return (
    <Button
      className={`text-btn ${small ? "small" : ""} ${
        chevronLeftIcon || chevronRightIcon ? "chevron" : ""
      }`}
      sx={customStyle}
    >
      {globeIcon && <PublicOutlinedIcon />}
      {grammarIcon && <SpellcheckOutlinedIcon />}
      {briefcaseIcon && <WorkOutlineOutlinedIcon />}
      {pinDropIcon && <PinDropOutlinedIcon />}
      {chevronLeftIcon && <ChevronLeftOutlinedIcon />}
      {chevronRightIcon && <ChevronRightOutlinedIcon />}
      {children}
      {calendarIcon && <CalendarMonthOutlinedIcon />}
    </Button>
  );
};

export default TextBtn;
