import { useState, useEffect, ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import ReportSharpIcon from '@mui/icons-material/ReportSharp';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DynamicButton from '../common/DynamicButton';
import PinkBtn from '../common/PinkBtn';
import TitleBar from '../common/TitleBar';
import InfoBadge from '../common/InfoBadge';
import { useUserContext } from '../Providers/UserContext';
import { usePrivateGroupsContext } from '../../providers/privateGroupsContext';
import useFetchCategory from '../../Query/useFetchCategory';
import useFetchGSELevel from '../../Query/useFetchGSELevel';
import useFetchLocations from 'Query/useFetchLocation';
import { WeekDays, GSELevel } from '../../utils/types';
import dayjs from 'dayjs';
import { RequiredSummary } from './RequiredSummary';
import LocationsAutocompleteCheckbox from '../private/LocationsAutocompleteCheckbox';
import { DateRangePickerMultiple } from '../form/DateRangePickerMultiple';
import { Children, SchedulingWindow, Category, Location } from '../../utils/types';
import { getFormattedGender, haveSameItems } from '../../utils/utilsts';
import useUpdateGroupGSELevels from '../../useMutation/useUpdateGroupGSELevels';
import useUpdateGroupLocations from '../../useMutation/useUpdateGroupLocations';
import useUpdateOrgMargin from '../../useMutation/useUpdateOrgMargin';
import { getContentEnv } from '../../utils/Network';

interface LicenseDetailsProps {
  parentData: Children | null;
}

const LicenseDetails = ({ parentData }: LicenseDetailsProps) => {
  const { selectedGroup, selectedOrg } = usePrivateGroupsContext();
  const { userToken } = useUserContext();
  const { data: Categories } = useFetchCategory(userToken);
  const { data: GSELevels } = useFetchGSELevel(userToken);
  const { data: Locations } = useFetchLocations(userToken);
  const [requiredMsgs, setRequiredMsgs] = useState<string[]>([]);

  const checkRequiredMsgs = () => {
    const msgs: string[] = [];

    if (!updatedLevels.length) {
      msgs.push('CEFR/GSE levels');
    }

    if (!updatedLocations.length) {
      msgs.push('Tutor location(s)');
    }

    if (!selectedOrg?.margin) {
      msgs.push('Scheduling margin');
    }

    setRequiredMsgs(msgs);
  };

  // GSELevels:
  const [isLevelsInfo, setIsLevelsInfo] = useState<boolean>(false);
  const [isLevelsEditMode, setIsLevelsEditMode] = useState<boolean>(false);
  const [selectedGSELevels, setSelectedGSELevels] = useState<GSELevel[]>([]);
  const [updatedLevels, setUpdatedLevels] = useState<GSELevel[]>([]); // acts as BE value until mutate() refresh fix.
  const { mutateAsync: updateGroupGSELevels } = useUpdateGroupGSELevels();

  // Locations:
  const [isLocationsInfo, setIsLocationsInfo] = useState<boolean>(false);
  const [isLocationsEditMode, setIsLocationsEditMode] = useState<boolean>(false);
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
  const [updatedLocations, setUpdatedLocations] = useState<Location[]>([]); // acts as BE value until mutate() refresh fix.
  const { mutateAsync: updateGroupLocations } = useUpdateGroupLocations();

  // Margin
  const [isMarginEditMode, setIsMarginEditMode] = useState<boolean>(false);
  const [selectedMarginError, setSelectedMarginError] = useState<boolean>(false);
  const [selectedMargin, setSelectedMargin] = useState<string | null>(null); //decimal mode
  const { mutateAsync: updateOrgMargin } = useUpdateOrgMargin();

  const handleMarginChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // setSelectedMargin(parseInt(event.target.value));
    // for decimal mode:
    const newValue = event.target.value;
    if (/^\d+(\.\d+)?$/.test(newValue)) {
      setSelectedMargin(newValue);
      if (parseInt(newValue) > 84 && parseInt(newValue) < 116) {
        if (selectedMarginError) {
          setSelectedMarginError(false);
        }
      } else {
        setSelectedMarginError(true);
      }
    }
  };

  const handleMarginSave = async () => {
    setIsMarginEditMode(false);
    if (selectedOrg) {
      const req = {
        rootId: selectedOrg.id,
        // margin: selectedMargin,
        // for decimal mode:
        margin: selectedMargin && selectedMargin !== '' ? parseFloat(selectedMargin) : null,
        userToken,
      };
      const resMargin = await updateOrgMargin(req);
      if (resMargin) {
        setSelectedMargin(resMargin);
      }
    }
  };

  const handleRemoveMargin = async () => {
    setIsMarginEditMode(false);
    if (selectedOrg) {
      const req = {
        rootId: selectedOrg.id,
        margin: null,
        userToken,
      };
      const resMargin = await updateOrgMargin(req);
      if (resMargin) {
        setSelectedMargin(resMargin);
      }
    }
  };

  const handleMarginCancel = () => {
    // setSelectedMargin(selectedOrg.margin ? selectedOrg.margin : null);
    // for decimal mode:
    setSelectedMargin(selectedOrg?.margin ? selectedOrg?.margin.toString() : null);
    setIsMarginEditMode(false);
  };

  const [licenseStartDate, setLicenseStartDate] = useState<string | dayjs.Dayjs>(
    selectedOrg?.licenses
      ? selectedOrg?.licenses.length > 0
        ? dayjs(selectedOrg?.licenses[0].startDate)
        : dayjs()
      : dayjs(),
  );
  const [licenseEndDate, setLicenseEndDate] = useState<string | dayjs.Dayjs>(
    selectedOrg?.licenses
      ? selectedOrg?.licenses.length > 0
        ? dayjs(selectedOrg?.licenses[0].endDate)
        : dayjs()
      : dayjs(),

    // dayjs(selectedOrg?.licenses[0].endDate),
  );

  // const sumLicensesValidToday = (): number => {
  //   const today = dayjs();
  //   let totalQuantity = 0;

  //   selectedOrg?.licenses.forEach((license) => {
  //     const startDate = dayjs(license.startDate);
  //     const endDate = dayjs(license.endDate);

  //     if (today.isBetween(startDate, endDate, null, '[]')) {
  //       totalQuantity += license.quantity;
  //     }
  //   });

  //   return totalQuantity;
  // };

  const calcEarliestDateRange = (): void => {
    const today = dayjs();
    let earliestStartDate: dayjs.Dayjs | null = null;
    let earliestEndDate: dayjs.Dayjs | null = null;
    if (selectedOrg?.licenses && selectedOrg.licenses.length > 0) {
      selectedOrg?.licenses.forEach((license) => {
        const startDate = dayjs(license.startDate);
        const endDate = dayjs(license.endDate);

        if (today.isBetween(startDate, endDate, null, '[]')) {
          if (!earliestStartDate || startDate.isBefore(earliestStartDate)) {
            earliestStartDate = startDate;
            earliestEndDate = endDate;
          }
        }
      });
    }

    if (earliestStartDate && earliestEndDate) {
      setLicenseStartDate(dayjs(earliestStartDate));
      setLicenseEndDate(dayjs(earliestEndDate));
    }
  };

  useEffect(() => {
    calcEarliestDateRange();
  }, [selectedOrg]);

  const getSchedulingDays = (schedulingWindows: SchedulingWindow[]): string[] => {
    const teachingDays: string[] = [];

    schedulingWindows.forEach((window) => {
      if (window.intervals.length) {
        teachingDays.push(window.key);
      }
    });

    return teachingDays;
  };

  const teachingDays = selectedGroup?.schedulingWindow
    ? getSchedulingDays(selectedGroup?.schedulingWindow)
    : [];
  const weekDaysKeys = Object.keys(WeekDays) as (keyof typeof WeekDays)[];

  // initial setSelectedGSELevels from selectedGroup
  useEffect(() => {
    if (GSELevels && selectedGroup && selectedGroup.GSELevels) {
      setSelectedGSELevels(selectedGroup.GSELevels);
      setUpdatedLevels(selectedGroup.GSELevels);
    }
  }, [selectedGroup, selectedGroup?.GSELevels, GSELevels]);

  // initial setSelectedLocations from selectedGroup
  useEffect(() => {
    if (Locations && selectedGroup && selectedGroup.locations) {
      setSelectedLocations(selectedGroup.locations);
      setUpdatedLocations(selectedGroup.locations);
    }
  }, [selectedGroup, selectedGroup?.locations, Locations]);

  useEffect(() => {
    checkRequiredMsgs();
  }, [
    selectedGroup?.locations,
    selectedGroup?.GSELevels,
    selectedGroup?.id,
    selectedGSELevels,
    selectedLocations,
    updatedLevels,
    updatedLocations,
    selectedOrg?.margin,
  ]);

  // reset modes when changing group:
  useEffect(() => {
    setIsLevelsInfo(false);
    setIsLocationsInfo(false);
    setIsLevelsEditMode(false);
    setIsLocationsEditMode(false);
    setIsMarginEditMode(false);
  }, [selectedGroup?.id]);

  // remove Add btns if Levels/Locations update and exist
  useEffect(() => {
    if (selectedGSELevels.length) {
      setIsLevelsInfo(true);
    }
    if (selectedLocations.length) {
      setIsLocationsInfo(true);
    }
  }, [selectedGSELevels, selectedLocations]);

  const handleAddLevelClick = () => {
    if (GSELevels) {
      setIsLevelsInfo(true);
      setIsLevelsEditMode(true);
    }
  };

  // GSELevels logic:
  const handleGSELevelChange = (level: GSELevel) => {
    setSelectedGSELevels((prevSelectedLevels) =>
      prevSelectedLevels && prevSelectedLevels.some((sGSElv) => sGSElv.id === level.id)
        ? prevSelectedLevels.filter((l) => l.id !== level.id)
        : prevSelectedLevels && [
            ...prevSelectedLevels,
            {
              id: level.id,
              level: level.level,
              code: level.code,
              minGSE: level.minGSE,
              maxGSE: level.maxGSE,
            },
          ],
    );
  };

  const handleGSELevelsSave = async () => {
    setIsLevelsEditMode(false);
    if (selectedGroup && selectedOrg) {
      // groupGSELevelsMutation.mutate();
      const req = {
        groupId: selectedGroup.id,
        rootId: selectedOrg?.id,
        levels: selectedGSELevels,
        userToken,
      };
      await updateGroupGSELevels(req);
      // queryClient.invalidateQueries({ queryKey: ['privateGroups'] });
    }
    if (!selectedGSELevels.length) {
      setIsLevelsInfo(false);
    }
  };

  useEffect(() => {
    if (selectedGroup?.GSELevels) {
      if (!haveSameItems(selectedGroup.GSELevels, selectedGSELevels)) {
        setSelectedGSELevels(selectedGroup.GSELevels);
      }
    }
  }, [selectedGroup?.GSELevels]);

  const handleGSELevelsCancel = () => {
    if (updatedLevels) {
      setSelectedGSELevels(updatedLevels);
      if (!updatedLevels.length) {
        setIsLevelsInfo(false);
      }
    }
    setIsLevelsEditMode(false);
  };

  const getGSELevelBtnFormatted = (level: GSELevel) => {
    return (
      <>
        <span style={{ color: 'inherited', lineHeight: '16px' }}>{level.code}</span>
        {level.level.match(/(?<=\s).+/) ? (
          <span style={{ color: 'inherited', lineHeight: '16px' }}>
            {level.level.match(/(?<=\s).+/)}
          </span>
        ) : (
          ''
        )}
      </>
    );
  };

  const checkIsGSELevelsSaveBtnDisable = (): boolean => {
    if (updatedLevels) {
      if (updatedLevels.length !== selectedGSELevels.length) {
        return false;
      }
      const ids1 = new Set(updatedLevels.map((obj) => obj.id));
      const ids2 = new Set(selectedGSELevels.map((obj) => obj.id));

      if (ids1.size !== ids2.size) {
        return false;
      }

      for (const id of ids1) {
        if (!ids2.has(id)) {
          return false;
        }
      }

      return true;
    }
    return true;
  };

  const isGSELevelsSaveBtnDisable = checkIsGSELevelsSaveBtnDisable();

  // LOCATION SELECT/EDIT LOGIC:
  const handleAddLocationsClick = () => {
    if (Locations) {
      setIsLocationsInfo(true);
      setIsLocationsEditMode(true);
    }
  };

  const handleLocationsSave = async () => {
    setIsLocationsEditMode(false);
    if (selectedGroup && selectedOrg) {
      // groupLocationsMutation.mutate();
      const req = {
        groupId: selectedGroup.id,
        rootId: selectedOrg?.id,
        locations: selectedLocations,
        userToken,
      };
      await updateGroupLocations(req);
    }
    if (!selectedLocations.length) {
      setIsLocationsInfo(false);
      setIsLocationsEditMode(false);
    }
  };

  const handleLocationsCancel = () => {
    if (updatedLocations) {
      setSelectedLocations(updatedLocations);
    }
    setIsLocationsEditMode(false);
    if (!updatedLocations.length) {
      setIsLocationsInfo(false);
      setIsLocationsEditMode(false);
    }
  };

  const locationFormatted = (location: Location) => {
    return `${location?.code?.length ? location.code + ' - ' : ''}${location.location}`;
  };

  const checkIsLocationsSaveBtnDisable = (): boolean => {
    if (updatedLocations) {
      if (updatedLocations.length !== selectedLocations.length) {
        return false;
      }
      const ids1 = new Set(updatedLocations.map((obj) => obj.id));
      const ids2 = new Set(selectedLocations.map((obj) => obj.id));

      if (ids1.size !== ids2.size) {
        return false;
      }

      for (const id of ids1) {
        if (!ids2.has(id)) {
          return false;
        }
      }

      return true;
    }
    return true;
  };

  const isLocationsSaveBtnDisable = checkIsLocationsSaveBtnDisable();

  // LOCATION AUTOCOMPLETE SELECT:

  const handleLocationSelect = (val: Location[]) => {
    setSelectedLocations(val);
  };

  useEffect(() => {
    // reset GSE and Tutor rates(Locations) btns when switching group:
    if (!selectedGSELevels.length) {
      setIsLevelsInfo(false);
    }
    if (!selectedLocations.length) {
      setIsLocationsInfo(false);
    }
  }, [selectedGroup]);

  return (
    <section className="license-details-section flex column">
      {requiredMsgs && requiredMsgs.length ? (
        <div className="required-warning">
          <RequiredSummary msgs={requiredMsgs} />
        </div>
      ) : (
        ''
      )}
      <div className="organization-details">
        <TitleBar title={'Organisation'} iconType={'organization'} />
        <div className="details-list flex column gap-16">
          <div className="details-item flex">
            <div className="info-title">Organisation</div>
            <div className="detail-content flex gap-8">
              {selectedOrg && <p className="details">{selectedOrg.name}</p>}
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Sub-organisation</div>
            <div className="detail-content flex gap-8">
              <p className="details">{parentData && parentData.name}</p>
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Group</div>
            <div className="detail-content flex gap-8">
              <p className="details">{selectedGroup?.name}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="license-agreement-details">
        <TitleBar title={'License agreement'} iconType={'article'} />
        <div className="details-list flex column gap-16">
          <div className="details-item flex">
            <div className="info-title">Group licenses</div>
            <div className="detail-content flex gap-8">
              <p className="details">{selectedGroup?.maxNumberOfStudents}</p>
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Start date</div>
            <div className="detail-content flex gap-8">
              {licenseStartDate && (
                <p className="details">{dayjs(licenseStartDate).format('DD/MM/YYYY')}</p>
              )}
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">End date</div>
            <div className="detail-content flex gap-8">
              {licenseEndDate && (
                <p className="details">{dayjs(licenseEndDate).format('DD/MM/YYYY')}</p>
              )}
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Duration</div>
            <div className="detail-content flex gap-8">
              {licenseStartDate && licenseEndDate && (
                <>
                  <p className="details">
                    {dayjs(licenseEndDate).diff(dayjs(licenseStartDate), 'month') > 1 &&
                      `${dayjs(licenseEndDate).diff(dayjs(licenseStartDate), 'month')} month`}
                  </p>
                  <p className="details">
                    {dayjs(licenseEndDate).diff(dayjs(licenseStartDate), 'week') > 1 &&
                      `(${dayjs(licenseEndDate).diff(dayjs(licenseStartDate), 'week')} weeks)`}
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Expires in</div>
            <div className="detail-content flex gap-8">
              {selectedOrg && selectedOrg.licenses && selectedOrg.licenses.length > 0 && (
                <>
                  <p className="details">
                    {dayjs(selectedOrg.licenses[0].endDate).diff(dayjs(), 'month') > 1 &&
                      `${dayjs(selectedOrg.licenses[0].endDate).diff(dayjs(), 'month')} month`}
                  </p>
                  <p className="details">
                    {dayjs(selectedOrg.licenses[0].endDate).diff(dayjs(), 'week') > 1 &&
                      `(${dayjs(selectedOrg.licenses[0].endDate).diff(dayjs(), 'week')} weeks)`}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="profiency-details">
        <TitleBar title={'Learner proficiency and content type'} iconType={'teachingContent'} />
        <div className="details-list gap-48 flex column gap-16">
          <div className="details-item flex">
            <div className="info-title flex gap-8">
              CEFR/GSE levels
              {!updatedLevels?.length && (
                <div className="error-filter">
                  <ErrorOutlinedIcon />
                </div>
              )}
            </div>
            <div className="detail-content flex gap-8">
              {isLevelsInfo && (
                <div className="flex" style={{ gap: '24px' }}>
                  {isLevelsEditMode && (
                    <div className="flex gap-8">
                      {GSELevels?.map((level, index) => (
                        <PinkBtn
                          small
                          thinPadding
                          key={index}
                          isSelected={
                            selectedGSELevels &&
                            selectedGSELevels?.some((sGSElv) => sGSElv.id === level.id)
                          }
                          onClick={() => handleGSELevelChange(level)}
                        >
                          {level.code && level.level && (
                            <div className="flex column">{getGSELevelBtnFormatted(level)}</div>
                          )}
                        </PinkBtn>
                      ))}{' '}
                    </div>
                  )}

                  {!isLevelsEditMode && isLevelsInfo && (
                    <div className="flex gap-8">
                      {GSELevels &&
                        selectedGSELevels &&
                        GSELevels.map((level, index) => (
                          <InfoBadge
                            small
                            key={index}
                            disableHeight
                            thinInlinePadding
                            isDisable={
                              !selectedGSELevels!.some(
                                (gseLevel: GSELevel) => gseLevel.id === level.id,
                              )
                            }
                          >
                            {level.level && (
                              <div className="flex column justify-center align-center">
                                {getGSELevelBtnFormatted(level)}
                              </div>
                            )}
                          </InfoBadge>
                        ))}
                    </div>
                  )}
                  <div className="action-btns gap-8 flex column space-between">
                    {!isLevelsEditMode && (
                      <DynamicButton
                        size="small"
                        type="quaternary"
                        longPadding
                        onClick={() => setIsLevelsEditMode(true)}
                      >
                        <EditOutlinedIcon /> Edit
                      </DynamicButton>
                    )}
                    {isLevelsEditMode && (
                      <>
                        <DynamicButton
                          size="small"
                          type="secondary"
                          disable={isGSELevelsSaveBtnDisable}
                          onClick={() => handleGSELevelsSave()}
                          fullWidth
                        >
                          Save
                        </DynamicButton>
                        <DynamicButton
                          size="small"
                          type="tertiary"
                          onClick={() => handleGSELevelsCancel()}
                        >
                          Cancel
                        </DynamicButton>
                      </>
                    )}
                  </div>
                </div>
              )}

              {!isLevelsInfo && !updatedLevels.length && (
                <DynamicButton
                  size="small"
                  type="primary"
                  addIcon={true}
                  onClick={handleAddLevelClick}
                >
                  Add CEFR/GSE levels
                </DynamicButton>
              )}
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Categories</div>
            <div className="detail-content flex gap-8">
              {Categories &&
                selectedGroup?.categories &&
                Categories.map((category, index) => (
                  <InfoBadge
                    small
                    key={index}
                    globeIcon={category.category === 'General English'}
                    grammarIcon={category.category === 'Grammar'}
                    briefcaseIcon={category.category === 'Workplace English'}
                    pinDropIcon={category.category === 'Specific purpose'}
                    isDisable={
                      !selectedGroup?.categories!.some((cat: Category) => cat.id === category.id)
                    }
                  >
                    {category.category}
                  </InfoBadge>
                ))}
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Content sensitivity</div>
            <div className="detail-content flex gap-8">
              <div className="license-topic-sensitivity">
                {selectedGroup?.contentSensitivity ? (
                  <ReportSharpIcon />
                ) : (
                  <ReportOffOutlinedIcon />
                )}

                {selectedGroup?.contentSensitivity
                  ? 'Adapted topics for regional sensitivity'
                  : 'Standard topics'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="live-sessions-details">
        <TitleBar title={'Live sessions'} iconType={'groups'} />
        <div className="details-list flex column gap-16">
          <div className="details-item flex">
            <div className="info-title">Weekly limit</div>
            <div className="detail-content flex gap-8">
              <p className="details">{selectedGroup?.weeklyLimit} sessions / learner</p>
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Duration</div>
            <div className="detail-content flex gap-8">
              <p className="details">{selectedGroup?.duration} minutes</p>
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Class size</div>
            <div className="detail-content flex gap-8">
              <p className="details">{selectedGroup?.classSize} learners</p>
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Teaching days</div>
            <div className="detail-content flex gap-8">
              {weekDaysKeys &&
                teachingDays &&
                weekDaysKeys.map((day, index) => (
                  <InfoBadge small key={index} isDisable={!teachingDays.includes(day)}>
                    {WeekDays[day]}
                  </InfoBadge>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="tutor-details">
        <TitleBar title={'Tutors'} iconType={'group'} />
        <div className="details-list gap-48 flex column">
          <div className="details-item flex">
            <div className="info-title flex gap-8">
              Regional tutor rate(s){' '}
              {!updatedLocations ||
                (!updatedLocations.length && (
                  <div className="error-filter">
                    <ErrorOutlinedIcon />
                  </div>
                ))}
            </div>
            <div className="detail-content flex gap-8">
              {isLocationsInfo && (
                <div className="flex" style={{ gap: '24px', width: '100%' }}>
                  {isLocationsEditMode && (
                    <div className="flex gap-8">
                      <LocationsAutocompleteCheckbox
                        selectedLocationsLicenseDetails={selectedLocations}
                        onLocationSelect={handleLocationSelect}
                      />
                    </div>
                  )}
                  {!isLocationsEditMode && isLocationsInfo && (
                    <div
                      className="flex gap-8 locations-list"
                      style={{ flexWrap: 'wrap', width: '100%' }}
                    >
                      {Locations &&
                        selectedLocations &&
                        Locations.map((loc, index) => (
                          <InfoBadge
                            small
                            key={index}
                            disableHeight
                            isHide={
                              !selectedLocations!.some(
                                (selectedLoc: Location) => selectedLoc.id === loc.id,
                              )
                            }
                          >
                            {loc && (
                              <div className="flex column justify-center align-center">
                                {locationFormatted(loc)}
                              </div>
                            )}
                          </InfoBadge>
                        ))}
                    </div>
                  )}
                  <div className="action-btns gap-8 flex column space-between">
                    {!isLocationsEditMode && (
                      <DynamicButton
                        size="small"
                        type="quaternary"
                        longPadding
                        onClick={() => setIsLocationsEditMode(true)}
                      >
                        <EditOutlinedIcon /> Edit
                      </DynamicButton>
                    )}
                    {isLocationsEditMode && (
                      <>
                        <DynamicButton
                          size="small"
                          type="secondary"
                          disable={isLocationsSaveBtnDisable}
                          onClick={() => handleLocationsSave()}
                          fullWidth
                        >
                          Save
                        </DynamicButton>
                        <DynamicButton
                          size="small"
                          type="tertiary"
                          onClick={() => handleLocationsCancel()}
                        >
                          Cancel
                        </DynamicButton>
                      </>
                    )}
                  </div>
                </div>
              )}

              {!isLocationsInfo && !selectedLocations.length && (
                <DynamicButton
                  size="small"
                  type="primary"
                  addIcon={true}
                  onClick={handleAddLocationsClick}
                >
                  Add tutor rate(s)
                </DynamicButton>
              )}
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Gender</div>
            <div className="detail-content flex gap-8">
              {selectedGroup?.gender && <p>{getFormattedGender(selectedGroup.gender)}</p>}
            </div>
          </div>
        </div>
      </div>
      <div className="schedule-details">
        <TitleBar title={'Schedule'} iconType={'calendar'} />
        <div className="details-list flex column gap-48">
          <div className="details-item flex">
            <div className="info-title">Learner time zone(s)</div>
            <div className="detail-content flex gap-8">
              <p className="details">
                {selectedGroup?.timezone ? selectedGroup.timezone : 'null - default UTC'}
              </p>
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Scheduling window</div>
            <div className="detail-content flex column gap-8">
              {selectedGroup?.schedulingWindow &&
                selectedGroup.schedulingWindow.map((day, index) => {
                  return (
                    <div className="day flex gap-16" key={index}>
                      <span className="info-title flex-1">
                        {WeekDays[day.key as 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU']}
                      </span>{' '}
                      <div className="scheduling-window">
                        {day.intervals.map((interval, index) => (
                          <p key={index}>
                            {interval.start} - {interval.end}
                          </p>
                        ))}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title flex gap-8">
              Scheduling margin
              {!selectedOrg?.margin && (
                <div className="error-filter">
                  <ErrorOutlinedIcon />
                </div>
              )}
            </div>
            <div className="detail-content flex gap-8">
              {/* selectedOrg.margin exists: */}
              {selectedOrg?.margin && !isMarginEditMode && <p>{selectedOrg.margin}%</p>}
              {/* selectedOrg.margin does not exist: */}
              {/* selectedOrg.margin does not exist, !editMode: */}
              {/* {getContentEnv() !== 'prod' && ( */}
              {!selectedOrg?.margin && !isMarginEditMode && (
                <>
                  <p>
                    The margin will apply to all groups in the organisation and can’t be changed
                    once saved
                  </p>
                  <DynamicButton
                    size="small"
                    type="primary"
                    addIcon={true}
                    onClick={() => {
                      setSelectedMargin(
                        selectedOrg?.margin ? selectedOrg.margin.toString() : '100',
                      );
                      setIsMarginEditMode(true);
                    }}
                  >
                    Add scheduling margin
                  </DynamicButton>
                </>
              )}
              {selectedOrg?.margin && !isMarginEditMode && (
                <div style={{ paddingInlineStart: '24px' }}>
                  <DynamicButton
                    size="small"
                    type="quaternary"
                    longPadding
                    onClick={() => handleRemoveMargin()}
                  >
                    Remove margin (Dev mode)
                  </DynamicButton>
                </div>
              )}
              {/* selectedOrg.margin does not exist, editMode: */}
              {/* TODO: return after dev {!selectedOrg.margin && isMarginEditMode && ( */}
              {isMarginEditMode && (
                <div className="margin-edit-mode flex gap-24 space-between full">
                  <div className="margin-input flex column gap-8">
                    <p style={{ fontWeight: '400' }}>
                      The margin will apply to all groups in the organisation and can’t be changed
                      once saved
                    </p>
                    <TextField
                      // fullWidth
                      type="number"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        'aria-label': 'number-input', // Add an aria-label if needed
                        title: '',
                        inputMode: 'decimal',
                      }}
                      value={selectedMargin?.toString()}
                      onChange={(event) => handleMarginChange(event)}
                    />
                    <div className="instructions flex column">
                      <div className="flex gap-8">
                        <InfoOutlinedIcon fontSize="small" />
                        <p style={{ fontWeight: '400' }}>
                          Use values above 100% to schedule more sessions than required, and values
                          below to schedule fewer
                        </p>
                      </div>
                      <p
                        style={{
                          color: 'red',
                          fontWeight: '400',
                          visibility: selectedMarginError ? 'visible' : 'hidden',
                        }}
                      >
                        This value must be a positive number between 85 and 115. Use a dot for
                        decimals (e.g., 100.5%)
                      </p>
                    </div>
                  </div>
                  <div className="margin-action-btns flex column gap-8">
                    <DynamicButton
                      size="small"
                      type="secondary"
                      disable={
                        selectedMargin === null ||
                        selectedMargin === '' ||
                        selectedMargin.length === 0 ||
                        selectedMarginError
                      }
                      onClick={() => handleMarginSave()}
                      fullWidth
                    >
                      Save
                    </DynamicButton>
                    <DynamicButton
                      size="small"
                      type="tertiary"
                      onClick={() => handleMarginCancel()}
                    >
                      Cancel
                    </DynamicButton>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="details-item flex">
            <div className="info-title">Exclusion dates</div>
            <div className="detail-content flex gap-8 column">
              <p style={{ fontWeight: '400' }}>
                Dates will apply to all groups in the organisation and can’t be changed once they
                have been saved
              </p>
              {selectedOrg && (
                <DateRangePickerMultiple key={selectedOrg.id} orgData={selectedOrg} />
              )}
            </div>
          </div>
        </div>
      </div>
      {getContentEnv() !== 'prod' && getContentEnv() !== 'stage' && (
        <div className="flex full">
          <pre>{selectedGroup && JSON.stringify(selectedGroup, null, 2)}</pre>
          <pre>{selectedOrg && JSON.stringify(selectedOrg, null, 2)}</pre>
        </div>
      )}
    </section>
  );
};

export default LicenseDetails;
