
import { TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import { addUserToBlockList, getBlockUsersList, removeUserBlockList } from '../utils/Network';
import { useUserContext } from '../components/Providers/UserContext'

const Block = () => {

    const [blockUserList, setBlockUserList] = useState([]);
    const [blockUserId, setBlockUserId] = useState('');
    const [removeUserId, setRemoveUserId] = useState('');

    const { userToken } = useUserContext();

    const blockUser = async (id: number) => {
         await addUserToBlockList(id, userToken);
         blockList();

      }
    
    const removeUser = async (id: number) => {
        await removeUserBlockList(id, userToken);
        blockList();
    }

    const blockList = async () => {
        const userList = (await getBlockUsersList());
        setBlockUserList(userList);
    }

    useEffect(() => {
        blockList()
    },[])

    return <> 
        <div style={{ justifyContent: "center", margin: "50px 150px" }}>
        
            {blockUserList.map((user) => {
                return<>{user}<br/></>
            })}
            </div>
            <div style={{display: "flex"}}>
        <div style={{ marginTop: "100px",display: "flex", gap: 10, width: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <TextField
                    label="Block User ID:"
                    onChange={(e) => setBlockUserId(e.target.value)} />
            <div
                onClick={() => blockUser(parseInt(blockUserId))}
                style={{
                    width: 70,
                    height: 50,
                    borderRadius: 12,
                    padding: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexFlow: "wrap",
                    backgroundColor: "#a9dbd2",
                    cursor: "pointer"
                }}>Block
            </div>
        </div>
        <div style={{ marginTop: "100px",display: "flex", gap: 10, width: "100%", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <TextField
                    label="Remove User ID:"
                    onChange={(e) => setRemoveUserId(e.target.value)} />
            <div
                onClick={() => removeUser(parseInt(removeUserId))}
                style={{
                    width: 70,
                    height: 50,
                    borderRadius: 12,
                    padding: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexFlow: "wrap",
                    backgroundColor: "#fbba82",
                    cursor: "pointer"
                }}>Remove
            </div>
            </div>
        </div>
        </>
}

export default Block;