// import { useRef, useEffect, useState } from "react";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import VideoFileOutlinedIcon from "@mui/icons-material/VideoFileOutlined";
import AudioFileOutlinedIcon from "@mui/icons-material/AudioFileOutlined";
// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import SwitchBtn from "../common/SwitchBtn";

interface FileUploadProgressProps {
  uploadProgress: number;
  fileType: string;
  fileName: string;
  isViewMode?: boolean;
  isTutorFacing?: boolean;
  toggleTutorFacing?: (fileName: string) => void;
  removeTopicFileByName?: (fileName: string) => void;
  // removeUploadingFile?: (fileName: string) => void;
}

const FileUploadProgress = ({
  uploadProgress = 50,
  fileType,
  fileName = "",
  isViewMode = false,
  isTutorFacing = false,
  toggleTutorFacing,
  removeTopicFileByName,
}: // removeUploadingFile,
FileUploadProgressProps) => {
  const getFileIcon = () => {
    switch (fileType) {
      case "pdf":
        return <PictureAsPdfOutlinedIcon />;
      case "doc":
        return <InsertDriveFileOutlinedIcon />;
      case "video":
        return <VideoFileOutlinedIcon />;
      case "audio":
        return <AudioFileOutlinedIcon />;
      case "ppt":
        return <DescriptionOutlinedIcon />;
      case "image":
        return <ImageOutlinedIcon />;

      default:
        return <InsertDriveFileOutlinedIcon />;
    }
  };

  const LinearProgressSx = {
    height: "10px",
    borderRadius: "5px",
    width: "848px",
    backgroundColor: "#DFE1E1",
    "& .MuiLinearProgress-bar": {
      borderRadius: "5px",
      backgroundColor: "#9E007E",
    },
  };

  const onSwitchClick = () => {
    if (fileName && toggleTutorFacing) {
      toggleTutorFacing(fileName);
    }
  };
  const onRemoveTopicFileByName = () => {
    if (fileName && removeTopicFileByName) {
      removeTopicFileByName(fileName);
    }
  };

  // const onRemoveUploadingFile = () => {
  //   if (fileName && removeTopicFileByName) {
  //     removeTopicFileByName(fileName);
  //   }
  //   if (fileName && removeUploadingFile) {
  //     removeUploadingFile(fileName);
  //   }
  // };

  return (
    <section className="file-upload-progress-section align-center flex">
      <div className="file-upload-progress flex align-center space-between gap-8">
        {getFileIcon()}
        <div className="central flex column gap-5">
          <div className="upper flex space-between align-center">
            <p style={{ margin: 0 }}>{fileName}</p>

            <div className="tutor-facing flex gap-8 align-center">
              {isViewMode && isTutorFacing && (
                <p style={{ margin: 0 }}>Tutor facing only</p>
              )}
              {!isViewMode && uploadProgress >= 100 && fileName && (
                <div className="flex gap-8 align-center">
                  <p style={{ margin: 0 }}>Tutor facing only</p>
                  <SwitchBtn
                    onClick={() => onSwitchClick()}
                    isTutorFacing={isTutorFacing}
                  />
                </div>
              )}
              {!isViewMode && uploadProgress < 100 && (
                <p style={{ margin: 0 }}>{uploadProgress}%</p>
              )}
            </div>
          </div>

          {uploadProgress < 100 && (
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              sx={LinearProgressSx}
            />
          )}
        </div>
        {!isViewMode && uploadProgress === 100 && (
          <button
            type="button"
            className="cancel-btn"
            onClick={onRemoveTopicFileByName}
            style={{
              filter:
                "invert(30%) sepia(23%) saturate(5608%) hue-rotate(169deg) brightness(94%) contrast(101%)",
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </button>
        )}
        {/* {!isViewMode && uploadProgress < 100 && (
          <button
            type="button"
            className="cancel-btn"
            onClick={onRemoveUploadingFile}
          >
            <CloseOutlinedIcon />
          </button>
        )} */}
      </div>
    </section>
  );
};

export default FileUploadProgress;
