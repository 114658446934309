import { useQuery } from "@tanstack/react-query";
import { getVendorsList } from "../utils/Network";
import { Vendor } from "@utils/types";
import { TIME } from "../utils/constants";


const STALE_TIME = TIME.day * 5;
const CACHE_TIME = TIME.day + 5 * TIME.second;

const useFetchVendors = (token: string) => useQuery<Vendor[]>({
    queryKey: ['vendors'],
  queryFn: () => getVendorsList(token),
  staleTime: STALE_TIME,
  gcTime: CACHE_TIME,
  enabled: !!token,
  refetchOnWindowFocus: false,
  });

export default useFetchVendors;