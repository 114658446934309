import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
interface TitleBarProps {
  title: string;
  iconType: string;
}

const TitleBar = ({ title, iconType }: TitleBarProps) => {
  return (
    <section className="title-bar-section">
      <div className="title-bar-content flex ">
        {' '}
        {iconType && iconType === 'organization' && <AccountBalanceOutlinedIcon />}
        {iconType && iconType === 'person' && <PersonOutlineOutlinedIcon />}
        {iconType && iconType === 'teachingContent' && <SchoolOutlinedIcon />}
        {iconType && iconType === 'contact' && <EmailOutlinedIcon />}
        {iconType && iconType === 'groups' && <GroupsOutlinedIcon />}
        {iconType && iconType === 'article' && <ArticleOutlinedIcon />}
        {iconType && iconType === 'calendar' && <CalendarMonthOutlinedIcon />}
        {iconType && iconType === 'group' && <GroupOutlinedIcon />}
        {iconType && iconType === 'dateRange' && <DateRangeOutlinedIcon />}
        <p>{title}</p>
      </div>
    </section>
  );
};

export default TitleBar;
