import { SyntheticEvent, useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';
import useFetchLocations from 'Query/useFetchLocation';
import { useUserContext } from '../Providers/UserContext';
import { Location } from '../../utils/types';

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

interface LocationsAutocompleteCheckboxProps {
  selectedLocationsLicenseDetails: Location[]; // updated seleced Locations from parent
  onLocationSelect: (val: Location[]) => void;
}

const theme = createTheme({
  palette: {
    secondary: {
      main: '#9E007E',
      light: '#faf2f9',
    },
  },
});

const LocationsAutocompleteCheckbox = ({
  selectedLocationsLicenseDetails,
  onLocationSelect,
}: LocationsAutocompleteCheckboxProps) => {
  const { userToken } = useUserContext();
  const { data: Locations } = useFetchLocations(userToken);
  const [locationOptions, setLocationOptions] = useState<Location[]>([]);
  // const [selectedLocationOptions, setSelectedLocationOptions] = useState<Location[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Location[]>([]);

  // TODO: make sure no "Any location" goes out of component
  // TODO: prevent incomplete Location from options- if there's no code, get from Locations.
  useEffect(() => {
    if (Locations) {
      setLocationOptions([
        {
          id: 0,
          location: 'Any location',
          code: 'Any location',
        },
        ...Locations.reverse(),
      ]);
    }
  }, [Locations]);

  // set selectedLocations from cmp as selected:
  useEffect(() => {
    setSelectedOptions(selectedLocationsLicenseDetails);
  }, [selectedLocationsLicenseDetails]);

  const locationFormatted = (location: Location) => {
    const fullLoc = Locations?.find((loc) => loc.id === location.id);
    if (fullLoc) {
      if (fullLoc.location === 'Other') {
        return fullLoc.location;
      }
      return `${fullLoc.code + ' - '}${fullLoc.location}`;
    }
    return `${location.code + ' - '}${location.location}`;
  };

  const handleChange = (event: SyntheticEvent<Element, Event>, value: Location[]) => {
    if (value.some((option: Location) => option.id === 0)) {
      if (selectedOptions.length >= locationOptions.length) {
        // DESELECT ALL
        setSelectedOptions([]);
        // update in parent cmp:
        onLocationSelect([]);
      } else {
        // SELECT ALL
        setSelectedOptions(locationOptions);
        // update in parent cmp:
        onLocationSelect(locationOptions.filter((el) => el.id !== 0));
      }
    } else {
      setSelectedOptions(value);
      // update in parent cmp:
      onLocationSelect(value);
    }
  };

  const handleChangeAnyLocation = () => {
    if (selectedOptions.length === locationOptions?.length) {
      setSelectedOptions([]);
      // update in parent cmp:
      onLocationSelect([]);
    } else {
      setSelectedOptions([...locationOptions]);
      // update in parent cmp:
      onLocationSelect(locationOptions.filter((el) => el.id !== 0));
    }
  };

  const getOptionCheck = (option: Location) => {
    if (selectedLocationsLicenseDetails.find((loc) => loc.id === option.id)) {
      return true;
    }
    return false;
  };

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        color="secondary"
        multiple
        id="checkboxes-tags"
        options={locationOptions}
        disableCloseOnSelect
        value={selectedOptions}
        onChange={handleChange}
        getOptionLabel={(option) => locationFormatted(option)}
        renderOption={(props, option) => (
          <li {...props} className="location-autocomplete-checkbox-list-item">
            {option.id === 0 ? (
              <FormControlLabel
                color="secondary"
                label="Any location"
                control={
                  <Checkbox
                    color="secondary"
                    checked={selectedOptions.length === locationOptions?.length}
                    indeterminate={
                      selectedOptions.length > 0 && selectedOptions.length < locationOptions?.length
                    }
                    onChange={handleChangeAnyLocation}
                  />
                }
              />
            ) : (
              <>
                <Checkbox
                  color="secondary"
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={getOptionCheck(option)}
                />
                {locationFormatted(option)}
              </>
            )}
          </li>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField color="secondary" {...params} placeholder="Select rate(s)" />
        )}
        renderTags={(value: readonly Location[], getTagProps) =>
          value.map((option: Location, index: number) => {
            if (option.id !== 0) {
              return (
                <Chip
                  variant="filled"
                  label={locationFormatted(option)}
                  {...getTagProps({ index })}
                  key={option.location}
                  sx={{ borderRadius: '4px', backgroundColor: '#F5F5F5' }}
                />
              );
            }
          })
        }
      />
    </ThemeProvider>
  );
};

export default LocationsAutocompleteCheckbox;
