import { useQueryClient, useMutation } from '@tanstack/react-query';
import { removeAllUpcomingSessionsPrivate } from '../utils/Network';

type RemoveAllUpcomingSessionsByGroupRequest = {
  startDate: string;
  groupId: number;
  userToken: string;
};

const _removeAllUpcomingSessionsByGroup = async (data: RemoveAllUpcomingSessionsByGroupRequest) => {
  return removeAllUpcomingSessionsPrivate(data.startDate, data.groupId, data.userToken);
};

const useRemoveAllUpcomingSessionsByGroup = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: _removeAllUpcomingSessionsByGroup,

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['privateGroups'],
      });
    },
  });
};

export default useRemoveAllUpcomingSessionsByGroup;
