import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
import ReportSharpIcon from '@mui/icons-material/ReportSharp';
import { useUserContext } from '../Providers/UserContext';
import useFetchGSELevel from 'Query/useFetchGSELevel';
import useFetchVendors from 'Query/useFetchVendors';
import PinkBtn from '../common/PinkBtn';
import TitleBar from '../common/TitleBar';
import InfoBadge from '../common/InfoBadge';
import BackButton from '../layout/BackButton';
import DynamicButton from '../common/DynamicButton';
import SingleSelectBtns from '../form/SingleSelectBtns';
import { usePrivateGroupsContext } from '../../providers/privateGroupsContext';
import {
  getFormattedGender,
  getDayKeyAsDayNumber,
  getFirstAvailableDate,
} from '../../utils/utilsts';
import { RequiredSummary } from './RequiredSummary';
import { SessionsResponseSummary } from './SessionsResponseSummary';
import useCreateMultipleSessions from '../../useMutation/useCreateMultipleSessions';

import {
  GSELevel,
  contentSensitivity,
  Location,
  Vendor,
  MultipleSessionsRequestData,
  Session,
  ExclusionInterval,
  License,
} from '../../utils/types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface SessionAddProps {
  setIsScheduleTab: (val: boolean) => void;
}

const AddMultipleSessionsPrivate = ({ setIsScheduleTab }: SessionAddProps) => {
  const { userToken } = useUserContext();
  const { mutateAsync: createMultipleSessions } = useCreateMultipleSessions();
  const { data: GSELevels } = useFetchGSELevel(userToken);
  const { data: Vendors } = useFetchVendors(userToken);
  const { selectedGroup, selectedOrg } = usePrivateGroupsContext();
  const selectDurationRef = useRef<HTMLSelectElement>(null);

  const navigate = useNavigate();
  const [selectedGSELevels, setSelectedGSELevels] = useState(selectedGroup?.GSELevels || []);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const today = dayjs();
  const [summaryModalData, setSummaryModalData] = useState<Session[]>([]);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState<boolean>(false);
  const [isSubmitBtnEnabled, setIsSubmitBtnEnabled] = useState<boolean>(false);

  const currLicense: License | undefined =
    selectedOrg?.licenses && selectedOrg.licenses.length > 0
      ? selectedOrg.licenses.find((license) =>
          today.isBetween(license.startDate, license.endDate, null, '[]'),
        )
      : undefined;

  const getOffset = (timeZone: string): number => {
    const match = timeZone.match(/^UTC([+-])(\d{2}):(\d{2})$/);
    if (match) {
      const sign = match[1] === '+' ? 1 : -1;
      const hours = parseInt(match[2], 10);
      const minutes = parseInt(match[3], 10);
      return sign * (hours + minutes / 60);
    } else {
      try {
        return dayjs.utc(new Date()).tz(timeZone).utcOffset() / 60;
      } catch (error) {
        console.log(error);
        return 0;
      }
    }
  };

  const weekdaysNoInterval = selectedGroup?.schedulingWindow?.filter(
    (el) => el.intervals.length === 0,
  );
  const schedulingWindowsDisableDays = weekdaysNoInterval?.map((el) =>
    getDayKeyAsDayNumber(el.key),
  );

  const checkDisableDate = (date: dayjs.Dayjs): boolean => {
    if (date.isAfter(currLicense?.endDate)) {
      return true;
    }
    const exclusionDates = selectedOrg?.exclusionDates || [];

    // disable weekdays by schedulingWindow:
    const day = date.day();
    if (schedulingWindowsDisableDays?.some((el) => el === day)) {
      return true;
    }
    // exclusion dates logic:
    if (exclusionDates.length > 0) {
      return exclusionDates.some((range: ExclusionInterval) =>
        dayjs(date).isBetween(
          dayjs(range.startDate),
          dayjs(range.endDate),
          null,
          '[]', // Inclusive of start and end date
        ),
      );
    }
    return false;
  };

  const getInitialStartDate = (): string => {
    let initialStartDate = dayjs().format('YYYY-MM-DD');

    if (selectedGroup && selectedGroup.lastSessionDate) {
      if (!dayjs(initialStartDate).isAfter(selectedGroup.lastSessionDate)) {
        initialStartDate = dayjs(selectedGroup.lastSessionDate).add(1, 'day').format('YYYY-MM-DD');
      }
    }
    if (dayjs(initialStartDate).isBefore(currLicense?.startDate)) {
      initialStartDate = dayjs(currLicense?.startDate).format('YYYY-MM-DD');
    }
    const allowedDaysOfWeekKey = selectedGroup?.schedulingWindow?.filter(
      (el) => el.intervals.length > 0,
    );
    const allowedDaysOfWeek = allowedDaysOfWeekKey?.map((el) => getDayKeyAsDayNumber(el.key)) as
      | number[];
    if (initialStartDate) {
      const getClosestAvailableDay = getFirstAvailableDate(
        initialStartDate,
        selectedOrg?.exclusionDates || [],
        allowedDaysOfWeek,
      );
      if (getClosestAvailableDay) {
        initialStartDate = getClosestAvailableDay;
      }
    }
    return initialStartDate;
  };

  const [lastSessionDate, setLastSessionDate] = useState<string | null>(getInitialStartDate());
  const [maxDate, setMaxDate] = useState<string>(dayjs(currLicense?.endDate).format('YYYY-MM-DD'));

  useEffect(() => {
    const minDate = getInitialStartDate();
    if (minDate) {
      setLastSessionDate(minDate);
    }
  }, [selectedGroup, lastSessionDate]);

  useEffect(() => {
    const maxDateCalc = dayjs(currLicense?.endDate).format('YYYY-MM-DD');
    if (maxDateCalc) {
      setMaxDate(maxDateCalc);
    }
  }, [currLicense?.endDate]);

  // requestData:

  const [multipleSessionsData, setMultipleSessionsData] = useState<MultipleSessionsRequestData>({
    groupId: selectedGroup?.id || null,
    timeFrame: {
      startDate: getInitialStartDate(),
      endDate: dayjs(currLicense?.endDate).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD'),
      offset: selectedGroup?.timezone ? getOffset(selectedGroup?.timezone) : 0,
    },
    GSELevels: selectedGroup?.GSELevels || [],
  });

  useEffect(() => {
    // on refresh or using url or dependencies change:
    // 1.reset query:
    if (!multipleSessionsData.groupId && selectedGroup && currLicense) {
      setMultipleSessionsData({
        groupId: selectedGroup.id,
        timeFrame: {
          startDate: getInitialStartDate(),
          endDate: dayjs(currLicense?.endDate).format('YYYY-MM-DD'),
          offset: selectedGroup?.timezone ? getOffset(selectedGroup?.timezone) : 0,
        },
        GSELevels: selectedGroup?.GSELevels,
      });
    }
  }, [
    selectedGroup?.id,
    selectedOrg,
    currLicense?.endDate,
    selectedGroup?.GSELevels,
    lastSessionDate,
  ]);

  // utils:

  const locationFormatted = (location: Location) => {
    return `${location?.code?.length ? location.code + ' - ' : ''}${location.location}`;
  };

  // mui:

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#9E007E',
        light: '#faf2f9',
      },
    },
  });

  const getEffectiveEndDate = (calcEndDate: string): string => {
    if (currLicense && dayjs(calcEndDate).isAfter(dayjs(currLicense.endDate))) {
      return dayjs(currLicense.endDate).format('YYYY-MM-DD');
    }
    return calcEndDate;
  };

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setMultipleSessionsData((prevData) => ({
        ...prevData,
        timeFrame: {
          ...prevData.timeFrame,
          startDate: date?.format('YYYY-MM-DD'),
        },
      }));
      // recalculate endDate:
      if (!isUntilLicenseEnds) {
        if (durationSingleSelect === 'Months' && selectMonthsVal) {
          const calcEndDate = dayjs(date).add(selectMonthsVal, 'month').format('YYYY-MM-DD');
          if (calcEndDate) {
            setMultipleSessionsData((prevData) => ({
              ...prevData,
              timeFrame: {
                ...prevData.timeFrame,
                endDate: getEffectiveEndDate(calcEndDate),
              },
            }));
          }
        }
        if (durationSingleSelect === 'Weeks' && selectWeeksVal) {
          const calcEndDate = date.add(selectWeeksVal, 'week').format('YYYY-MM-DD');
          if (calcEndDate) {
            setMultipleSessionsData((prevData) => ({
              ...prevData,
              timeFrame: {
                ...prevData.timeFrame,
                endDate: getEffectiveEndDate(calcEndDate),
              },
            }));
          }
        }
      }
    }
  };

  // duration picker:

  const [selectMonthsVal, setSelectMonthsVal] = useState<number | undefined>(undefined);

  const [selectWeeksVal, setSelectWeeksVal] = useState<number | undefined>(undefined);

  const [isUntilLicenseEnds, setIsUntilLicenseEnds] = useState<boolean>(true);

  const [durationSingleSelect, setDurationSingleSelect] = useState<string>('');

  const handleDurationSingleSelectChange = (name: string, option: string) => {
    if (isUntilLicenseEnds) {
      setIsUntilLicenseEnds(false);
    }
    setDurationSingleSelect(option);
  };

  useEffect(() => {
    handleDurationSingleSelectChange('duration', 'Weeks');
  }, [selectedGroup]);

  const handleIsUntilLicenseEndsChange = () => {
    if (durationSingleSelect.length) {
      setIsUntilLicenseEnds(!isUntilLicenseEnds);
      setDurationSingleSelect('');
      if (currLicense) {
        setMultipleSessionsData((prevData) => ({
          ...prevData,
          timeFrame: {
            ...prevData.timeFrame,
            endDate: dayjs(currLicense.endDate).format('YYYY-MM-DD'),
          },
        }));
      }
    }
  };

  const handleSetDuration = (val: string) => {
    durationSingleSelect === 'Months'
      ? setSelectMonthsVal(parseInt(val))
      : setSelectWeeksVal(parseInt(val));

    if (durationSingleSelect === 'Months' && val) {
      const calcEndDate = dayjs(multipleSessionsData.timeFrame.startDate)
        .add(parseInt(val), 'month')
        .format('YYYY-MM-DD');

      setMultipleSessionsData((prevData) => ({
        ...prevData,
        timeFrame: {
          ...prevData.timeFrame,
          endDate: getEffectiveEndDate(calcEndDate),
        },
      }));
    }

    if (durationSingleSelect === 'Weeks' && val) {
      const calcEndDate = dayjs(multipleSessionsData.timeFrame.startDate)
        .add(parseInt(val), 'week')
        .format('YYYY-MM-DD');

      setMultipleSessionsData((prevData) => ({
        ...prevData,
        timeFrame: {
          ...prevData.timeFrame,
          endDate: getEffectiveEndDate(calcEndDate),
        },
      }));
    }

    if (selectDurationRef.current) {
      selectDurationRef.current?.focus();
    }
  };

  const handleSelectDuration = (event: SelectChangeEvent) => {
    const val = event.target.value;
    handleSetDuration(val);
  };

  // levels logic:
  const handleGSELevelClick = (level: GSELevel) => {
    const updatedGSELevels = selectedGSELevels.some((sGSElv) => sGSElv.id === level.id)
      ? selectedGSELevels.filter((l) => l.id !== level.id)
      : selectedGSELevels && [
          ...selectedGSELevels,
          {
            id: level.id,
            level: level.level,
            code: level.code,
            minGSE: level.minGSE,
            maxGSE: level.maxGSE,
          },
        ];
    setSelectedGSELevels(updatedGSELevels);
    setMultipleSessionsData((prevData) => ({
      ...prevData,
      GSELevels: updatedGSELevels,
    }));
  };

  // vendors logic:
  const handleVendorsChange = (event: SyntheticEvent<Element, Event>, value: Vendor | null) => {
    if (value) {
      setSelectedVendor(value);
    }
  };

  // useEffects:

  useEffect(() => {
    if (selectedGroup?.GSELevels && selectedGroup.GSELevels.length) {
      setSelectedGSELevels(selectedGroup.GSELevels);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (Vendors) {
      if (Vendors.some((vendor: Vendor) => vendor.vendor === 'VIPKid')) {
        const vipKids = Vendors.find((vendor: Vendor) => vendor.vendor === 'VIPKid');
        if (vipKids) {
          setSelectedVendor(vipKids);
        }
      }
    }
  }, [Vendors, selectedGroup]);

  // handle submit and navigation:

  const handleSubmit = async () => {
    console.log('Submitted: ', multipleSessionsData);
    if (multipleSessionsData.groupId && multipleSessionsData.GSELevels) {
      const req = {
        groupId: multipleSessionsData.groupId,
        timeFrame: multipleSessionsData.timeFrame,
        GSELevels: multipleSessionsData.GSELevels,
        userToken,
      };
      const multipleSessionsRes = await createMultipleSessions(req);
      if (multipleSessionsRes) {
        setSummaryModalData(multipleSessionsRes);
      }
    }
    setIsSummaryModalOpen(true);
  };

  const handleBackBtn = () => {
    setIsScheduleTab(true);
  };

  const getMinDuration = () => {
    if (isUntilLicenseEnds) return true;
    return (
      (durationSingleSelect === 'Weeks' && weekOptions.length > 0) ||
      (durationSingleSelect === 'Months' && monthOptions.length > 0)
    );
  };

  const isCreateSessionsDisabled = () => {
    if (
      multipleSessionsData.groupId &&
      multipleSessionsData.timeFrame.startDate &&
      multipleSessionsData.timeFrame.endDate &&
      dayjs(multipleSessionsData.timeFrame.endDate).isAfter(
        multipleSessionsData.timeFrame.startDate,
      ) &&
      multipleSessionsData.GSELevels &&
      multipleSessionsData.GSELevels.length > 0 &&
      getMinDuration()
    ) {
      setIsSubmitBtnEnabled(true);
    } else {
      setIsSubmitBtnEnabled(false);
    }
  };

  useEffect(() => {
    isCreateSessionsDisabled();
  }, [
    isUntilLicenseEnds,
    multipleSessionsData.timeFrame.startDate,
    multipleSessionsData.timeFrame.endDate,
    selectedGSELevels,
    selectWeeksVal,
    selectMonthsVal,
    selectedVendor,
    selectedGroup,
    lastSessionDate,
    durationSingleSelect,
  ]);

  // Duration handling

  const [monthOptions, setMonthOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [weekOptions, setWeekOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const generateMonthOptions = (startDate: string, endDate: string) => {
    const monthToEndDate = dayjs(endDate).diff(dayjs(startDate), 'month');
    const initial = monthToEndDate ? Array.from({ length: monthToEndDate }, (v, i) => i + 1) : [];
    return initial.map((el) => ({
      label: `${el}`,
      value: el.toString(),
    }));
  };

  const generateWeekOptions = (startDate: string, endDate: string) => {
    const weeksToEndDate = dayjs(endDate).diff(dayjs(startDate), 'week');
    const initial = weeksToEndDate ? Array.from({ length: weeksToEndDate }, (v, i) => i + 1) : [];
    return initial.map((el) => ({ label: `${el}`, value: el.toString() }));
  };

  useEffect(() => {
    let startDateForOptions = null;
    if (lastSessionDate) {
      startDateForOptions = dayjs().isAfter(multipleSessionsData.timeFrame.startDate)
        ? dayjs().isAfter(lastSessionDate)
          ? dayjs().format('YYYY-MM-DD')
          : dayjs(lastSessionDate).format('YYYY-MM-DD')
        : dayjs(multipleSessionsData.timeFrame.startDate).format('YYYY-MM-DD');
    } else {
      startDateForOptions = dayjs().isAfter(multipleSessionsData.timeFrame.startDate)
        ? dayjs().format('YYYY-MM-DD')
        : dayjs(multipleSessionsData.timeFrame.startDate).format('YYYY-MM-DD');
    }
    if (startDateForOptions) {
      const generatedMonthOptions = generateMonthOptions(
        startDateForOptions,
        dayjs(currLicense?.endDate).format('YYYY-MM-DD'),
      );
      if (generatedMonthOptions) {
        setMonthOptions(generatedMonthOptions);
      }
      const generatedWeekOptions = generateWeekOptions(
        startDateForOptions,
        dayjs(currLicense?.endDate).format('YYYY-MM-DD'),
      );
      if (generatedWeekOptions) {
        setWeekOptions(generatedWeekOptions);
      }
    }
  }, [
    currLicense?.startDate,
    currLicense?.endDate,
    selectedGroup,
    lastSessionDate,
    multipleSessionsData.timeFrame.startDate,
  ]);

  useEffect(() => {
    if (durationSingleSelect === 'Weeks' && weekOptions.length > 0) {
      setSelectWeeksVal(parseInt(weekOptions[weekOptions.length - 1].value));
    } else if (durationSingleSelect === 'Months' && monthOptions.length > 0) {
      setSelectMonthsVal(parseInt(monthOptions[monthOptions.length - 1].value));
    }
  }, [
    durationSingleSelect,
    weekOptions,
    monthOptions,
    currLicense?.endDate,
    lastSessionDate,
    selectedGroup,
  ]);

  const handleCloseSummary = () => {
    navigate(`/privatesessions/${selectedGroup?.id}`);
    setIsScheduleTab(true);
    setIsSummaryModalOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <section
        className="session-edit-section"
        style={{ flex: '1', width: '890px', paddingTop: '36px', background: 'white' }}
      >
        <div className="session-edit-container">
          <BackButton
            onClick={handleBackBtn}
            navigatePath={`/privatesessions/${selectedGroup?.id}`}
          />

          <div className="session-add-title flex column gap-8" style={{ paddingTop: '32px' }}>
            {selectedGroup && (
              <p style={{ fontSize: '12px', color: '#505759', margin: '0' }}>
                {' '}
                {selectedGroup.name}
              </p>
            )}
            <h1 style={{ margin: '0', fontFamily: 'OpenSans-Regular', fontWeight: '400' }}>
              {' '}
              Add multiple sessions
            </h1>
            <p style={{ fontFamily: 'OpenSans-SemiBold' }}>
              This schedule of live sessions will apply to all licenses bought for this group
            </p>
          </div>

          <div className="session-add-data-container">
            <div className="license-agreement-details" style={{ width: '100%' }}>
              <TitleBar title={'Overview of license agreement'} iconType={'article'} />
              <div className="details-list no-padding flex column gap-16">
                {/*  */}
                <div className="details-item flex">
                  <div className="info-title">Start date</div>
                  <div className="detail-content flex gap-8">
                    {currLicense && (
                      <p className="details">{dayjs(currLicense.startDate).format('DD/MM/YYYY')}</p>
                    )}
                  </div>
                </div>
                <div className="details-item flex">
                  <div className="info-title">End date</div>
                  <div className="detail-content flex gap-8">
                    {currLicense && (
                      <p className="details">{dayjs(currLicense.endDate).format('DD/MM/YYYY')}</p>
                    )}
                  </div>
                </div>
                <div className="details-item flex">
                  <div className="info-title">Duration</div>
                  <div className="detail-content flex gap-8">
                    {currLicense && (
                      <>
                        <p className="details">
                          {dayjs(currLicense.endDate).diff(dayjs(currLicense.startDate), 'month') >
                            1 &&
                            `${dayjs(currLicense.endDate).diff(
                              dayjs(currLicense.startDate),
                              'month',
                            )} month`}
                        </p>
                        <p className="details">
                          {dayjs(currLicense.endDate).diff(dayjs(currLicense.startDate), 'week') >
                            1 &&
                            `(${dayjs(currLicense.endDate).diff(
                              dayjs(currLicense.startDate),
                              'week',
                            )} weeks)`}
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <div className="details-item flex">
                  <div className="info-title">Expires in</div>
                  <div className="detail-content flex gap-8">
                    {currLicense && (
                      <>
                        <p className="details">
                          {dayjs(currLicense.endDate).diff(dayjs(), 'month') > 1 &&
                            `${dayjs(currLicense.endDate).diff(dayjs(), 'month')} month`}
                        </p>
                        <p className="details">
                          {dayjs(currLicense.endDate).diff(dayjs(), 'week') > 1 &&
                            `(${dayjs(currLicense.endDate).diff(dayjs(), 'week')} weeks)`}
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <div className="details-item flex">
                  <div className="info-title">Learner time zone(s)</div>
                  <div className="detail-content flex gap-8">
                    <p className="details">
                      {' '}
                      {selectedGroup?.timezone ? selectedGroup.timezone : 'null - default UTC'}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="date-range">
              <TitleBar title={'Date range'} iconType={'dateRange'} />
              <div className="details-list no-padding flex column gap-16">
                {selectedGroup && selectedGroup.lastSessionDate && (
                  <div className="required-warning">
                    <RequiredSummary existingSessionsNote noMargin />
                  </div>
                )}
                <div className="datepicker">
                  <p className="form-label">From</p>
                  <DatePicker
                    value={dayjs(multipleSessionsData.timeFrame.startDate)}
                    maxDate={dayjs(maxDate)}
                    minDate={dayjs(lastSessionDate)}
                    disablePast
                    shouldDisableDate={checkDisableDate}
                    onChange={(newVal) => handleStartDateChange(newVal)}
                    format={'DD/MM/YYYY'}
                    slots={{
                      openPickerIcon: CalendarMonthOutlinedIcon,
                    }}
                    slotProps={{
                      openPickerIcon: {
                        style: {
                          color: 'black',
                        },
                      },
                      layout: {
                        sx: {
                          borderRadius: '8px',
                        },
                      },
                      day: {
                        sx: {
                          fontFamily: 'OpenSans',
                          '&:hover': {
                            fontWeight: '600',
                            color: '#9E007E',
                            border: '2px solid',
                            borderColor: '#9E007E',
                            backgroundColor: 'rgba(158,0, 26,0.05)',
                          },
                          '&.Mui-selected': {
                            color: 'white',
                            backgroundColor: '#9E007E !important',
                            '&:hover': {
                              backgroundColor: '#722351',
                              border: 'none',
                              fontWeight: '500',
                            },
                          },
                          '&:disabled': {
                            color: 'rgba(145, 145, 145, 1)',
                          },
                          '&.MuiPickersDay-today': {
                            border: 'none',
                            backgroundColor: '#E6E6E6',
                            '&:hover': {
                              fontWeight: '600',
                              color: '#9E007E',
                              border: '2px solid',
                              borderColor: '#9E007E',
                              backgroundColor: 'rgba(158,0, 26,0.05)',
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
                <div className="select-duration flec column">
                  <p className="form-label">For</p>
                  <div className="select-options flex gap-24">
                    <Select
                      value={
                        durationSingleSelect === 'Weeks'
                          ? selectWeeksVal?.toString() || ''
                          : durationSingleSelect === 'Months'
                          ? selectMonthsVal?.toString() || ''
                          : ''
                      }
                      placeholder={'Select'}
                      onChange={handleSelectDuration}
                      displayEmpty
                      sx={{ width: '127px', height: '50px' }}
                      disabled={
                        durationSingleSelect.length === 0 ||
                        dayjs(multipleSessionsData.timeFrame.startDate).isAfter(
                          multipleSessionsData.timeFrame.endDate,
                        )
                      }
                      ref={selectDurationRef}
                    >
                      {durationSingleSelect === 'Weeks' &&
                        weekOptions &&
                        weekOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      {durationSingleSelect === 'Months' &&
                        monthOptions &&
                        monthOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </Select>

                    <div>
                      <SingleSelectBtns
                        name={'duration'}
                        onChange={handleDurationSingleSelectChange}
                        value={durationSingleSelect}
                        selectOptions={['Weeks', 'Months']}
                        noMargin
                        large
                      />
                    </div>

                    <div
                      className="flex gap-8 align-center"
                      style={{ fontFamily: 'OpenSans-Regular', textAlign: 'center' }}
                    >
                      <div
                        className="separator"
                        style={{ width: '34px', height: '1px', background: '#DFE1E1' }}
                      ></div>
                      or
                      <div
                        className="separator"
                        style={{ width: '34px', height: '1px', background: '#DFE1E1' }}
                      ></div>
                    </div>

                    <div>
                      <PinkBtn
                        isSelected={isUntilLicenseEnds}
                        onClick={() => handleIsUntilLicenseEndsChange()}
                      >
                        Until license ends
                      </PinkBtn>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="profiency-details">
              <TitleBar
                title={'Learner proficiency and content type'}
                iconType={'teachingContent'}
              />
              <div className="flex column" style={{ gap: '24px' }}>
                {GSELevels && (
                  <div className="teaching-content-input">
                    <div className="flex">
                      <p className="form-label">CEFR/GSE level</p>
                      <p>&nbsp;(Select all that apply)</p>
                    </div>
                    <div className="choice-btns-list">
                      {GSELevels?.map((level, index) => (
                        <PinkBtn
                          small
                          key={level + '-' + index}
                          disable={
                            selectedGroup?.GSELevels
                              ? !selectedGroup?.GSELevels?.some((el) => el.code === level.code)
                              : false
                          }
                          isSelected={
                            selectedGSELevels &&
                            selectedGSELevels?.some((sGSElv) => sGSElv.id === level.id)
                          }
                          onClick={() => handleGSELevelClick(level)}
                        >
                          {level.level}
                        </PinkBtn>
                      ))}{' '}
                    </div>
                  </div>
                )}
                <div className="flex gap-24 align-center">
                  <p style={{ paddingInlineEnd: '24px' }}>Content sensitivity</p>
                  <InfoBadge small>
                    {selectedGroup?.contentSensitivity ? (
                      <ReportSharpIcon />
                    ) : (
                      <ReportOffOutlinedIcon />
                    )}

                    {selectedGroup?.contentSensitivity
                      ? contentSensitivity.Adapted
                      : contentSensitivity.standard}
                  </InfoBadge>
                </div>
              </div>
            </div>

            <div className="session-add-info-container">
              <TitleBar title={'Tutor'} iconType={'person'} />
              <div className="details-list no-padding flex column gap-16">
                <div className="details-item flex align-center">
                  <div className="info-title flex align-center gap-8">Regional tutor rate(s) </div>
                  <div className="detail-content flex gap-8 align-center">
                    {selectedGroup?.locations &&
                      selectedGroup.locations.map((loc, index) => (
                        <InfoBadge small key={index} disableHeight>
                          {loc && (
                            <div className="flex column justify-center align-center">
                              {locationFormatted(loc)}
                            </div>
                          )}
                        </InfoBadge>
                      ))}
                  </div>
                </div>
                <div className="details-item flex">
                  <div className="info-title">Gender</div>
                  <div className="detail-content flex gap-8">
                    {selectedGroup?.gender && <p>{getFormattedGender(selectedGroup.gender)}</p>}
                  </div>
                </div>
                {Vendors && selectedVendor && (
                  <div className="flex column">
                    <p className="form-label">Vendor</p>
                    <ThemeProvider theme={theme}>
                      <Autocomplete
                        // multiple
                        options={Vendors}
                        value={selectedVendor}
                        disableClearable
                        onChange={handleVendorsChange}
                        getOptionLabel={(option) => option.vendor}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              color="secondary"
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.vendor}
                          </li>
                        )}
                        style={{ width: 500 }}
                        renderInput={(params) => <TextField {...params} placeholder="Select" />}
                        renderTags={(value: readonly Vendor[], getTagProps) =>
                          value.map((option: Vendor, index: number) => {
                            return (
                              <Chip
                                variant="filled"
                                label={option.vendor}
                                {...getTagProps({ index })}
                                key={option.vendor}
                                sx={{ borderRadius: '4px', backgroundColor: '#F5F5F5' }}
                              />
                            );
                          })
                        }
                      />
                    </ThemeProvider>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="session-add-action-btn-container">
          <div className="topic-edit-action-btns">
            <div className="submit-btns">
              <DynamicButton
                size="large"
                type="primary"
                disable={!isSubmitBtnEnabled}
                onClick={() => handleSubmit()}
              >
                Publish sessions
              </DynamicButton>
              <DynamicButton
                size="large"
                type="tertiary"
                onClick={() => {
                  navigate(`/privatesessions/${selectedGroup?.id}`);
                }}
              >
                Cancel
              </DynamicButton>
            </div>
          </div>
        </div>
        <p style={{ fontFamily: 'OpenSans-SemiBold' }}>Data summary FOR TESTING:</p>

        <pre>{JSON.stringify(multipleSessionsData, null, 2)}</pre>
        <Dialog
          open={isSummaryModalOpen && summaryModalData && summaryModalData.length > 0}
          onClose={handleCloseSummary}
          maxWidth={'xl'}
        >
          <SessionsResponseSummary
            sessions={summaryModalData}
            group={selectedGroup}
            orgData={selectedOrg}
          />
        </Dialog>
      </section>
    </LocalizationProvider>
  );
};

export default AddMultipleSessionsPrivate;
