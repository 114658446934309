import { useQuery } from '@tanstack/react-query';
import { getGroups } from '../utils/Network';
import { Children } from '@utils/types';
import { TIME } from '../utils/constants';

const STALE_TIME = TIME.minute * 120;
const CACHE_TIME = TIME.minute + 120 * TIME.second;

const useFetchPrivateGroups = (userToken: string) =>
  useQuery<Children[]>({
    queryKey: ['privateGroups'],
    queryFn: async () => getGroups(userToken),
    staleTime: STALE_TIME,
    gcTime: CACHE_TIME,
    enabled: !!userToken,
    refetchOnWindowFocus: false,
  });

export default useFetchPrivateGroups;
