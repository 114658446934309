import { useEffect, useState } from 'react';
import SessionTableTabs from './SessionTableTabs';
import SessionTable from './SessionTable';
import { Session } from '@utils/types';
import { SessionTableTimezonePicker } from '../Session/SessionTableTimezonePicker';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

interface SessionDashboardTableProps {
  sessions: Session[] | undefined;
  isLoading?: boolean;
  tableTimezone: string;
  handleTimeZoneChange?: (selectedTimezone: string) => void;
}

const SessionDashboardTable = ({
  sessions,
  isLoading = false,
  tableTimezone = 'EST',
  handleTimeZoneChange,
}: SessionDashboardTableProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [filteredSessions, setFilteredSessions] = useState<Session[] | undefined>(sessions);
  dayjs.extend(isBetween);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const completedSessionsLength = () => {
    return (
      sessions?.filter((session) => dayjs(session.date).add(44, 'minute').isBefore(dayjs()))
        .length || 0
    );
  };

  const liveSessionsLength = () => {
    return (
      sessions?.filter((session) =>
        dayjs().isBetween(session.date, dayjs(session.date).add(44, 'minute')),
      ).length || 0
    );
  };

  const upcomingSessionsLength = () => {
    // pending tab
    return sessions?.filter((session) => dayjs(session.date).isAfter(dayjs())).length || 0;
  };

  const filterSessionsByTab = () => {
    if (tabValue === 0) {
      setFilteredSessions(sessions);
    } else if (tabValue === 1) {
      const filtered = sessions?.filter((session) =>
        dayjs(session.date).add(44, 'minute').isBefore(dayjs()),
      );
      setFilteredSessions(filtered);
    } else if (tabValue === 2) {
      const filtered = sessions?.filter((session) =>
        dayjs().isBetween(session.date, dayjs(session.date).add(44, 'minute')),
      );
      setFilteredSessions(filtered);
    } else if (tabValue === 3) {
      const filtered = sessions?.filter((session) => dayjs(session.date).isAfter(dayjs()));
      setFilteredSessions(filtered);
    } else return sessions;
  };

  useEffect(() => {
    filterSessionsByTab();
  }, [tabValue, sessions]);

  return (
    <div className="session-dashboard-table-container ">
      <div className="tabs-container flex space-between">
        <SessionTableTabs
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          allSessionsLength={sessions?.length || 0}
          completedSessionsLength={completedSessionsLength()}
          liveSessionsLength={liveSessionsLength()}
          upcomingSessionsLength={upcomingSessionsLength()}
        />
        {tableTimezone && handleTimeZoneChange && (
          <div className="timezone-display">
            <SessionTableTimezonePicker
              tableTimezone={tableTimezone}
              handleTimeZoneChange={handleTimeZoneChange}
            />
          </div>
        )}
        {tableTimezone && !handleTimeZoneChange && <p>{tableTimezone}</p>}
      </div>
      <div className="table-container">
        <SessionTable sessions={filteredSessions} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default SessionDashboardTable;
