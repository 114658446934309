import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ExclusionInterval } from '../../utils/types';
import dayjs, { Dayjs } from 'dayjs';

interface DateRangePickerProps {
  dateRange: ExclusionInterval;
  id: number;
  diff: number;
  enableDelete?: boolean;
  onChange: (newValue: ExclusionInterval, id: number, isValid: boolean) => void;
  onDateRangeDelete: (id: number) => void;
  maxDate: string;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  dateRange,
  onChange,
  id,
  diff,
  enableDelete = false,
  onDateRangeDelete,
  maxDate,
}) => {
  const [firstStartDateSelect, setFirstStartDateSelect] = useState<boolean>(true);
  const [firstEndDateSelect, setFirstEndDateSelect] = useState<boolean>(true);
  const dayjsFormat = (date?: string) => {
    return date ? dayjs(date) : dayjs();
  };

  const [localStartDate, setLocalStartDate] = useState(dayjsFormat(dateRange.startDate));

  const [localEndDate, setLocalEndDate] = useState(dayjsFormat(dateRange.endDate));

  const handleStartDateChange = (newStart: Dayjs | null) => {
    if (newStart) {
      onChange(
        {
          startDate: newStart?.format('YYYY-MM-DD'),
          endDate: localEndDate.format('YYYY-MM-DD'),
        },
        id,
        firstEndDateSelect ? false : true,
      );
    }
    if (firstStartDateSelect) {
      setFirstStartDateSelect(false);
    }
    const updatedStartDate = newStart;
    if (updatedStartDate) {
      setLocalStartDate(updatedStartDate);
    }
  };

  const handleEndDateChange = (newEnd: Dayjs | null) => {
    if (newEnd) {
      onChange(
        {
          startDate: localStartDate.format('YYYY-MM-DD'),
          endDate: newEnd.format('YYYY-MM-DD'),
        },
        id,
        firstStartDateSelect ? false : true,
      );
    }
    if (firstEndDateSelect) {
      setFirstEndDateSelect(false);
    }
    const updatedEndDate = newEnd;
    if (updatedEndDate) {
      setLocalEndDate(updatedEndDate);
    }
  };

  const handleClickDelete: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onDateRangeDelete(id);
  };

  const datePickerCustomStyles = {
    width: '200px',
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <div className="flex column">
          <p className="input-label">From</p>
          <DatePicker
            value={null}
            onChange={(newVal) => handleStartDateChange(newVal)}
            format="DD/MM/YYYY"
            disablePast
            maxDate={dayjs(maxDate)}
            sx={datePickerCustomStyles}
            slots={{
              openPickerIcon: CalendarMonthOutlinedIcon,
            }}
            slotProps={{
              openPickerIcon: {
                style: {
                  color: 'black',
                },
              },
              layout: {
                sx: {
                  borderRadius: '8px',
                },
              },
              day: {
                sx: {
                  fontFamily: 'OpenSans',
                  '&:hover': {
                    fontWeight: '600',
                    color: '#9E007E',
                    border: '2px solid',
                    borderColor: '#9E007E',
                    backgroundColor: 'rgba(158,0, 26,0.05)',
                  },
                  '&.Mui-selected': {
                    color: 'white',
                    backgroundColor: '#9E007E !important',
                    '&:hover': {
                      backgroundColor: '#722351',
                      border: 'none',
                      fontWeight: '500',
                    },
                  },
                  '&:disabled': {
                    color: 'rgba(145, 145, 145, 1)',
                  },
                  '&.MuiPickersDay-today': {
                    border: 'none',
                    backgroundColor: '#E6E6E6',
                    '&:hover': {
                      fontWeight: '600',
                      color: '#9E007E',
                      border: '2px solid',
                      borderColor: '#9E007E',
                      backgroundColor: 'rgba(158,0, 26,0.05)',
                    },
                  },
                },
              },
            }}
          />
        </div>
        <Box sx={{ paddingTop: '20px' }}>
          <ArrowForwardOutlinedIcon />
        </Box>
        <div className="flex column">
          <p className="input-label">To</p>
          <DatePicker
            value={null}
            minDate={dayjs(dateRange.startDate)}
            onChange={(newVal) => handleEndDateChange(newVal)}
            format="DD/MM/YYYY"
            disablePast
            maxDate={dayjs(maxDate)}
            sx={datePickerCustomStyles}
            slots={{
              openPickerIcon: CalendarMonthOutlinedIcon,
              textField: (params) => (
                <TextField
                  {...params}
                  FormHelperTextProps={{
                    style: {
                      color: 'red',
                      position: 'absolute',
                      top: '60px',
                      fontSize: '14px',
                      fontFamily: 'OpenSans-SemiBold',
                      height: '48px',
                    },
                  }}
                />
              ),
            }}
            slotProps={{
              openPickerIcon: {
                style: {
                  color: 'black',
                },
              },
              textField: {
                error: diff < 0,
                helperText: diff < 0 ? 'Please select a date' : '',
              },
              layout: {
                sx: {
                  borderRadius: '8px',
                },
              },
              day: {
                sx: {
                  fontFamily: 'OpenSans',
                  '&:hover': {
                    fontWeight: '600',
                    color: '#9E007E',
                    border: '2px solid',
                    borderColor: '#9E007E',
                    backgroundColor: 'rgba(158,0, 26,0.05)',
                  },
                  '&.Mui-selected': {
                    color: 'white',
                    backgroundColor: '#9E007E !important',
                    '&:hover': {
                      backgroundColor: '#722351',
                      border: 'none',
                      fontWeight: '500',
                    },
                  },
                  '&:disabled': {
                    color: 'rgba(145, 145, 145, 1)',
                  },
                  '&.MuiPickersDay-today': {
                    border: 'none',
                    backgroundColor: '#E6E6E6',
                    '&:hover': {
                      fontWeight: '600',
                      color: '#9E007E',
                      border: '2px solid',
                      borderColor: '#9E007E',
                      backgroundColor: 'rgba(158,0, 26,0.05)',
                    },
                  },
                },
              },
            }}
          />
        </div>
        <Box sx={{ paddingTop: '20px' }}>
          <p>{firstEndDateSelect ? 0 : diff ? diff : 0} days</p>
        </Box>
        {enableDelete && (
          <div style={{ paddingTop: '25px' }}>
            <button
              type="button"
              className="icon-link-color"
              onClick={(event) => handleClickDelete(event)}
            >
              <DeleteOutlinedIcon />
            </button>
          </div>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangePicker;
