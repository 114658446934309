import { FC, ReactNode } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button } from '@mui/material';

interface DynamicButtonProps {
  size: 'large' | 'medium' | 'small';
  type: 'primary' | 'secondary' | 'tertiary' | 'action' | 'quaternary' | 'add-file';
  onClick?: () => void;
  children: ReactNode;
  addIcon?: boolean;
  nextIcon?: boolean;
  upIcon?: boolean;
  disable?: boolean;
  longPadding?: boolean;
  fullWidth?: boolean;
}

const DynamicButton: FC<DynamicButtonProps> = ({
  size,
  type,
  onClick,
  children,
  addIcon = false,
  nextIcon = false,
  upIcon = false,
  disable = false,
  longPadding = false,
  fullWidth = false,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      sx={{ textTransform: 'none' }}
      disabled={disable}
      className={`dynamic-button ${size} ${type} ${disable ? 'disable' : ''} ${
        longPadding ? 'long-padding' : ''
      } ${fullWidth ? 'fullWidth' : ''}`}
      onClick={handleClick}
      component="label"
    >
      {(addIcon || nextIcon || upIcon) && <AddRoundedIcon />}
      {children}
    </Button>
  );
};

export default DynamicButton;
