import { useQuery } from "@tanstack/react-query";
import { tutoringApiUrl } from "../utils/Network";
import { Language } from "../utils/types";


const useFetchLanguages = (token: string) => useQuery<Language[]>({
    queryKey: ['languages'],
    queryFn: () =>
      fetch(`${tutoringApiUrl()}/_internal/getLanguages`,
      // fetch(`http://localhost:8080/_internal/getLanguages`, 
      {method: 'Get', 
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }}).then((res) =>
        res.json(),
      ),
      staleTime: Infinity,
      gcTime: 3600 + 300,
  });

export default useFetchLanguages;