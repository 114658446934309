/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DynamicButton from "../common/DynamicButton";
import LanguageIcon from "@mui/icons-material/Language";
import LanguageSelect from "../form/LanguageSelect";
import FileUploadProgress from "../form/FileUploadProgress";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ReportOffOutlinedIcon from "@mui/icons-material/ReportOffOutlined";
import ReportSharpIcon from "@mui/icons-material/ReportSharp";
import "react-week-calendar/dist/style.css";
import { Box } from "@mui/material";
import BackButton from "../layout/BackButton";
import InfoBadge from "../common/InfoBadge";
import { useTopicContext } from "../../providers/TopicContext";
import { Language, LanguageData, contentSensitivity } from "../../utils/types";
import { getFileType, gseRangeToCEFR } from "../../utils/utilsts";
import { getLangData } from "../../utils/Network";
import { useUserContext } from "../../components/Providers/UserContext";

const TopicView = () => {
  const { topic, setTopic } = useTopicContext();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState<Language>({
    code: "en_us",
    language: "English",
    id: 1,
  });

  const [languageData, setLanguageData] = useState<LanguageData[]>(
    topic?.languageData || []
  );

  const [CEFRLevels, setCEFRLevels] = useState<string[]>([]);

  useEffect(() => {
    // get correlating CEFR levels:
    if (topic?.GSELevel.minGSE && topic?.GSELevel.maxGSE) {
      setCEFRLevels(
        gseRangeToCEFR(topic?.GSELevel.minGSE, topic?.GSELevel.maxGSE)
      );
    }
  }, [topic?.GSELevel]);

  const { userToken } = useUserContext();

  const topicSupportedLanguages = topic?.languageData.map(
    (cLang) => cLang.language
  );

  const handleBackButtonClick = () => {
    setTopic(null);
  };

  // useEffect(() => {
  //   console.log("topic: ", topic);
  // }, [topic]);

  const handleLanguageChange = async (language: Language) => {
    setSelectedLanguage(language);
    const currentMondlyTopic = topic?.languageData.find(
      (lData) => lData.language === language.code
    )?.mondlyTopic;
    if (
      topic &&
      topic.id &&
      currentMondlyTopic?.mondlyCategory &&
      !languageData.some((lData) => lData.language === language.code)
    ) {
      const langData = await getLangData(
        topic.id,
        language.code,
        currentMondlyTopic.mondlyCategory,
        userToken
      );

      if (langData) {
        languageData.push({
          title: langData?.title || "",
          description: langData?.description || "",
          mondlyTopic: langData?.mondlyTopic,
          language: langData?.lang || language.code,
        });
        setLanguageData([...languageData]);
      }
    }
  };

  return (
    <section className="topic-view-section">
      <div className="topic-view-container">
        <BackButton navigatePath={"/topics"} onClick={handleBackButtonClick} />
        <div className="actions-bar flex space-between align-center">
          <div className="topic-edit-translation-container">
            <div className="topic-edit-translation-title">
              <LanguageIcon />
              <div className="topic-edit-titles">Translation language</div>
            </div>
            <LanguageSelect
              onLanguageChange={(lang) => handleLanguageChange(lang)}
              supporedLanguages={topicSupportedLanguages}
              language={selectedLanguage}
              langView
            />
          </div>
          <div className="flex row gap-8">
            <DynamicButton
              type="primary"
              size="medium"
              longPadding
              onClick={() => {
                navigate("/topicadd", { state: { selectedLanguage } });
              }}
            >
              <EditOutlinedIcon />
              Edit Topic
            </DynamicButton>
            <DynamicButton
              size="medium"
              disable={true}
              type="tertiary"
              longPadding
            >
              <DeleteOutlinedIcon />
              Delete
            </DynamicButton>
          </div>
        </div>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          <div className="general-title">
            <div className="general-details">
              <div className="details-list flex column gap-16">
                <div className="flex">
                  <div className="info-title">Content sensitivity</div>
                  <div className="detail-content">
                    {/* <InfoBadge small>
                      {topic?.contentSensitivity
                        ? "Adapted for regional sesitivity"
                        : "Standard"}
                    </InfoBadge> */}
                    <div className="topic-sensitivity">
                      {topic?.contentSensitivity ? (
                        <ReportSharpIcon />
                      ) : (
                        <ReportOffOutlinedIcon />
                      )}

                      {topic?.contentSensitivity
                        ? contentSensitivity.Adapted
                        : contentSensitivity.standard}
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="info-title">Title</div>
                  <div className="detail-content">
                    {
                      languageData?.find(
                        (lData) => lData.language === selectedLanguage.code
                      )?.title
                    }
                  </div>
                </div>
                <div className="flex">
                  <div className="info-title">Description</div>
                  <div className="detail-content">
                    {
                      languageData?.find(
                        (lData) => lData.language === selectedLanguage.code
                      )?.description
                    }
                  </div>
                </div>
                <div className="flex">
                  <div className="info-title">Category</div>
                  <div className="detail-content">
                    <InfoBadge small briefcaseIcon>
                      {topic?.category.category}
                    </InfoBadge>
                  </div>
                </div>
                <div className="flex">
                  <div className="info-title">CEFR/GSE level</div>
                  <div className="detail-content">
                    {CEFRLevels && CEFRLevels.length > 1 ? (
                      <span>
                        {CEFRLevels[0]}-{CEFRLevels[CEFRLevels.length - 1]}{" "}
                      </span>
                    ) : (
                      <span>{CEFRLevels[0]} </span>
                    )}
                    ({topic?.GSELevel.minGSE} - {topic?.GSELevel.maxGSE})
                  </div>
                </div>

                <div className="flex">
                  <div className="info-title">Mondly topic</div>
                  <div className="detail-content">
                    {/* {
                      languageData?.find(
                        (lData) => lData.language === selectedLanguage.code
                      )?.mondlyTopic?.name
                    } */}
                    {languageData?.find(
                      (lData) => lData.language === selectedLanguage.code
                    )?.mondlyTopic?.mondlyTopic || ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <div className="topic-files flex column gap-8">
          {topic?.classMaterial &&
            topic.classMaterial.length > 0 &&
            topic.classMaterial.map((file, index) => (
              <FileUploadProgress
                key={`${index}-${file.file}`}
                fileType={getFileType(file.file)}
                uploadProgress={100}
                fileName={file.file}
                isTutorFacing={file.upload}
                isViewMode
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default TopicView;
