import { useMutation, useQuery } from '@tanstack/react-query';
import { tutoringApiUrl } from '../utils/Network';
import { Tutor } from '../utils/types';
// import { TutorData } from "@components/Tutor/TutorAddEdit";

export const useFetchTutors = (token: string) =>
  useQuery<Tutor[]>({
    queryKey: ['tutors'],
    queryFn: () =>
      fetch(
        `${tutoringApiUrl()}/_internal/tutors/all`,
        //   fetch('http://localhost:8080/_internal/tutors/all',
        {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      ).then((res) => res.json()),
    staleTime: 3600,
    gcTime: 3600 + 300,
  });

// export const updateTutor = (tutorData: TutorData, userToken: string) =>
//     // const queryClient = useQueryClient()
//      useMutation({
//         mutationKey: ['tutors'],
//         mutationFn: (tutorData: TutorData) => {
//             return fetch('http://localhost:8080/_internal/tutors/updateTutor',
//             // return fetch(`${tutoringApiUrl()}/_internal/tutors/updateTutor`,
//                 {
//                 method: 'POST',
//                 body: JSON.stringify({tutorData}),
//                 headers: {
//                     "content-type": "application/json",
//                     Authorization: `Bearer ${userToken}`,
//                 }
//             })
//         },
//         // onSuccess: (data) => {
//         //     queryClient.setQueryData(['tutors', {id: tutorData.id}], data)
//         // },
//         retry: 3,
//         retryDelay: 1000,
//     });

export const DeleteTutor = (tutorId: number | undefined, userToken: string) =>
  useMutation({
    mutationKey: ['tutors'],
    mutationFn: (tutorId: number | undefined) => {
      // return fetch(`http://localhost:8080/_internal/tutors/deleteTutor/${tutorId}`,
      return fetch(`${tutoringApiUrl()}/_internal/tutors/deleteTutor/${tutorId}`, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });
    },
    retry: 3,
    retryDelay: 1000,
  });
