import { FC, ReactNode } from 'react';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import SpellcheckOutlinedIcon from '@mui/icons-material/SpellcheckOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';

interface InfoBadgeProps {
  children: ReactNode;
  addIcon?: boolean;
  nextIcon?: boolean;
  upIcon?: boolean;
  reportIcon?: boolean;
  reportOffIcon?: boolean;
  small?: boolean;
  btnGroup?: boolean;
  globeIcon?: boolean;
  grammarIcon?: boolean;
  briefcaseIcon?: boolean;
  pinDropIcon?: boolean;
  isDisable?: boolean;
  disableHeight?: boolean;
  isHide?: boolean;
  thinInlinePadding?: boolean;
}

const InfoBadge: FC<InfoBadgeProps> = ({
  children,
  small = false,
  isDisable = false,
  globeIcon = false,
  grammarIcon = false,
  briefcaseIcon = false,
  pinDropIcon = false,
  disableHeight = false,
  isHide = false,
  thinInlinePadding = false,
}) => {
  return (
    <section
      className={`info-badge ${small ? 'small' : ''} ${isDisable ? 'disable' : ''} ${
        disableHeight ? 'disable-height' : ''
      } ${isHide ? 'hide' : ''} ${thinInlinePadding ? 'padding-inline-4' : ''}`}
    >
      {globeIcon && <PublicOutlinedIcon />}
      {grammarIcon && <SpellcheckOutlinedIcon />}
      {briefcaseIcon && <WorkOutlineOutlinedIcon />}
      {pinDropIcon && <PinDropOutlinedIcon />}
      {children}
    </section>
  );
};

export default InfoBadge;
