import { useQuery } from "@tanstack/react-query";
import { getTopicList } from "../utils/Network";
import { Topic } from "@utils/types";
import { TIME } from "../utils/constants";

const STALE_TIME = TIME.day * 5;
const CACHE_TIME = TIME.day + 5 * TIME.second;

const useFetchTopics = (userToken: string) =>
  useQuery<Topic[]>({
    queryKey: ["topics"],
    queryFn: () => getTopicList(userToken).then((topicList) => {
      return topicList.sort((a: Topic, b: Topic) => {
        // Access the first title in languageData array of each topic
        const titleA = (a.languageData?.find(cLang => cLang.language === 'en_us')?.title || "").toLowerCase();
        const titleB = (b.languageData?.find(cLang => cLang.language === 'en_us')?.title || "").toLowerCase();
  
        // Use localeCompare for case-insensitive alphabetical sorting
        return titleA.localeCompare(titleB);
      })
      .filter((a: Topic[]) => a);
    }),
    staleTime: STALE_TIME,
    gcTime: CACHE_TIME,
    enabled: !!userToken,
    refetchOnWindowFocus: false,
  });

export default useFetchTopics;
