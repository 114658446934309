import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import Select, { SelectChangeEvent } from '@mui/material/Select';

interface CustomSelectProps {
  onChange: (name: string, value: string) => void;
  value?: string;
  selectOptions: string[];
  label?: string;
  name: string;
  error?: boolean;
}

const CustomSelect = ({
  onChange,
  value = '',
  label,
  name,
  selectOptions = [],
  error = false,
}: CustomSelectProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    onChange(name, value);
  };

  const menuItemSx = {
    '&.Mui-selected': {
      backgroundColor: 'rgba(158, 0, 126, 0.05)',
      '&:hover': {
        backgroundColor: 'rgba(158, 0, 126, 0.05)',
      },
    },
  };

  return (
    <section className="textfield-custom">
      <Box sx={{ minWidth: 120, margin: '16px 0 8px' }}>
        <FormControl fullWidth>
          {label && <p className="textfield-label">{label}</p>}
          <Select value={value} name={name} onChange={handleChange} error={!!error}>
            {selectOptions.map((option) => (
              <MenuItem key={option} value={option} sx={menuItemSx}>
                {option}
              </MenuItem>
            ))}
          </Select>
          {error && (
            <FormHelperText
              className="required-error-helper-txt"
              style={{ color: 'red', fontFamily: 'OpenSans-SemiBold' }}
            >
              Required
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </section>
  );
};

export default CustomSelect;
