import { Box, ButtonGroup } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import PinkBtn from '../common/PinkBtn';

interface SingleSelectBtnsProps {
  onChange: (name: string, value: string) => void;
  value?: string | null;
  selectOptions: string[];
  selectIcons?: string[];
  label?: string;
  name: string;
  optional?: boolean;
  groupOptions?: string[];
  noMargin?: boolean;
  large?: boolean;
}

const SingleSelectBtns = ({
  onChange,
  value = '',
  label,
  name,
  optional = false,
  selectIcons = [],
  selectOptions = [],
  groupOptions = [],
  noMargin = false,
  large = false,
}: SingleSelectBtnsProps) => {
  const handleChange = (option: string) => {
    onChange(name, option);
  };

  const checkDisable = (option: string) => {
    if (groupOptions && groupOptions.length) {
      return groupOptions.includes(option) ? false : true;
    }
    return false;
  };

  return (
    <section className={`single-select-btns ${noMargin ? 'no-margin' : ''}`}>
      <Box sx={{ minWidth: 120, margin: noMargin ? '0' : '16px 0 8px' }}>
        <FormControl fullWidth>
          <div className="flex gap-5">
            {label && <p className="input-label">{label}</p>}
            {label && optional && <p className="input-label-optional">(optional)</p>}
          </div>
          <div className="single-choice-btns-list">
            <ButtonGroup size={`${large ? 'large' : 'small'}`}>
              {selectOptions.map((option, index) => (
                <PinkBtn
                  key={index}
                  disable={checkDisable(option)}
                  btnGroup={!large}
                  reportIcon={selectIcons[index] === 'reportIcon'}
                  reportOffIcon={selectIcons[index] === 'reportOffIcon'}
                  globeIcon={selectOptions[index] === 'General English'}
                  grammarIcon={selectOptions[index] === 'Grammar'}
                  briefcaseIcon={selectOptions[index] === 'Workplace English'}
                  pinDropIcon={selectOptions[index] === 'Specific purpose'}
                  isSelected={option === value}
                  onClick={() => handleChange(option)}
                >
                  {option}
                </PinkBtn>
              ))}{' '}
            </ButtonGroup>
          </div>
        </FormControl>
      </Box>
    </section>
  );
};

export default SingleSelectBtns;
