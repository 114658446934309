import { useQueryClient, useMutation } from '@tanstack/react-query';
import { updateGroupGSELevels } from '../utils/Network';
import { GSELevel } from '@utils/types';

type UpdateGroupGSELevelsRequest = {
  groupId: number;
  rootId: number;
  levels: GSELevel[];
  userToken: string;
};

const _updateGroupGSELevels = async (data: UpdateGroupGSELevelsRequest) => {
  return updateGroupGSELevels(data.groupId, data.rootId, data.levels, data.userToken);
};

const useUpdateGroupGSELevels = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: _updateGroupGSELevels,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ['privateGroups'],
      });
    },
  });
};

export default useUpdateGroupGSELevels;
