import { useQueryClient, useMutation } from '@tanstack/react-query';
import { updateGroupLocations } from '../utils/Network';
import { Location } from '@utils/types';

type UpdateGroupLocationsRequest = {
  groupId: number;
  rootId: number;
  locations: Location[];
  userToken: string;
};

const _updateGroupLocations = async (data: UpdateGroupLocationsRequest) => {
  return updateGroupLocations(data.groupId, data.rootId, data.locations, data.userToken);
};

const useUpdateGroupLocations = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: _updateGroupLocations,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ['privateGroups'],
      });
    },
  });
};

export default useUpdateGroupLocations;
