import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Extend Day.js with necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

// Create a context to hold the selected timezone
interface TimeZoneContextType {
  timeZone: string;
  setTimeZone: (timeZone: string) => void;
  timezoneOffset: number;
  privateGroupScheduleDate: Date | Dayjs;
  setPrivateGroupScheduleDate: (date: Date | Dayjs) => void;
}

type TimeZoneProviderProps = {
  children: ReactNode;
};

const TimeZoneContext = createContext<TimeZoneContextType | undefined>(undefined);

// Custom hook to access the selected timezone
export const useTimeZoneContext = () => {
  const context = useContext(TimeZoneContext);
  if (!context) {
    throw new Error('useTimeZone must be used within a TimeZoneProvider');
  }
  return context;
};

// TimeZoneProvider component to manage the selected timezone
export const TimeZoneProvider: React.FC<TimeZoneProviderProps> = ({ children }) => {
  const [timeZone, setTimeZone] = useState<string>('EST'); // Set the default timezone as needed
  const [timezoneOffset, setTimezoneOffset] = useState<number>(0); // Set the default timezone as needed
  

  const initiateGroupDate = () => {
    try {
      return dayjs(new Date().setHours(12, 0, 0, 0)).tz(timeZone)
    } catch (e) {
      setTimeZone('EST')
      setTimezoneOffset(0);
      return dayjs(new Date().setHours(12, 0, 0, 0)).tz('EST')
    }
  }
  
  const [privateGroupScheduleDate, setPrivateGroupScheduleDate] = useState<Date | Dayjs>(initiateGroupDate);
  const getOffset = (timeZone: string) => {
    try {
      const offset = dayjs.utc(new Date()).tz(timeZone).utcOffset() / 60;
      setTimezoneOffset(offset);
    } catch (error) {
      // console.error('Error occurred while getting offset:', error);
      // return initial values:
      setTimezoneOffset(0);
      setTimeZone('EST');
    }
  };

  useEffect(() => {
    if (timeZone) {
      getOffset(timeZone);
    } else {
      console.log('Error using this timezone: ', timeZone);
    }
  }, [timeZone]);

  return (
    <TimeZoneContext.Provider
      value={{
        timeZone,
        setTimeZone,
        timezoneOffset,
        privateGroupScheduleDate,
        setPrivateGroupScheduleDate,
      }}
    >
      {children}
    </TimeZoneContext.Provider>
  );
};
