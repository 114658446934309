import { useRef } from "react";
import { useNavigate } from "react-router-dom";
// import TutorAddEdit from "../components/Tutor/TutorAddEdit";
// import { deleteTutor, saveTutor, getTutors } from "../../Shared/Network";
import { Tutor } from "../utils/types";
import Avatar from "../components/common/Avatar";
import DynamicButton from "../components/common/DynamicButton";
import { useUserContext } from "../components/Providers/UserContext";
import { useTutorContext } from "../providers/TutorContext";
import Loader from "../components/common/Loader";

interface TutorsProps {
  tutorList: Tutor[];
  isLoading: boolean;
}

const Tutors = ({ tutorList, isLoading }: TutorsProps) => {
  const tutorsData = useRef<Tutor[]>(tutorList);
  // const [tutor, setTutor] = useState<Tutor>();
  const { hasRole } = useUserContext();
  const navigate = useNavigate();
  const { setTutor } = useTutorContext();
  // const vendorList = [...new Set(tutorList.map((tutor) => tutor.vendor))];
  // useEffect(() => {
  //   const tutorsExArr = [];
  //   tutorsExArr.push(exampleTutor1, exampleTutor2);
  //   setTutorsData(tutorsExArr);
  // }, []);

  // const addEditTutor = () => {
  //   setEditTutor(true);
  // };

  // const deteleTutorRes = (res) => {
  //   console.log("deteleTutorRes");
  //   console.log(res);

  //   let index = tutorsData.findIndex((e) => e.id === res.tutorDelete.id);
  //   tutorsData.splice(index, 1);

  //   setTutorsData([...tutorsData]);
  //   setTutor(null);
  //   setEditTutor(false);
  // };

  // const sendDeleteTutor = (tutorId) => {
  //   deleteTutor(tutorId, userToken, deteleTutorRes);
  // };

  // const saveTutorRes = (res) => {
  //   console.log(res);
  //   if (
  //     !res.tutor ||
  //     (typeof res.tutor === "string" && res.tutor?.includes("fail"))
  //   ) {
  //     if (!window.alert("This Email already exists!")) {
  //       return;
  //     }
  //   }
  //   let index = tutorsData.findIndex((e) => e.id === res.tutor.id);
  //   let a = [...tutorsData];

  //   if (index >= 0) {
  //     a[index] = res.tutor;
  //   } else {
  //     a.push(res.tutor);
  //   }

  //   setTutorsData(a);
  //   setTutor(null);
  //   setEditTutor(false);
  // };

  // const sendSaveTutor = (data) => {
  //   saveTutor(tutor?.id, data, userToken, saveTutorRes);
  // };

  return (
    <section
      className="tutor-page-container"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div className="tutor-page-content">
        {hasRole("Admin") && (
          <div className="add-tutor-btn">
            <DynamicButton
              size="medium"
              type="primary"
              addIcon={true}
              onClick={() => navigate("/tutoradd")}
            >
              New tutor
            </DynamicButton>
          </div>
        )}

        {tutorsData.current?.length && (
          <div className="tutor-list">
            {tutorsData.current.map((tutor) => (
              <div
                key={tutor?.id}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: 12,
                  cursor: "pointer",
                  marginBottom: 10,
                  display: "flex",
                  padding: 20,
                  gap: 10,
                }}
                onClick={() => {
                  setTutor(tutor);
                  navigate("/tutorview");
                }}
              >
                <div>
                  <Avatar width={64} thumb={tutor.thumb} />
                </div>
                <div>
                  <div style={{ fontWeight: 600 }}>
                    {tutor.firstName + " " + tutor.lastName}
                  </div>
                  <div style={{ color: "#666" }}> {tutor.email}</div>

                  <div
                    style={{
                      display: "flex",
                      color: "#666",
                      fontSize: 12,
                      marginTop: 10,
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        )}

        {!tutorList && isLoading && <Loader />}
      </div>
    </section>
  );
};

export default Tutors;
