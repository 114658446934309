import { useQueryClient, useMutation } from '@tanstack/react-query';
import { addOrgExclusionDates } from '../utils/Network';
import { ExclusionInterval } from '@utils/types';

type UpdateOrgExclusionDates = {
  rootId: number;
  exclusionDates: ExclusionInterval[];
  userToken: string;
};

const _updateOrgExclusionDates = async (data: UpdateOrgExclusionDates) => {
  return addOrgExclusionDates(data.rootId, data.exclusionDates, data.userToken);
};

const useUpdateOrgExclusionDates = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: _updateOrgExclusionDates,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ['privateGroups'],
      });
    },
  });
};

export default useUpdateOrgExclusionDates;
