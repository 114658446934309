import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";

interface GseSliderProps {
  handleGSEChange: (name: string, value: number[]) => void;
  value?: number[];
  name: string;
}

const marks = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 22,
    label: "22",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 36,
    label: "36",
  },
  {
    value: 43,
    label: "43",
  },
  {
    value: 51,
    label: "51",
  },
  {
    value: 59,
    label: "59",
  },
  {
    value: 67,
    label: "67",
  },
  {
    value: 76,
    label: "76",
  },
  {
    value: 85,
    label: "85",
  },
  {
    value: 90,
    label: "90",
  },
];

const GseSlider = ({
  handleGSEChange,
  value = [10, 90],
  name,
}: GseSliderProps) => {
  const [currVal, setCurrVal] = useState<number[]>(value);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setCurrVal(newValue as number[]);
  };

  useEffect(() => {
    handleGSEChange(name, currVal);
  }, [currVal]);

  const sliderStyles = {
    "& .MuiSlider-root": {
      color: "#C9C9C9",
      height: 3,
      padding: "13px 0",
    },
    "& .MuiSlider-thumb": {
      height: 20,
      width: 20,
      color: "#C9C9C9",
      // marginTop: -9,
      // marginLeft: -9,
      border: "solid 3.91px #9E007E",
      "&:hover": {
        boxShadow: "0px 0px 0px 8px rgba(158, 0, 126, 0.16)",
      },
    },
    active: {},
    "& .MuiSlider-rail": {
      height: 3,
      borderRadius: 0,
      backgroundColor: "#C9C9C9",
    },
    "& .MuiSlider-track": {
      height: 5,
      borderRadius: 0,
      color: "#9E007E",
    },
    "& .MuiSlider-mark": {
      backgroundColor: "#C9C9C9",
      height: 9,
      width: 9,
      borderRadius: "50%",
    },
    "& .MuiSlider-markLabel": {
      color: "#151515",

      transform: "translateX(-48%)",
    },
    "& .MuiSlider-mark.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "#9E007E",
    },
    "& .MuiSlider-valueLabel": {
      background: "none",
      color: "#9E007E",
      transform: "translateY(100%) scale(1)",
    },
    "& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen": {
      background: "none",
      color: "#9E007E",
      transform: "translateY(100%) scale(1)",
    },
  };

  return (
    <section className="gse-slider-custom flex column">
      <p className="form-label">CEFR/GSE level</p>
      <div className="cefr-levels">
        <p className="flex-2">&lt;A1</p>
        <p className="flex-15">A1</p>
        <p className="flex-1">A2</p>
        <p className="flex-1">A2+</p>
        <p className="flex-15">B1</p>
        <p className="flex-1">B1+</p>
        <p className="flex-15">B2</p>
        <p className="flex-15">B2+</p>
        <p className="flex-15">C1</p>
        <p className="flex-1">C2</p>
      </div>
      <Stack sx={{ height: 50 }} spacing={1} direction="row">
        <Slider
          // getAriaLabel={() => "GSE level"}
          // getAriaValueText={valuetext}
          value={value}
          valueLabelDisplay="on"
          marks={marks}
          onChange={handleChange}
          min={10}
          max={90}
          sx={sliderStyles}
        />
      </Stack>
    </section>
  );
};

export default GseSlider;
