import { Topic } from '../../utils/types';
import { Autocomplete, TextField } from '@mui/material';

interface TopicSelectProps {
  topicList: Topic[];
  onTopicChange: (topic: Topic) => void;
  language: string;
  error?: boolean;
  value: Topic | null | undefined;
}

const TopicSelect = ({ topicList, onTopicChange, language, value }: TopicSelectProps) => {
  // const topicListSorted = topicList
  //   .sort((a, b) => {
  //     // Access the first title in languageData array of each topic
  //     const titleA = (a.languageData[0]?.title || "").toLowerCase();
  //     const titleB = (b.languageData[0]?.title || "").toLowerCase();

  //     // Use localeCompare for case-insensitive alphabetical sorting
  //     return titleA.localeCompare(titleB);
  //   })
  //   .filter((a) => a);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: Topic | null | undefined,
  ) => {
    if (value) {
      onTopicChange(value);
    }
  };

  const isOptionEqualToValue = (option: Topic, value: Topic) => option.id === value.id;

  return (
    <section className="textfield-custom">
      <p className="textfield-label">Topic</p>
      <Autocomplete
        value={value}
        onChange={(event, value) => handleChange(event, value)}
        options={topicList}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={(option) =>
          `${option?.languageData.find((t) => t.language === language)?.title} (${
            option?.GSELevel.minGSE
          }-${option?.GSELevel.maxGSE})` || ''
        }
        getOptionKey={(option) =>
          option.languageData.find((t) => t.language === language)?.id || -1
        }
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </section>
  );
};

export default TopicSelect;
