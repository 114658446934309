import React, { useEffect, useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
// import dayjs from 'dayjs';
import { Autocomplete, FormControl, Box, TextField } from '@mui/material';

import * as moment from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data';
import { TimeZone } from '../../utils/types';

interface SessionTableTimezonePickerProps {
  tableTimezone: string;
  handleTimeZoneChange: (selectedTimezone: string) => void;
}

export const SessionTableTimezonePicker = ({
  tableTimezone,
  handleTimeZoneChange,
}: SessionTableTimezonePickerProps) => {
  //
  const allTimeZones: string[] = moment.tz.names();

  const allTimezonesFormatted: TimeZone[] = allTimeZones.map((timezone) => {
    return {
      label: `(UTC${moment.tz(timezone).format('Z')}) ${timezone.replace(/_/g, ' ')}`, // Replace underscores with spaces
      offset: moment.tz(timezone).format('Z'),
    };
  });

  const getTimeZoneName = (timezoneLabel: string): string | null => {
    const match = timezoneLabel.match(/\(UTC[+-]\d{2}:\d{2}\)\s*(.+)/);
    return match ? match[1] : null;
  };

  const [selectedTimezone, setSelectedTimezone] = useState<TimeZone | null>(null);
  const [timezones, setTimezones] = useState<TimeZone[] | null>([]);

  // initiate timezones:
  useEffect(() => {
    setTimezones(allTimezonesFormatted);
  }, []);

  // set timezone prop to selectedTimezone(TimeZone type):
  useEffect(() => {
    const updatedTimezone = allTimezonesFormatted.find((tz) => tz.label.includes(tableTimezone));
    if (updatedTimezone) {
      setSelectedTimezone({
        label: updatedTimezone.label,
        offset: updatedTimezone.offset,
      });
    }
  }, [tableTimezone]);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: TimeZone | null) => {
    if (value) {
      setSelectedTimezone(value);
      const timezoneName = getTimeZoneName(value.label);
      if (timezoneName) {
        handleTimeZoneChange(timezoneName);
      }
    }
  };

  const customSx = {
    color: '#007A9C',
    width: '350px',
    border: 'none',
    '& fieldset': { border: 'none' },
    '& Button': { color: '#007A9C' },
    '& input': { border: 'none', color: '#007A9C', fontWeight: '600' },
  };

  return (
    <section className="timezone-picker">
      <Box sx={customSx}>
        <FormControl fullWidth sx={customSx}>
          {timezones && selectedTimezone && (
            <Autocomplete
              sx={customSx}
              value={timezones.find((tz) => tz.label === selectedTimezone.label)}
              onChange={(event, value) => handleChange(event, value)}
              options={timezones}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} fullWidth sx={customSx} />}
              popupIcon={<KeyboardArrowDownOutlinedIcon />}
              disableClearable
            />
          )}
        </FormControl>
      </Box>
    </section>
  );
};
