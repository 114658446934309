// requirements:
// Ideal Bulk Schedule Dashboard/Report:
// the start and stop date of the created schedule -DONE
// the total # of classes created during the schedule -DONE
// the total # of days with scheduled classes during the schedule
// the frequency of the GSE bands of the schedule, e.g. how many classes created from each band -DONE
// the maximum # of classes created on a single day within the schedule
// the minimum # of classes created on a single day within the schedule
// the maximum # of classes created in single week within the schedule
// the minimum # of classes created in a single week within the schedule

// QA requirements:
// exclusion dates
// number of classes per week matches minimun required classes.

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Children, Session, License } from '../../utils/types';
import dayjs from 'dayjs';
import DynamicButton from '../common/DynamicButton';
import isoWeek from 'dayjs/plugin/isoWeek';
// import { useTimeZoneContext } from '../../providers/TimeZoneProvider';

interface SessionsResponseSummaryProps {
  sessions: Session[];
  group: Children | null;
  orgData: Children | null;
}

export const SessionsResponseSummary = ({
  sessions = [],
  group,
  orgData,
}: SessionsResponseSummaryProps) => {
  dayjs.extend(isoWeek);

  const [currLicense, setCurrLicense] = useState<License | null>(null);

  const getSessionTimeFormatted = (session: Session) => {
    try {
      if (session.date && group) {
        let formattedDate;
        if (group.timezone) {
          if (group.timezone.startsWith('UTC')) {
            if (group.timezone === 'UTC') {
              formattedDate = dayjs(new Date(session.date)).utcOffset(0).format('HH:mm');
            } else {
              formattedDate = dayjs(new Date(session.date))
                .utcOffset(group.timezone.replace('UTC', ''))
                .format('HH:mm');
            }
          } else {
            formattedDate = dayjs(new Date(session.date)).tz(group.timezone).format('HH:mm');
          }
        } else {
          formattedDate = dayjs(new Date(session.date)).tz('EST').format('HH:mm');
        }
        return formattedDate;
      }
    } catch (error) {
      if (session.date) {
        return `${dayjs(new Date(session.date)).tz('EST').format('HH:mm')} EST time`;
      }
    }
  };

  const downloadTextAsFile = (blob: Blob, filename: string) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;

    // Append the anchor element to the body
    document.body.appendChild(a);

    // Programmatically trigger a click event on the anchor element
    a.click();

    // Remove the temporary anchor element from the document
    document.body.removeChild(a);
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    { field: 'id', headerName: 'Session Id', width: 90, type: 'number' },
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
    },
    {
      field: 'weekday',
      headerName: 'Weekday',
      width: 100,
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 100,
    },
    {
      field: 'category',
      headerName: 'Categoty',
      width: 150,
    },
    {
      field: 'GSELevel',
      headerName: 'GSE Level',
      width: 150,
    },
    {
      field: 'topic',
      headerName: 'Topic',
      width: 200,
    },
    {
      field: 'contentSensitivity',
      headerName: 'Content sensitivity',
      width: 100,
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      width: 100,
    },
  ];

  const getDate = (session: Session) => {
    if (group && group.timezone && session.date) {
      if (group.timezone.startsWith('UTC')) {
        if (group.timezone === 'UTC') {
          return dayjs(new Date(session.date)).utcOffset(0).format('YYYY-MM-DD');
        } else {
          return dayjs(new Date(session.date))
            .utcOffset(group.timezone.replace('UTC', ''))
            .format('YYYY-MM-DD');
        }
      } else {
        return dayjs(new Date(session.date)).tz(group.timezone).format('YYYY-MM-DD');
      }
    }
  };

  const getWeekday = (session: Session) => {
    if (group && group.timezone && session.date) {
      if (group.timezone.startsWith('UTC')) {
        return dayjs(new Date(session.date))
          .utcOffset(group.timezone.replace('UTC', ''))
          .format('ddd');
      } else {
        return dayjs(new Date(session.date)).tz(group.timezone).format('ddd');
      }
    }
  };

  const rows = sessions.map((session) => ({
    id: session.id.toString().replace(/,/g, ''),
    date: getDate(session),
    weekday: getWeekday(session),
    time: getSessionTimeFormatted(session),
    category: session.category.category,
    GSELevel: session.GSELevel?.level,
    topic: session.topic?.title ? session.topic.title : 'Placeholder',
    contentSensitivity: session.topic?.contentSensitivity.toString(),
    vendor: session.vendor,
  }));

  type DataItem = {
    id: string;
    date: string;
    weekday: string;
    time?: string;
    category: string;
    GSELevel?: string;
    topic: string;
    contentSensitivity?: string;
    vendor: string;
  };

  const getSessionsResponseCsv = () => {
    // const rowsString = JSON.stringify(rows);
    // const blob = new Blob([rowsString], { type: 'application/json' });
    // Step 1: Extract the headers
    const headers = Object.keys(rows[0]) as Array<keyof DataItem>;

    // Step 2: Create the header row
    const headerRow = headers.join(',');

    // Step 3: Convert each object to a CSV row
    const csvRows: string[] = rows.map((item) => {
      return headers.map((header) => item[header] ?? '').join(',');
    });

    // Step 4: Combine the header row and the data rows
    const csvString = [headerRow, ...csvRows].join('\n');

    // Step 5: Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv' });
    downloadTextAsFile(blob, `multiple-sessions-response-${new Date().toLocaleString()}.csv`);
  };

  type LevelCount = {
    [key: string]: number;
  };

  const GSELevelsCount = sessions.reduce(
    (accumulator: LevelCount, session: Session): LevelCount => {
      const levelName = session.GSELevel?.level;
      if (levelName) {
        if (accumulator[levelName]) {
          accumulator[levelName]++;
        } else {
          accumulator[levelName] = 1;
        }
      }

      return accumulator;
    },
    {} as LevelCount,
  );

  const calcCurrLicense = () => {
    const today = dayjs();
    if (orgData) {
      const currLicenseFind = orgData?.licenses?.find(
        (license) =>
          dayjs(license.startDate).isBefore(today) && dayjs(license.endDate).isAfter(today),
      );
      if (currLicenseFind) {
        setCurrLicense(currLicenseFind);
      }
    }
  };

  const sessionsPerWeekCalculated = (group: Children) => {
    if (group.maxNumberOfStudents && group.classSize && group.weeklyLimit) {
      return Math.ceil((group.maxNumberOfStudents / group.classSize) * group.weeklyLimit);
    }
    return 'Missing group info';
  };

  const sessionsPerWeekCalculatedWithMargin = (group: Children, org: Children) => {
    if (group.maxNumberOfStudents && group.classSize && group.weeklyLimit && org) {
      const sessionsPerWeek = Math.ceil(
        (group.maxNumberOfStudents / group.classSize) * group.weeklyLimit,
      );
      return org.margin && sessionsPerWeek
        ? Math.ceil((sessionsPerWeek * org.margin) / 100)
        : sessionsPerWeek;
    }
    return 'Missing group or org info';
  };

  useEffect(() => {
    calcCurrLicense();
  }, [orgData]);

  // Group sessions by Mondays as start of week
  type SessionsByWeek = { [key: string]: number };

  // Utility function to apply the correct timezone or offset
  const applyTimezone = (date: string | Date | undefined, timezone: string) => {
    if (date && timezone.startsWith('UTC')) {
      return dayjs(date).utcOffset(timezone.replace('UTC', ''));
    } else {
      return dayjs(date).tz(timezone);
    }
  };

  // Group sessions by the Monday of their week with a specific timezone or offset
  const groupSessionsByWeek = (sessions: Session[], timezone: string): SessionsByWeek => {
    return sessions.reduce((acc, session) => {
      const weekStart = applyTimezone(session.date, timezone)
        .startOf('day') // Ensure it's the start of the day in the given timezone
        .startOf('isoWeek') // Start of the week in the given timezone
        .format('YYYY-MM-DD');

      acc[weekStart] = (acc[weekStart] || 0) + 1;
      return acc;
    }, {} as SessionsByWeek);
  };

  // Find weeks with fewer sessions than desired
  const findWeeksWithFewerSessions = (
    allMondays: string[],
    sessionsByWeek: SessionsByWeek,
    desiredCount: number,
  ): string[] => {
    return allMondays.filter((monday) => (sessionsByWeek[monday] || 0) < desiredCount);
  };

  const sessionsPerWeekCalculatedWithMarginNumber = (group: Children, org: Children) => {
    if (group.maxNumberOfStudents && group.classSize && group.weeklyLimit && org) {
      const sessionsPerWeek = Math.ceil(
        (group.maxNumberOfStudents / group.classSize) * group.weeklyLimit,
      );
      return org.margin && sessionsPerWeek
        ? Math.ceil((sessionsPerWeek * org.margin) / 100)
        : sessionsPerWeek;
    }
    return 0;
  };

  // Get the first and last session dates
  const firstSessionDate = dayjs(sessions[0].date).format('YYYY-MM-DD');
  const lastSessionDate = dayjs(sessions[sessions.length - 1].date).format('YYYY-MM-DD');

  // Function to generate all Mondays within a date range
  const generateMondaysInRange = (
    startDate: string,
    endDate: string,
    timezone: string,
  ): string[] => {
    const start = applyTimezone(startDate, timezone).startOf('isoWeek');
    const end = applyTimezone(endDate, timezone).endOf('isoWeek');
    const mondays: string[] = [];
    let current = start;

    while (current.isBefore(end) || current.isSame(end, 'day')) {
      mondays.push(current.format('YYYY-MM-DD'));
      current = current.add(1, 'week');
    }

    return mondays;
  };

  // Generate all Mondays within the date range
  const allMondays = generateMondaysInRange(
    firstSessionDate,
    lastSessionDate,
    group?.timezone || 'UTC',
  );

  const sessionsByWeek = groupSessionsByWeek(sessions, group?.timezone || 'UTC');

  const sessionPerWeeksByLicense =
    group && orgData ? sessionsPerWeekCalculatedWithMarginNumber(group, orgData) : 0;

  const weeksWithFewerSessions = findWeeksWithFewerSessions(
    allMondays,
    sessionsByWeek,
    sessionPerWeeksByLicense,
  );

  // const weeksWithMoreSessions = findWeeksWithMoreSessions(
  //   allMondays,
  //   sessionsByWeek,
  //   sessionPerWeeksByLicense,
  // );

  return (
    <section className={`sessions-response-summary-section`} style={{ padding: '20px' }}>
      <div className="sessions-response-summary-content">
        <div className="statistics flex gap-24">
          <div className="response-info full">
            <h3>Multiple sessions created info</h3>
            <div className="details-item flex">
              <div className="info-title">Start date</div>
              <div className="detail-content flex gap-8">
                {sessions && sessions.length > 0 && (
                  <p className="details">{`${dayjs(sessions[0].date).format('YYYY-MM-DD')}`}</p>
                )}
              </div>
            </div>
            <div className="details-item flex">
              <div className="info-title">Last session date</div>
              <div className="detail-content flex gap-8">
                {sessions && sessions.length > 0 && (
                  <p className="details">
                    {`${dayjs(sessions[sessions.length - 1].date).format('YYYY-MM-DD')}`}
                  </p>
                )}
              </div>
            </div>
            <div className="details-item flex">
              <div className="info-title">GSE Levels created:</div>
              <div className="detail-content flex gap-8">
                {sessions && (
                  <p style={{ whiteSpace: 'pre' }}>
                    {Object.keys(GSELevelsCount)
                      .map((key) => `${key}: ${GSELevelsCount[key]}`)
                      .join('\r\n')}
                  </p>
                )}
                {/* {sessions && <p className="details">{JSON.stringify(GSELevelsCount, null, 2)}</p>} */}
              </div>
            </div>
            <div className="details-item flex">
              <div className="info-title">Total # of sessions created</div>
              <div className="detail-content flex gap-8">
                {sessions && <p className="details">{sessions.length}</p>}
              </div>
            </div>
            <div className="details-item flex">
              <div className="info-title">Weeks with less sessions than required by license:</div>
              <div className="detail-content flex gap-8">
                {weeksWithFewerSessions && (
                  <p
                    className="details"
                    style={{
                      whiteSpace: 'pre',
                      width: '150px',
                      overflow: 'auto',
                      maxHeight: '200px',
                    }}
                  >
                    {weeksWithFewerSessions.join('\r\n')}
                  </p>
                )}
              </div>
            </div>
            <DynamicButton
              size="small"
              type="primary"
              longPadding
              onClick={() => getSessionsResponseCsv()}
            >
              <FileDownloadOutlinedIcon />
              Download as .csv
            </DynamicButton>
          </div>
          <div className="license-info full flex">
            <div>
              <h3>Group and License info</h3>
              <div className="details-item flex">
                <div className="info-title">Group name</div>
                <div className="detail-content flex gap-8">
                  <p className="details">{group?.name}</p>
                </div>
              </div>
              <div className="details-item flex">
                <div className="info-title">Group Id</div>
                <div className="detail-content flex gap-8">
                  <p className="details">{group?.id}</p>
                </div>
              </div>
              <div className="details-item flex">
                <div className="info-title">Group licenses</div>
                <div className="detail-content flex gap-8">
                  <p className="details">{group?.maxNumberOfStudents}</p>
                </div>
              </div>
              <div className="details-item flex">
                <div className="info-title">License start date</div>
                <div className="detail-content flex gap-8">
                  <p className="details">
                    {currLicense ? dayjs(currLicense.startDate).format('YYYY-MM-DD') : ''}
                  </p>
                </div>
              </div>
              <div className="details-item flex">
                <div className="info-title">License end date</div>
                <div className="detail-content flex gap-8">
                  <p className="details">
                    {currLicense ? dayjs(currLicense.endDate).format('YYYY-MM-DD') : ''}
                  </p>
                </div>
              </div>
              <div className="details-item flex">
                <div className="info-title">Weekly limit</div>
                <div className="detail-content flex gap-8">
                  <p className="details">{group?.weeklyLimit} sessions / learner</p>
                </div>
              </div>
              <div className="details-item flex">
                <div className="info-title">Total sessions per week</div>
                <div className="detail-content flex gap-8">
                  <p className="details">{group && sessionsPerWeekCalculated(group)} sessions</p>
                </div>
              </div>
              <div className="details-item flex">
                <div className="info-title">Total sessions per week including margin</div>
                <div className="detail-content flex gap-8">
                  <p className="details" style={{ fontWeight: 'bold' }}>
                    {group && orgData && sessionsPerWeekCalculatedWithMargin(group, orgData)}{' '}
                    sessions
                  </p>
                </div>
              </div>
              <div className="details-item flex">
                <div className="info-title">(Max) Class size</div>
                <div className="detail-content flex gap-8">
                  <p className="details">{group?.classSize} learners</p>
                </div>
              </div>
            </div>
            <div className="second-row">
              <div className="details-item flex">
                <div className="info-title">Timezone</div>
                <div className="detail-content flex gap-8">
                  {group && group.timezone ? (
                    <p className="details">{`${group.timezone}`}</p>
                  ) : (
                    <p className="details">null - UTC as default</p>
                  )}
                </div>
              </div>
              <div className="details-item flex">
                <div className="info-title">Margin</div>
                <div className="detail-content flex gap-8">
                  {orgData?.margin && <p className="details">{`${orgData.margin}%`}</p>}
                </div>
              </div>
              <div className="details-item flex">
                <div className="info-title">scheduling window</div>
                <div className="detail-content flex gap-8 column">
                  {group &&
                    group?.schedulingWindow &&
                    group.schedulingWindow.map((day, index) => {
                      if (day.intervals.length > 0) {
                        return (
                          <div className="day flex gap-16" key={index}>
                            <p className="info-title flex-1">{day.key}</p>{' '}
                            <div className="scheduling-window">
                              {day.intervals.map((interval, index) => (
                                <p key={index}>
                                  {interval.start} - {interval.end}
                                </p>
                              ))}
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div className="details-item flex">
                <div className="info-title">Exclusion dates</div>
                <div className="detail-content flex gap-8 column">
                  {orgData &&
                    orgData?.exclusionDates &&
                    orgData.exclusionDates.map((range, index) => {
                      return (
                        <div className="day flex gap-16" key={index}>
                          <p className="info-title flex-1">
                            Start: {JSON.stringify(range.startDate, null, 2)}
                          </p>{' '}
                          <p className="info-title flex-1">
                            End: {JSON.stringify(range.endDate, null, 2)}
                          </p>{' '}
                        </div>
                      );
                    })}
                  {/* <p className="details">{JSON.stringify(group?.schedulingWindow, null, 2)}</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="data-grid-container">
          <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} disableRowSelectionOnClick />
          </Box>
        </div>
      </div>
    </section>
  );
};
