import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { List, ListItem, ListItemText, Collapse, Box } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Children } from '../../utils/types';
import { usePrivateGroupsContext } from '../../providers/privateGroupsContext';

interface NestedSidebarProps {
  handleSelectGroup: (orgId: number, subOrgId: number, subOrgName: string, group: Children) => void;
}

export const NestedSidebar = ({ handleSelectGroup }: NestedSidebarProps) => {
  const { groupId } = useParams();
  const parsedGroupId = groupId ? parseInt(groupId) : null;
  const [openedNodes, setOpenedNodes] = useState<number[]>([]);
  const { setGroupByIdOrgs, allOrgs, selectedGroup, setSelectedGroupById, firstGroupId } =
    usePrivateGroupsContext();

  // const findFirstChildWithClassSize = (node: Children | null): Children | null => {
  //   if (!node) {
  //     return null;
  //   }
  //   if (node && node.children) {
  //     if (node.children[0].classSize !== undefined && node.children[0].classSize !== null) {
  //       return node.children[0];
  //     } else {
  //       const nestedChild = findFirstChildWithClassSize(node.children[0]);
  //       if (nestedChild) return nestedChild;
  //     }
  //   }
  //   return null;
  // };

  const handleClick = (id: number) => {
    const updatedNodes = [...openedNodes];

    if (updatedNodes.includes(id)) {
      const indexToRemove = updatedNodes.findIndex((el) => el === id);
      updatedNodes.splice(indexToRemove, 1);
    } else {
      updatedNodes.push(id);
    }
    setOpenedNodes(updatedNodes);
  };

  const ListItemSx = {
    '&:hover': {
      background: '#E6E6E6',
    },
    '&.selected': {
      background: 'none',
      '&:hover': {
        background: '#E6E6E6',
      },
    },
  };

  const GroupListItemSx = {
    paddingInlineStart: '64px',
    '&.selected': {
      color: '#9E007E',
      background: 'rgba(158, 0, 126, 0.05)',
      borderLeft: '3px solid #9E007E',
      '&. MuiListItemText-primary': {
        fontFamily: 'OpenSans-Bold',
        fontWeight: '700',
      },
    },
    '&:hover': {
      background: '#E6E6E6',
    },
  };

  const getOpenNodesArrayForGroup = (
    groupId: number,
    relatedNodes: number[],
    children: Children[],
  ) => {
    // case 1: first level is group:
    const firstLevelGroup = children?.find((org) => org.id === groupId);
    if (firstLevelGroup) {
      setOpenedNodes([...relatedNodes]);
    } else {
      // case 2: group is nested:
      children.map((subOrg) => {
        const updatedNodes = [...relatedNodes, subOrg.id];
        getOpenNodesArrayForGroup(groupId, updatedNodes, subOrg.children || []);
      });
    }
  };
  // const getOpenNodesArrayForGroup = async(
  //   groupId: number,
  //   relatedNodes: number[],
  //   children: Children[],
  // ) => {
  //   if (children && children.length > 0) {
  //     // case 1: first level is group:
  //     const firstLevelGroup = children.find((org) => org.id === groupId);
  //     if (firstLevelGroup) {
  //       setOpenedNodes(relatedNodes);
  //     } else {
  //       // case 2: group is nested:
  //       const updatedNodes: number[] = [...relatedNodes];
  //       updatedNodes.push(groupId);
  //       // getOpenNodesArrayForGroup()
  //     }
  //   }
  // };

  useEffect(() => {
    if (selectedGroup === null || selectedGroup === undefined) {
      if (firstGroupId && allOrgs) {
        setSelectedGroupById(firstGroupId, allOrgs[0].id);
        getOpenNodesArrayForGroup(firstGroupId, [], allOrgs);
      }
    } else {
      if (openedNodes.length === 0 && selectedGroup && allOrgs) {
        getOpenNodesArrayForGroup(selectedGroup.id, [], allOrgs);
      }
    }
    if (parsedGroupId && allOrgs && openedNodes.length === 0) {
      setGroupByIdOrgs(parsedGroupId, allOrgs);
    }
  }, [allOrgs, selectedGroup, parsedGroupId]);

  const getGroupNode = (org: Children, subOrg: Children, group: Children) => {
    if (selectedGroup) {
      return (
        <ListItem
          button
          key={group.id}
          sx={GroupListItemSx}
          selected={selectedGroup.id === group.id}
          className={selectedGroup.id === group.id ? 'selected' : ''}
          onClick={() => handleSelectGroup(org.id, subOrg.id, subOrg.name, group)}
        >
          <ListItemText
            primary={group.name}
            primaryTypographyProps={
              selectedGroup.id === group.id ? { fontFamily: 'OpenSans-Bold' } : {}
            }
          />
        </ListItem>
      );
    }
  };

  const getSubOrgNode = (org: Children, subOrg: Children) => {
    return (
      <Box key={subOrg.id}>
        <ListItem
          button
          onClick={() => handleClick(subOrg.id)}
          style={{ paddingInlineStart: '42px' }}
          selected={openedNodes.includes(subOrg.id)}
          className={openedNodes.includes(subOrg.id) ? 'selected' : ''}
          sx={ListItemSx}
        >
          {openedNodes.includes(subOrg.id) ? <ExpandLess /> : <ExpandMore />}
          <ListItemText
            primary={subOrg.name}
            primaryTypographyProps={
              openedNodes.includes(subOrg.id) ? { fontFamily: 'OpenSans-SemiBold' } : {}
            }
          />
        </ListItem>
        <Collapse in={openedNodes.includes(subOrg.id)} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subOrg.children &&
              selectedGroup &&
              subOrg?.children?.map((node) => {
                return node.classSize !== null && node.classSize !== undefined
                  ? getGroupNode(org, subOrg, node)
                  : getSubOrgNode(org, node);
              })}
          </List>
        </Collapse>
      </Box>
    );
  };

  const getOrgListItems = (org: Children | null) => {
    // case 1: flat (org is group with classSize), org = group:
    if (selectedGroup && org) {
      if (org.classSize !== null && org.classSize !== undefined) {
        return (
          <Box key={org.id}>
            <ListItem
              button
              key={org.id}
              sx={GroupListItemSx}
              selected={selectedGroup.id === org.id}
              className={selectedGroup.id === org.id ? 'selected' : ''}
              onClick={() => handleSelectGroup(org.id, org.id, org.name, org)}
            >
              <ListItemText
                primary={org.name}
                primaryTypographyProps={
                  selectedGroup.id === org.id ? { fontFamily: 'OpenSans-Bold' } : {}
                }
              />
            </ListItem>
          </Box>
        );
      }
      // case 2: 2nd level or more is the group level:
      return (
        <Box key={org.id}>
          <ListItem
            button
            onClick={() => handleClick(org.id)}
            style={{ paddingInlineStart: '24px' }}
            selected={openedNodes.includes(org.id)}
            className={openedNodes.includes(org.id) ? 'selected' : ''}
            sx={ListItemSx}
          >
            {openedNodes.includes(org.id) ? <ExpandLess /> : <ExpandMore />}
            <ListItemText
              primary={org.name}
              primaryTypographyProps={
                openedNodes.includes(org.id) ? { fontFamily: 'OpenSans-SemiBold' } : {}
              }
            />
          </ListItem>
          <Collapse in={openedNodes.includes(org.id)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {org?.children?.map((subOrg) =>
                subOrg.classSize !== null && subOrg.classSize !== undefined
                  ? getGroupNode(org, subOrg, subOrg)
                  : getSubOrgNode(org, subOrg),
              )}
            </List>
          </Collapse>
        </Box>
      );
    }
  };

  return (
    <section
      className="nested-sidebar"
      style={{
        zIndex: '5',
        marginBottom: '200px',
      }}
    >
      <p style={{ margin: '32px 24px 40px', fontSize: '18px' }}>Private groups</p>
      <div>{allOrgs && allOrgs?.map((org) => getOrgListItems(org))}</div>
    </section>
  );
};
