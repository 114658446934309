import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Session } from "@utils/types";
import SessionRow from "./SessionRow";
import GradientLoader from "../common/Loader";

interface SessionTableProps {
  sessions: Session[] | undefined;
  isLoading: boolean;
}

const headerStyle = { fontWeight: "600", borderBottom: "1px solid #151515" };
const tableStyle = {
  border: "0",
  borderCollapse: "separate",
  borderSpacing: "0 4px",
  borderRadius: "4px",

  "& .MuiTableBody-root": {
    borderRadius: "4px",

    "& .MuiTableRow-root": {
      "& .MuiTableCell-root": {
        border: "0",
      },
    },
  },
};

export default function SessionTable({
  sessions,
  isLoading,
}: SessionTableProps) {
  return (
    <>
      <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
        <Table
          sx={tableStyle}
          stickyHeader
          aria-label="sticky collapsible table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={headerStyle}>Time</TableCell>
              <TableCell sx={headerStyle}> Topic</TableCell>
              <TableCell sx={headerStyle}>Tutor</TableCell>
              <TableCell sx={headerStyle}>
                <GroupOutlinedIcon />
              </TableCell>
              <TableCell sx={headerStyle}>ID</TableCell>
              <TableCell sx={headerStyle} />
              <TableCell sx={headerStyle} />
            </TableRow>
          </TableHead>

          {sessions && (
            <TableBody>
              {sessions.map((session) => (
                <SessionRow key={session.id} session={session} />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {isLoading && (
        <div
          className="flex column justify-center align-center"
          style={{ paddingTop: "136px" }}
        >
          <GradientLoader />
        </div>
      )}
      {sessions && sessions.length === 0 && !isLoading && (
        <div className="no-sessions full-width">
          <p style={{ marginTop: "24px" }}>
            There are no sessions scheduled for the selected day
          </p>
        </div>
      )}
    </>
  );
}
