import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';

interface RequiredSummaryProps {
  msgs?: string[];
  existingSessionsNote?: boolean;
  noMargin?: boolean;
}

export const RequiredSummary = ({
  msgs,
  existingSessionsNote = false,
  noMargin = false,
}: RequiredSummaryProps) => {
  return (
    <section
      className={`required-summary-section ${existingSessionsNote ? 'blue' : ''} ${
        noMargin ? 'noMargin' : ''
      }`}
    >
      <div className="required-summary-content ">
        {msgs && !existingSessionsNote && (
          <>
            <div className="flex gap-8">
              <div className="error-filter">
                <ErrorOutlinedIcon />
              </div>
              <span>Required details to complete:</span>
            </div>
            <div className="msgs-container">
              <ul className="msgs-list">
                {msgs.map((msg, index) => (
                  <li key={index}>{msg}</li>
                ))}
              </ul>
            </div>
          </>
        )}
        {existingSessionsNote && (
          <>
            <div className="flex gap-8 align-center">
              <pre style={{ fontFamily: 'openSans-SemiBold' }}>Note:</pre>
              <p>
                {' '}
                The earliest start date was automatically selected. It takes into consideration
              </p>
              <pre style={{ color: '#007a9c', fontFamily: 'openSans-SemiBold' }}>
                existing sessions.
              </pre>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
