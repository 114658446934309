import { FC, ReactNode } from 'react';
// import addIconImage from "../../assets/image/add-24.png";
// import nextIconImage from "../../assets/image/next-24.png";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ReportSharpIcon from '@mui/icons-material/ReportSharp';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import SpellcheckOutlinedIcon from '@mui/icons-material/SpellcheckOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { Button } from '@mui/material';

interface PinkBtnProps {
  onClick?: () => void;
  children: ReactNode;
  addIcon?: boolean;
  nextIcon?: boolean;
  upIcon?: boolean;
  reportIcon?: boolean;
  reportOffIcon?: boolean;
  small?: boolean;
  xSmall?: boolean;
  tableCell?: boolean;
  isSelected: boolean;
  btnGroup?: boolean;
  globeIcon?: boolean;
  grammarIcon?: boolean;
  briefcaseIcon?: boolean;
  pinDropIcon?: boolean;
  thinPadding?: boolean;
  disable?: boolean;
}

const PinkBtn: FC<PinkBtnProps> = ({
  onClick,
  children,
  isSelected,
  addIcon = false,
  nextIcon = false,
  upIcon = false,
  reportIcon = false,
  reportOffIcon = false,
  small = false,
  xSmall = false,
  tableCell = false,
  btnGroup = false,
  globeIcon = false,
  grammarIcon = false,
  briefcaseIcon = false,
  pinDropIcon = false,
  thinPadding = false,
  disable = false,
}) => {
  const buttonStyles = {
    borderRadius: '4px',
    border: `1px solid #919191`,
    background: '#FFF',
    fontFamily: 'OpenSans',
    fontSize: '16px',
    fontStyle: 'normal',
    color: '#333',
    textTransform: 'unset',
    fontWeight: '400',
    letterSpacing: 'unset',
    // font-weight: '600';
    lineHeight: '34px',
    gap: '10px',
    '&:hover': {
      // borderRadius: "4px",
      border: `1px solid #151515`,
      background: '#FAFAFA',
    },
    '&.selected': {
      color: '#9E007E',
      fontWeight: '600',
      border: '1px solid #9E007E',
      background:
        'linear-gradient(0deg, rgba(158, 0, 126, 0.05) 0%, rgba(158, 0, 126, 0.05) 100%), #FFF',
    },
    '&.Mui-selected': {
      color: '#9E007E',
      border: '1px solid #9E007E',
      background:
        'linear-gradient(0deg, rgba(158, 0, 126, 0.05) 0%, rgba(158, 0, 126, 0.05) 100%), #FFF',
    },
    '&.small': {
      fontSize: '14px',
    },
    '&.x-small': {
      fontSize: '12px',
    },
    '&.hour-slot': {
      fontSize: '14px',
      width: '100%',
      height: '100%', // why is this not working?
      padding: '4px',
      lineHeight: '150%',
    },
    '&.btnGroup': {
      fontSize: '14px',
    },
    '&.thin-padding': {
      minWidth: 'unset',
      padding: '16px 4px',
      width: 'fit-content',
      height: 'fit-content',
    },
    '&.disable': {
      border: '1px solid #DFE1E1',
      background: '#F5F5F5',
      color: '#919191',
      cursor: 'not-allowed',
    },
  };

  return (
    <Button
      onClick={!disable ? onClick : undefined}
      sx={buttonStyles}
      className={`pink-btn ${isSelected ? 'selected' : ''} ${
        btnGroup ? 'btnGroup' : ''
      } ? "selected" : ""} ${small ? 'small' : ''} ${xSmall ? 'x-small' : ''} ${
        thinPadding ? 'thin-padding' : ''
      } ${tableCell ? 'hour-slot' : ''} ${disable ? 'disable' : ''}`}
      style={{}}
    >
      {globeIcon && <PublicOutlinedIcon />}
      {grammarIcon && <SpellcheckOutlinedIcon />}
      {briefcaseIcon && <WorkOutlineOutlinedIcon />}
      {pinDropIcon && <PinDropOutlinedIcon />}
      {(addIcon || nextIcon || upIcon) && <AddRoundedIcon />}
      {reportIcon && <ReportSharpIcon />}
      {reportOffIcon && <ReportOffOutlinedIcon />}
      {children}
    </Button>
  );
};

export default PinkBtn;
