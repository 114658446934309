import { useEffect, useState } from "react";
// import Box from "@mui/material/Box";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MondlyTopic } from "../../utils/types";
import { useUserContext } from "../../components/Providers/UserContext";
import useFetchMondlyTopics from "Query/useFetchMondlyTopics";
import { Autocomplete, TextField } from "@mui/material";

interface MondlyTopicSelectProps {
  onMondlyTopicChange: (mondlyTopic: MondlyTopic, language: string) => void;
  selectedLanguage: string;
  selectedMondlyCategory?: string | null;
}

const MondlyTopicSelect: React.FC<MondlyTopicSelectProps> = ({
  onMondlyTopicChange,
  selectedLanguage,
  selectedMondlyCategory,
}: MondlyTopicSelectProps) => {
  const { userToken } = useUserContext();
  const { data: mondlyTopics } = useFetchMondlyTopics(userToken);
  const mondlyTopicsOptions = mondlyTopics?.filter(
    (mTopic) => mTopic.language === selectedLanguage
  );
  const currentMondlyTopic = mondlyTopicsOptions?.find((sTopic) => sTopic.mondlyCategory === selectedMondlyCategory)

  const [selectedValue, setSelectedValue] = useState<string>(currentMondlyTopic?.mondlyTopic || '');


  useEffect(() => {
    if (currentMondlyTopic && currentMondlyTopic.mondlyTopic) {
      // onMondlyTopicChange(currentMondlyTopic, selectedLanguage) 
      setSelectedValue(currentMondlyTopic.mondlyTopic)
    }
  }, [selectedLanguage, selectedValue])
  
  
  const handleChange = (
    // event: React.SyntheticEvent<Element, Event>,
    value: MondlyTopic | null) =>
  {
    // const { value } = event.target;
    if (value) {
      setSelectedValue(value.mondlyTopic || '');
      const updatedMondlyTopic = mondlyTopics?.find(
        (el) => el.mondlyTopic == value.mondlyTopic
      );
      if (updatedMondlyTopic) {
        onMondlyTopicChange(updatedMondlyTopic, selectedLanguage);
      }
    }
    
  };
  

  // const dropDownStyle = {
  //   minWidth: 120,
  //   margin: "16px 0 8px",

  //   "&.language-view": {
  //     width: "440px",
  //   },
  // };
  return (
    <section className="textfield-custom">
      <p className="textfield-label">Mondly topic</p>
      <Autocomplete
        key={currentMondlyTopic?.id}
        value={currentMondlyTopic}
        options={mondlyTopicsOptions || []}
        getOptionLabel={(option) => option.mondlyTopic || ''}
        onChange={(e, value) => {
          handleChange(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            // label="Mondly Topic"
          />
        )}
      />
      {/* <Box sx={dropDownStyle}>
        <FormControl fullWidth>
          <Select value={selectedValue} onChange={handleChange}>
            {mondlyTopicsOptions?.map((mTopic) => (
              <MenuItem key={mTopic.id} value={mTopic.mondlyTopic}>
                {mTopic.mondlyTopic}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box> */}
    </section>
  );
};

export default MondlyTopicSelect;
