import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import useFetchLocations from 'Query/useFetchLocation';
import { useUserContext } from '../../components/Providers/UserContext';
import { Location } from '../../utils/types';

interface PriceLocationSelectProps {
  onChange?: (selectedLocation: Location | null) => void;
  tutorLocation?: Location;
  sessionLocation?: Location | undefined;
  label?: string;
  name: string;
  error?: boolean;
  withAny?: boolean;
  groupOptions?: Location[] | null | undefined;
}

const PriceLocationSelect = ({
  onChange,
  label,
  name,
  tutorLocation,
  sessionLocation,
  error = false,
  withAny = false,
  groupOptions = [],
}: PriceLocationSelectProps) => {
  const { userToken } = useUserContext();
  const { data: Locations } = useFetchLocations(userToken);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>('');

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    if (value === 'Any location' && onChange) {
      setSelectedLocation(value);
      onChange(null);
    }
    const sLocation = Locations?.find((loc) => value.includes(loc.location));
    if (sLocation && onChange) {
      setSelectedLocation(value);
      onChange(sLocation);
    }
  };

  const locationsFormatted = () => {
    if (Locations) {
      const locationOptions = Locations?.map(
        (loc) => `${loc.code.length ? loc.code + ' - ' : ''}${loc.location}`,
      );
      // re-arrange array to fit ux:
      setSelectOptions(locationOptions.reverse());

      // Step 1: Extract the last element ('Other')
      const elementToMove = locationOptions.find((loc) => loc.includes('Other'));

      // Step 2: Move 'Other' to last
      let orderedLocations: string[] = [];
      if (elementToMove) {
        orderedLocations = locationOptions.slice(1).concat(elementToMove);
      }

      // Step 3: SetState:
      if (orderedLocations.length) {
        setSelectOptions(orderedLocations);
      }

      if (withAny) {
        const allLocationsNoOther = locationOptions.filter((loc) => !loc.includes('Other'));
        const allLocationsWithAny: string[] = ['Any location'].concat(allLocationsNoOther);
        setSelectOptions(allLocationsWithAny);
        setSelectedLocation(allLocationsWithAny[0]);
      }
    }
  };

  const locationFormatted = (location: Location) => {
    const selectedLocationString = `${location?.code?.length ? location.code + ' - ' : ''}${
      location.location
    }`;
    setSelectedLocation(selectedLocationString);
  };

  useEffect(() => {
    locationsFormatted();
  }, [Locations]);

  useEffect(() => {
    if (tutorLocation) {
      locationFormatted(tutorLocation);
    }
    if (sessionLocation) {
      locationFormatted(sessionLocation);
    }
  }, [sessionLocation]);

  const menuItemSx = {
    '&.Mui-selected': {
      backgroundColor: 'rgba(158, 0, 126, 0.05)',
      '&:hover': {
        backgroundColor: 'rgba(158, 0, 126, 0.05)',
      },
    },
  };

  const checkIfOptionDisable = (option: string) => {
    if (groupOptions && groupOptions.length) {
      const found = groupOptions?.find((groupOption) => option.includes(groupOption.location));
      if (found) {
        return false;
      }
      return true;
    }
    return false;
  };

  return (
    <section className="textfield-custom">
      <Box sx={{ minWidth: 120, margin: '16px 0 8px' }}>
        <FormControl fullWidth>
          {label && <p className="textfield-label">{label}</p>}
          <Select value={selectedLocation} name={name} onChange={handleChange} error={!!error}>
            {selectOptions.map((option) => (
              <MenuItem
                key={option}
                value={option}
                sx={menuItemSx}
                disabled={checkIfOptionDisable(option)}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
          {error && (
            <FormHelperText
              className="required-error-helper-txt"
              style={{ color: 'red', fontFamily: 'OpenSans-SemiBold' }}
            >
              Required
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    </section>
  );
};

export default PriceLocationSelect;
