import { useQuery } from '@tanstack/react-query';
import { getGroupHasSessions } from '../utils/Network';
import { Children } from '@utils/types';
import { TIME } from '../utils/constants';

const STALE_TIME = TIME.minute * 5;
const CACHE_TIME = TIME.minute + 5 * TIME.second;

const useFetchPrivateGroupHasSessionsById = (groupId: number, userToken: string) =>
  useQuery<Children>({
    queryKey: ['privateGroupHasSessions', groupId],
    queryFn: () => getGroupHasSessions(groupId, userToken),
    staleTime: STALE_TIME,
    gcTime: CACHE_TIME,
    enabled: !!userToken && groupId > 0,
    refetchOnWindowFocus: false,
  });

export default useFetchPrivateGroupHasSessionsById;
