import React, { useEffect, useMemo, useState } from 'react';
import {
  // Select,
  // MenuItem,
  Autocomplete,
  FormControl,
  Box,
  TextField,
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as moment from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data';

interface TimezoneOption {
  value: string;
  label: string;
}

interface TimezoneDateTimePickerProps {
  handleChangeCustom: (name: string, value: string) => void;
  name: string;
}

export const TimezoneDateTimePicker = ({
  handleChangeCustom,
  name,
}: TimezoneDateTimePickerProps) => {
  // initial timezone(user local):
  const formatTimeZoneOffset = () => {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesPart = Math.abs(offsetMinutes) % 60;

    const formattedOffset =
      (offsetMinutes < 0 ? '+' : '-') +
      (offsetHours < 10 ? '0' : '') +
      offsetHours +
      ':' +
      (offsetMinutesPart < 10 ? '0' : '') +
      offsetMinutesPart;

    return formattedOffset;
  };

  const [timezones, setTimezones] = useState<TimezoneOption[] | null>([]);
  const currentDay = dayjs().set('hour', 12).set('minute', 0);
  const [selectedTimezone, setSelectedTimezone] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<Date | null | dayjs.Dayjs>(currentDay);
  const [selectedDate, setSelectedDate] = useState<Date | null | dayjs.Dayjs>(currentDay);
  const [formattedDate, setFormattedDate] = useState<string>('');
  const [formattedTime, setFormattedTime] = useState<string>('');
  const [formattedTimezone, setFormattedTimezone] = useState<string>(formatTimeZoneOffset());

  useEffect(() => {
    const allTimeZones: string[] = moment.tz.names();
    const timezoneFormatted: TimezoneOption[] = allTimeZones.map((timezone) => {
      return {
        label: `${timezone.replace(/_/g, ' ')}  UTC${moment.tz(timezone).format('Z')}`, // Replace underscores with spaces
        value: moment.tz(timezone).format('Z'),
      };
    });
    setTimezones(timezoneFormatted);
    const foundTimeZone = timezoneFormatted.find((tz) => tz.label.includes('EST'));
    if (foundTimeZone) {
      setSelectedTimezone(foundTimeZone.label);
      setFormattedTimezone(foundTimeZone.value);
    }
  }, []);

  const combineAndConvertToUTC = (): Date | undefined => {
    if (formattedDate && formattedTime && formattedTimezone) {
      const combinedDateTimeString = `${formattedDate} ${formattedTime}`;
      const combinedDateTimeTimezoneString = `${formattedDate} ${formattedTime} ${formattedTimezone}`;
      const combinedDateTime = new Date(combinedDateTimeString);
      // Check if the Date object is valid
      if (isNaN(combinedDateTime.getTime())) {
        console.error('Invalid date or time format');
        handleChangeCustom(name, '');
        return undefined;
      }
      const utcTime = new Date(combinedDateTimeTimezoneString).toISOString();
      handleChangeCustom(name, utcTime);
    }
  };

  useMemo(() => {
    combineAndConvertToUTC();
  }, [formattedDate, formattedTime, formattedTimezone]);

  const handleTimezoneChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: TimezoneOption | null,
  ) => {
    setSelectedTimezone(value ? value.label : '');
    setFormattedTimezone(value ? value.value : '');
    combineAndConvertToUTC();
    if (!value) {
      handleChangeCustom(name, '');
      return undefined;
    }
  };

  const handleTimeChange = (newTime: Date | null | dayjs.Dayjs) => {
    if (newTime) {
      const formattedHour = dayjs(newTime).format('HH');
      const formattedMinutes = dayjs(newTime).format('mm');
      const formattedTime = `${formattedHour}:${formattedMinutes}`;
      setFormattedTime(formattedTime);
      setSelectedTime(newTime);
      combineAndConvertToUTC();
    }
  };

  const handleDateChange = (newDate: Date | null | dayjs.Dayjs) => {
    if (newDate) {
      const formattedDate = dayjs(newDate).format('YYYY-MM-DD');
      setFormattedDate(formattedDate);
      setSelectedDate(dayjs(newDate));
      combineAndConvertToUTC();
    }
  };

  // initiate formatted time and date:
  useEffect(() => {
    if (currentDay) {
      handleTimeChange(currentDay);
      handleDateChange(currentDay);
    }
  }, []);

  const customBoxSx = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  };

  return (
    <section className="timezone-date-time-picker">
      <Box sx={customBoxSx}>
        <Box mt={2} sx={{ width: '100%' }}>
          <p className="input-label">Date</p>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker value={selectedDate} onChange={(newDate) => handleDateChange(newDate)} />
          </LocalizationProvider>
        </Box>

        <FormControl fullWidth>
          <p className="input-label">Time zone</p>

          {timezones && (
            <Autocomplete
              value={timezones.find((tz) => tz.label === selectedTimezone) || null}
              onChange={(event, value) => handleTimezoneChange(event, value)}
              options={timezones}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          )}
        </FormControl>

        <Box mt={2} sx={{ marginTop: 0, width: '100%' }}>
          <p className="input-label">Time</p>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker value={selectedTime} onChange={handleTimeChange} ampm={false} />
          </LocalizationProvider>
        </Box>
      </Box>
    </section>
  );
};
