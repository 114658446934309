import { useState } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useUserContext } from '../Providers/UserContext';
import DynamicButton from '../common/DynamicButton';
import DateRangePicker from '../form/DateRangePicker';
import { Children, ExclusionInterval } from '../../utils/types';
import { generate4DigitId } from '../../utils/utilsts';
import useUpdateOrgExclusionDates from '../../useMutation/useUpdateOrgExclusionDates';
interface DateRangePickerMultipleProps {
  orgData: Children | null | undefined;
}

interface ExtendedExInt {
  exclusionInterval: ExclusionInterval;
  id: number;
  isValid: boolean; // validate user actively picked the exclusion date, and hasn't left null at datePicker
}

export const DateRangePickerMultiple = ({ orgData }: DateRangePickerMultipleProps) => {
  const { userToken } = useUserContext();
  const { mutateAsync: updateOrgExclusionDates } = useUpdateOrgExclusionDates();
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedExclusionDates, setSelectedExclusionDates] = useState<ExclusionInterval[] | null>(
    orgData?.exclusionDates || [],
  );
  const [updatedExclusionDates, setUpdatedExclusionDates] = useState<ExtendedExInt[]>([]);
  const maxDate = () => {
    if (orgData && orgData.licenses && orgData.licenses.length > 0) {
      return orgData.licenses[orgData.licenses.length - 1].endDate;
    } else {
      return dayjs().add(1, 'year');
    }
  };

  const handleAddDateClick = () => {
    setIsEditMode(true);
    // Add new dateRangePicker:
    setUpdatedExclusionDates((prev) => [
      ...prev,
      {
        exclusionInterval: {
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
        },
        id: generate4DigitId(),
        isValid: false,
      },
    ]);
  };

  const onHandleDateRangeSelect = (val: ExclusionInterval, id: number, isValid: boolean) => {
    if (id) {
      const splicedExclusionDates = [...updatedExclusionDates];
      const updatedItem: ExtendedExInt = { exclusionInterval: val, id, isValid };
      const index = splicedExclusionDates.findIndex((el) => el.id === id);
      if (index !== -1) {
        splicedExclusionDates.splice(index, 1, updatedItem);
      }
      setUpdatedExclusionDates(splicedExclusionDates);
    }
  };

  const dateRangeDelete = (id: number) => {
    const splicedExclusionDates = [...updatedExclusionDates];
    const filteredDates = splicedExclusionDates.filter((el) => el.id !== id);
    setUpdatedExclusionDates(filteredDates);
  };

  const handleExclusionDatesCancel = () => {
    // assuming there is no scenario where user can edit when there are previous exclusionDates
    setUpdatedExclusionDates([]);
    setIsEditMode(false);
  };

  const handleSaveExclusionDates = async () => {
    setIsEditMode(false);
    if (updatedExclusionDates.length > 0 && orgData) {
      const selectedExclusionDatesInterval: ExclusionInterval[] = updatedExclusionDates.map(
        (el): ExclusionInterval => ({
          startDate: el.exclusionInterval.startDate,
          endDate: el.exclusionInterval.endDate,
        }),
      );
      const exclusionDatesMutationData: {
        rootId: number;
        exclusionDates: ExclusionInterval[];
        userToken: string;
      } = {
        rootId: orgData.id,
        exclusionDates: selectedExclusionDatesInterval,
        userToken,
      };
      const resExclusionDates = await updateOrgExclusionDates(exclusionDatesMutationData);
      setSelectedExclusionDates(resExclusionDates);
    }
  };

  const handleRemoveDatesClick = async () => {
    if (orgData) {
      const exclusionDatesMutationData = {
        rootId: orgData.id,
        exclusionDates: [],
        userToken,
      };
      const resExclusionDates = await updateOrgExclusionDates(exclusionDatesMutationData);
      setSelectedExclusionDates(resExclusionDates);
    }
  };

  const checkSaveDisable = () => {
    if (updatedExclusionDates) {
      if (updatedExclusionDates && updatedExclusionDates.length === 0) {
        return true;
      }
      if (
        updatedExclusionDates.some((el) =>
          dayjs(el.exclusionInterval.endDate).isBefore(el.exclusionInterval.startDate),
        )
      ) {
        return true;
      }
      if (
        updatedExclusionDates.some(
          (el) => !el.exclusionInterval.startDate || !el.exclusionInterval.endDate,
        )
      ) {
        return true;
      }
      if (updatedExclusionDates.some((el) => !el.isValid)) {
        return true;
      }
      return false;
    }
    return false;
  };

  const isSaveBtnDisable = checkSaveDisable();

  return (
    <section className="date-range-picker-multiple-section flex column gap-8">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="date-range-picker-multiple-container flex gap-24 space-between">
          <div className="date-range-picker-multiple-content flex column" style={{ gap: '30px' }}>
            {/* case1: show existing excl.dates, !isEditMode*/}
            {selectedExclusionDates &&
              selectedExclusionDates.length > 0 &&
              !isEditMode &&
              selectedExclusionDates.map((range) => {
                // case1.1: same day (single day)
                if (range.startDate.includes(range.endDate)) {
                  return (
                    <p key={range.startDate} className="date-range">
                      {dayjs(range.startDate).format('DD/MM/YYYY')}
                    </p>
                  );
                }
                // case1.2: range
                if (!range.startDate.includes(range.endDate)) {
                  return (
                    <p key={range.startDate} className="date-range">
                      {dayjs(range.startDate).format('DD/MM/YYYY')} -{' '}
                      {dayjs(range.endDate).format('DD/MM/YYYY')}
                    </p>
                  );
                }
              })}
            {/* case2: No exclusionDates =>click on Add date=> add new dateRange => on save activate useMutation*/}
            {isEditMode && updatedExclusionDates.length > 0 && (
              <>
                {updatedExclusionDates.map((exclusionItem) => {
                  return (
                    <DateRangePicker
                      key={exclusionItem.id}
                      id={exclusionItem.id}
                      maxDate={maxDate.toString()}
                      dateRange={exclusionItem.exclusionInterval}
                      diff={
                        dayjs(exclusionItem.exclusionInterval.endDate).diff(
                          exclusionItem.exclusionInterval.startDate,
                          'day',
                        ) + 1
                      }
                      enableDelete={updatedExclusionDates.length > 1}
                      onChange={(val: ExclusionInterval, id: number, isValid: boolean) =>
                        onHandleDateRangeSelect(val, id, isValid)
                      }
                      onDateRangeDelete={(id: number) => dateRangeDelete(id)}
                    />
                  );
                })}
              </>
            )}
            {selectedExclusionDates && selectedExclusionDates.length <= 0 && (
              <DynamicButton size={'medium'} type="quaternary" onClick={handleAddDateClick}>
                <AddOutlinedIcon />
                Add date
              </DynamicButton>
            )}
            {selectedExclusionDates && selectedExclusionDates.length > 0 && (
              <DynamicButton size={'medium'} type="quaternary" onClick={handleRemoveDatesClick}>
                Remove all dates (Dev mode)
              </DynamicButton>
            )}
          </div>
          {isEditMode && (
            <div className="flex column gap-8">
              <DynamicButton
                size="small"
                type="secondary"
                onClick={() => handleSaveExclusionDates()}
                fullWidth
                disable={isSaveBtnDisable}
              >
                Save
              </DynamicButton>
              <DynamicButton
                size="small"
                type="tertiary"
                onClick={() => handleExclusionDatesCancel()}
              >
                Cancel
              </DynamicButton>
            </div>
          )}
        </div>
      </LocalizationProvider>
    </section>
  );
};
