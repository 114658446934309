import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

// Inspired by the former Facebook spinners.
function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#9e007e' : '#ff8275'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

// // From https://github.com/mui/material-ui/issues/9496#issuecomment-959408221
// function GradientCircularProgress() {
//   return (
//     <React.Fragment>
//       <svg width={0} height={0}>
//         <defs>
//           <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
//             <stop offset="0%" stopColor="#e01cd5" />
//             <stop offset="100%" stopColor="#1CB5E0" />
//           </linearGradient>
//         </defs>
//       </svg>
//       <CircularProgress
//         sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
//       />
//     </React.Fragment>
//   );
// }

export default function GradientLoader() {
  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }} alignItems="center">
      <FacebookCircularProgress />
      {/* <GradientCircularProgress /> */}
    </Stack>
  );
}
