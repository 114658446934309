import { useMemo, useRef, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import BackButton from '../components/layout/BackButton';
import DynamicButton from '../components/common/DynamicButton';
import FileUploadProgress from '../components/form/FileUploadProgress';
import {
  ClassMaterial,
  Language,
  MondlyTopic,
  Topic,
  LanguageData,
  contentSensitivity,
} from '../utils/types';
import { getFileType } from '../utils/utilsts';
import { useTopicContext } from 'providers/TopicContext';
import TextFieldCustom from '../components/form/TextFieldCustom';
import SingleSelectBtns from '../components/form/SingleSelectBtns';
import LanguageSelect from '../components/form/LanguageSelect';
import useFetchCategory from 'Query/useFetchCategory';
import { useUserContext } from '../components/Providers/UserContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getLangData, updateTopic, uploadTopicFiles } from '../utils/Network';
import { useLocation, useNavigate } from 'react-router-dom';
import GseSlider from '../components/form/GseSlider';
import useFetchMondlyTopics from 'Query/useFetchMondlyTopics';
import MondlyTopicSelect from '../components/form/MondlyTopicSelect';

type ProgressMap = {
  [key: string]: number;
};

export type TopicData = {
  id?: number;
  category: {
    id: number;
    category: string;
  } | null;
  GSELevel: {
    minGSE: number;
    maxGSE: number;
  };
  languageData: LanguageData[];
  contentSensitivity: boolean | null;
  classMaterial?: ClassMaterial[];
};

const TopicAdd = () => {
  const [uploadingFiles, setUploadingFiles] = useState<ProgressMap>({});
  const sLanguage = useLocation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    sLanguage?.state?.selectedLanguage || {
      code: 'en_us',
      language: 'English',
      id: 1,
    },
  );

  const { topic, setTopic } = useTopicContext(); // only for initial values
  const navigate = useNavigate();
  const [selectedContentSensitivity, setSelectedContentSensititvity] = useState<string | null>(
    topic
      ? topic.contentSensitivity
        ? contentSensitivity.Adapted
        : contentSensitivity.standard
      : null,
  );
  const [selectedGSELevel, setSelectedGSELevel] = useState(
    topic?.GSELevel || { minGSE: 22, maxGSE: 90 },
  );
  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    topic?.category?.category || null,
  );
  const [topicFiles, setTopicFiles] = useState<ClassMaterial[]>(topic?.classMaterial || []);

  const { userToken } = useUserContext();
  const { data: Categories } = useFetchCategory(userToken);
  const { data: mondlyTopics } = useFetchMondlyTopics(userToken);
  const [selectedMondlyCategory, setSelectedMondlyCategory] = useState(
    topic?.languageData?.find((lData) => lData.language === selectedLanguage?.code)?.mondlyTopic
      ?.mondlyCategory || null,
  );
  const queryClient = useQueryClient();

  const topicMutation = useMutation({
    mutationFn: () => updateTopic(topicData as Topic, userToken),
    onSuccess: (updatedTopic) => {
      setTopic(updatedTopic);
      queryClient.setQueryData(['topics'], (oldTopicList: Topic[]) =>
        oldTopicList.map((cTopic: Topic) =>
          cTopic.id === updatedTopic.id ? updatedTopic : cTopic,
        ),
      );
      navigate('/topicview');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['topics'] });
    },
  });

  const setProgressByFile = (progress: number, fileName: string) => {
    setUploadingFiles((prevProgressMap) => ({
      ...prevProgressMap,
      [fileName]: progress,
    }));
  };

  const upload = useMutation({
    mutationFn: (file: File) =>
      uploadTopicFiles(file, userToken, setProgressByFile).then((fileData) =>
        setTopicFiles([
          ...(topicFiles || []),
          {
            file: fileData.file,
            upload: true,
            topicId: topicData?.id || -1,
            id: fileData.id || 0,
          },
        ]),
      ),
  });

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      upload.mutate(file);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleBackButtonClick = () => {
    if (topic?.languageData) {
      topic.languageData = topic?.languageData.filter(
        (langData) => langData.description && langData.title && langData.id,
      );
    }
    if (topicData?.languageData) {
      topicData.languageData = topicData?.languageData.filter(
        (langData) => langData.description && langData.title && langData.id,
      );
    }
    setTopic(topic ? topic : null);
    setTopicData(topicData);
    navigate(topic ? '/topicview' : '/topics');
  };

  const handleCategoryChange = (name: string, category: string) => {
    if (category) {
      const updatedCategory = Categories?.find((cat) => cat.category === category);
      setSelectedCategory(category);
      if (updatedCategory) {
        setTopicData((prevData) => ({
          ...prevData,
          category: updatedCategory,
        }));
      }
    }
    // setIsCreateProfileDisabled(!verifyRequiredFields(tutorData));
  };

  const [topicData, setTopicData] = useState<TopicData>({
    // component state for topic data and updates
    id: topic?.id,
    category: topic?.category || null,
    GSELevel: topic?.GSELevel || selectedGSELevel,
    languageData: topic?.languageData || [],
    contentSensitivity: topic?.contentSensitivity || false,
    classMaterial: topicFiles,
  });

  useMemo(() => {
    setTopicData((prevFormData) => ({
      ...prevFormData,
      // levels: selectedLevels,
      GSELevel: selectedGSELevel,
      // category: selectedCategory,
      classMaterial: topicFiles,
    }));
  }, [selectedGSELevel, topicFiles]);

  const verifyRequiredFields = (data: TopicData): boolean => {
    const enLangData = data.languageData.find((lData) => lData.language === 'en_us');
    if (!data.GSELevel) {
      return false;
    }
    if (!data.category) {
      return false;
    }
    if (!enLangData?.description || !enLangData?.title || !enLangData.mondlyTopic) {
      return false;
    }
    if (
      !data.languageData.find((langData) => langData.language === selectedLanguage.code)
        ?.description
    ) {
      return false;
    }
    if (!data.languageData.find((langData) => langData.language === selectedLanguage.code)?.title) {
      return false;
    }
    if (data.classMaterial && !data.classMaterial.length) {
      return false;
    }
    
    return true; // All checks passed
  };

  const handleChangeCustom = (name: string, value: string) => {
    if (name === 'title' || name === 'description') {
      const updatedTopic = { 
        ...topicData,
        languageData: [...topicData.languageData] 
      };
      let langIndex = updatedTopic.languageData.findIndex(
        (langData) => langData.language === selectedLanguage.code,
      );
      if (langIndex === -1) {
        //New Language update
        langIndex = updatedTopic.languageData.push({
          language: selectedLanguage.code,
          description: '',
          title: '',
        });
        updatedTopic.languageData[langIndex - 1][name] = value;
      } else {
        updatedTopic.languageData[langIndex] = {
          ...updatedTopic.languageData[langIndex],
          [name]: value
        };
      }

      setTopicData(updatedTopic);
    } else {
      setTopicData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleLanguageChange = async (language: Language) => {
    setSelectedLanguage(language);
    const currentMondlyTopic = topicData.languageData.find(
      (lData) => lData.language === selectedLanguage.code,
    )?.mondlyTopic;
    if (
      topicData.id &&
      currentMondlyTopic?.mondlyCategory &&
      !topicData.languageData.some((lData) => lData.language === language.code)
    ) {
      const langData = await getLangData(
        topicData.id,
        language.code,
        currentMondlyTopic?.mondlyCategory,
        userToken,
      );
      // TODO - Check the logic here
      topicData.languageData.push({
        id: langData.id,
        title: langData?.title || '',
        description: langData?.description || '',
        mondlyTopic: langData?.mondlyTopic,
        language: langData?.lang || language.code,
      });

      // handleMondlyTopicChange(langData?.mondlyTopic, language.code)
      setTopicData({ ...topicData });
      // setSelectedMondlyCategory(langData?.mondlyTopic?.mondlyCategory);
    }
  };

  const handleContentSensitivity = (sensitivity: string, value: string) => {
    setSelectedContentSensititvity(value);
    const updatedTopicContentSensitivity = value === 'Standard' ? false : true;
    setTopicData((prevData) => ({
      ...prevData,
      contentSensitivity: updatedTopicContentSensitivity,
    }));
  };

  const handleEditCreateTopic = () => {
    if (!topic?.id) {
      setTopic(topicData as Topic);
    }
    topicMutation.mutate();
  };

  const handleGSEChange = (name: string, value: number[]) => {
    setSelectedGSELevel({ minGSE: value[0], maxGSE: value[1] });
  };

  // useEffect(() => { 
  //   console.log('boom', topic);
  // },[topic])

  const toggleTutorFacing = (fileName: string) => {
    setTopicFiles((prevTopicFiles) => {
      return prevTopicFiles.map((file) => {
        if (file.file === fileName) {
          return {
            ...file,
            upload: !file.upload, // Toggle the upload property
          };
        }
        return file;
      });
    });
  };

  const removeTopicFileByName = (fileName: string) => {
    setTopicFiles((prevTopicFiles) => {
      return prevTopicFiles.filter((file) => file.file !== fileName);
    });
  };

  const handleMondlyTopicChange = (mTopic: MondlyTopic, language: string) => {
    const updatedTopic = { ...topicData };
    const index = updatedTopic.languageData.findIndex(
      (langData) => langData?.language === language,
    );
    if (index === -1) {
      const indx = updatedTopic.languageData.push({
        language: selectedLanguage.code,
        mondlyTopic: mTopic,
      });

      updatedTopic.languageData[indx - 1].mondlyTopic = mTopic;
    } else {
      updatedTopic.languageData[index].mondlyTopic = mTopic;
    }
    if (mTopic && mTopic.mondlyCategory) {
      setSelectedMondlyCategory(mTopic.mondlyCategory);
    }
    setTopicData(updatedTopic);
  };

  return (
    <section className="topic-edit-section">
      <div className="topic-edit-container">
        <BackButton
          navigatePath={topic ? '/topicview' : '/topics'}
          onClick={handleBackButtonClick}
        />
        <div className="topic-title">{topic && topic.id ? 'Edit topic' : 'Add topic'}</div>
        <div className="topic-edit-translation-container">
          <div className="topic-edit-translation-title">
            <LanguageIcon />
            <div className="topic-edit-titles">Translation language</div>
          </div>
          <LanguageSelect
            onLanguageChange={(lang) => handleLanguageChange(lang)}
            language={selectedLanguage}
          />
          {selectedLanguage.code != 'en_us' && (
            <span style={{ color: 'red', fontSize: '13px' }}>*English is mandatory</span>
          )}
        </div>
        <SingleSelectBtns
          onChange={handleContentSensitivity}
          value={selectedContentSensitivity}
          label={'Content sensitivity'}
          name={'contentSensitivity'}
          selectIcons={['reportOffIcon', 'reportIcon']}
          selectOptions={Object.values(contentSensitivity)}
        />

        <div className="topic-edit-data-container">
          <div className="topic-edit-title-container">
            <TextFieldCustom
              label="Title"
              name="title"
              value={
                topicData.languageData.find((lData) => lData.language === selectedLanguage.code)
                  ?.title || ''
              }
              onChange={handleChangeCustom}
            />
          </div>
          <div className="topic-edit-title-container">
            <TextFieldCustom
              label="Description"
              name="description"
              value={
                topicData.languageData.find((lData) => lData.language === selectedLanguage.code)
                  ?.description || ''
              }
              onChange={handleChangeCustom}
              multiline={true}
              rows={5}
            />
          </div>
          {Categories && (
            <div className="topic-edit-title-container">
              <SingleSelectBtns
                onChange={handleCategoryChange}
                value={selectedCategory}
                label={'Category'}
                name={'category'}
                selectOptions={Categories.map((category) => category.category)}
              />
            </div>
          )}
          <GseSlider
            handleGSEChange={handleGSEChange}
            value={[selectedGSELevel.minGSE, selectedGSELevel.maxGSE]}
            name={'gseRange'}
          />
          <div className="topic-edit-title-container" style={{ width: 466 }}>
            {mondlyTopics && (
              <MondlyTopicSelect
                onMondlyTopicChange={handleMondlyTopicChange}
                selectedLanguage={selectedLanguage.code}
                selectedMondlyCategory={selectedMondlyCategory}
              />
            )}
          </div>

          <DynamicButton addIcon size="medium" type="secondary">
            <input
              hidden
              multiple
              type="file"
              onChange={(e) => {
                handleFileChange(e);
              }}
              ref={fileInputRef}
            />
            Add file
          </DynamicButton>
          <div className="file-list">
            <div className="topic-files loaded flex column gap-8">
              {topicFiles &&
                topicFiles.length > 0 &&
                topicFiles.map(
                  (file, index) =>
                    (!uploadingFiles[file.file] || uploadingFiles[file.file] >= 100) && (
                      <FileUploadProgress
                        key={`${index}-${file.file}-${topic?.id}`}
                        fileType={getFileType(file.file)}
                        uploadProgress={100}
                        fileName={file.file}
                        isTutorFacing={file.upload}
                        toggleTutorFacing={toggleTutorFacing}
                        removeTopicFileByName={removeTopicFileByName}
                      />
                    ),
                )}
            </div>
            <div
              className="topic-files in-progress flex column gap-8"
              style={{ marginTop: '16px' }}
            >
              {Object.keys(uploadingFiles).map(
                (fileName, index) =>
                  uploadingFiles[fileName] < 100 && (
                    <FileUploadProgress
                      key={`${index}-${fileName}-${topic?.id}`}
                      fileType={getFileType(fileName)}
                      uploadProgress={uploadingFiles[fileName]}
                      fileName={fileName}
                      isTutorFacing
                      removeTopicFileByName={removeTopicFileByName}
                    />
                  ),
              )}
            </div>
          </div>
        </div>
        <div className="topic-edit-action-btns">
          <div className="submit-btns">
            <DynamicButton
              size="large"
              type="primary"
              onClick={handleEditCreateTopic}
              disable={!verifyRequiredFields(topicData)}
            >
              {topic ? 'Save changes' : 'Create topic'}
            </DynamicButton>
            <DynamicButton size="large" type="tertiary" onClick={handleBackButtonClick}>
              Cancel
            </DynamicButton>
          </div>
        </div>
      </div>
    </section>
  );
};
export default TopicAdd;
