import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface SessionTableTabsProps {
  tabValue: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  allSessionsLength: number | undefined;
  completedSessionsLength: number | undefined;
  liveSessionsLength: number | undefined;
  upcomingSessionsLength: number | undefined;
}

export default function SessionTableTabs({
  tabValue,
  handleTabChange,
  allSessionsLength,
  completedSessionsLength,
  liveSessionsLength,
  upcomingSessionsLength,
}: SessionTableTabsProps) {
  return (
    <Box>
      <Box>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => handleTabChange(event, newValue)}
          aria-label="basic tabs example"
          sx={{
            button: {
              '& .MuiTabs-indicator': {
                color: 'red',
              },
              color: '#151515',
              fontFamily: 'OpenSans',
              textTransform: 'unset',
              '&.Mui-selected': {
                color: '#151515',
                fontWeight: '600',
              },
            },
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: '#151515',
            },
          }}
        >
          <Tab label={`All (${allSessionsLength})`} />
          <Tab label={`Complete (${completedSessionsLength})`} disabled={!allSessionsLength} />
          <Tab label={`Live (${liveSessionsLength})`} disabled={!allSessionsLength} />
          <Tab label={`Pending (${upcomingSessionsLength})`} disabled={!allSessionsLength} />
        </Tabs>
      </Box>
    </Box>
  );
}
