import { useQuery } from '@tanstack/react-query';
import { getPrivateSessionList } from '../utils/Network';
import { Session } from '@utils/types';
import { TIME } from '../utils/constants';
import dayjs from 'dayjs';

const STALE_TIME = TIME.minute * 5;
const CACHE_TIME = TIME.minute + 5 * TIME.second;

const useFetchPrivateDailySessions = (
  userToken: string,
  groupId: number,
  date: Date | string | dayjs.Dayjs,
  offset?: number,
) =>
  useQuery<Session[]>({
    queryKey: ['sessions', dayjs(date).format('YYYY-MM-DD'), groupId],
    queryFn: () => getPrivateSessionList(userToken, groupId, date, offset ? offset : 0),
    staleTime: STALE_TIME,
    gcTime: CACHE_TIME,
    enabled: !!userToken,
    refetchOnWindowFocus: false,
  });

export default useFetchPrivateDailySessions;
