import { useEffect, createContext, useContext, ReactNode, useState } from 'react';
import { Children } from '../utils/types';
import { findFirstChildWithClassSize } from '../utils/utilsts';
import { getGroupById } from '../utils/Network';
import { useUserContext } from '../components/Providers/UserContext';
import useFetchPrivateGroups from '../Query/useFetchPrivateGroups';

// Create a context
type PrivateGroupsContextType = {
  selectedGroup: Children | null;
  allOrgs: Children[] | null;
  PendingGroups: boolean;
  setSelectedGroup: (group: Children) => void;
  setSelectedGroupById: (groupId: number, rootId: number) => void;
  firstGroupId: number | null;
  selectedOrg: Children | null;
  setGroupByIdOrgs: (groupId: number, children: Children[]) => void;
};

const PrivateGroupsContext = createContext<PrivateGroupsContextType | undefined>(undefined);

// Create a provider component
type PrivateGroupsProviderProps = {
  children: ReactNode;
};

export const PrivateGroupsProvider = ({ children }: PrivateGroupsProviderProps) => {
  const [selectedGroup, setSelectedGroup] = useState<Children | null>(null);
  const [selectedOrg, setSelectedOrg] = useState<Children | null>(null);
  const [firstGroupId, setFirstGroupId] = useState<number | null>(null);
  const { userToken } = useUserContext();
  const {
    data: allOrgs = [],
    error: GroupsError,
    isPending: PendingGroups,
  } = useFetchPrivateGroups(userToken);

  if (GroupsError) {
    console.log('An error has occurred: ' + GroupsError.message);
  }

  const setSelectedGroupById = async (groupId: number, rootId: number) => {
    if (allOrgs?.length) {
      const fetchedGroup = await getGroupById(groupId, rootId, userToken);
      if (fetchedGroup) {
        setSelectedGroup(fetchedGroup);
        setSelectedOrg(allOrgs.find((org) => org.id === fetchedGroup.rootId) || null);
      }
    }
  };

  const getFirstGroupId = () => {
    if (allOrgs) {
      const firstGroupWithClassSize = findFirstChildWithClassSize(allOrgs[0]);
      if (firstGroupWithClassSize) {
        const firstGroupId = firstGroupWithClassSize ? firstGroupWithClassSize.id : allOrgs[0].id;
        if (firstGroupId) {
          setFirstGroupId(firstGroupId);
        }
      }
    }
  };

  const findGroupInSubGroups = (subOrgs: Children[], groupId: number, parentId: number) => {
    const foundParent = subOrgs.find((el) => el.id === parentId);
    if (foundParent && foundParent.children) {
      return foundParent.children.find((el) => el.id === groupId);
    }
    return null;
  };

  // refresh group if allOrgs changes/updates!
  useEffect(() => {
    if (selectedGroup?.organizationName && allOrgs.length) {
      const rootNode = allOrgs.find((org) => org.id === selectedGroup.rootId);
      if (rootNode && rootNode.children && rootNode.children.length > 0 && selectedGroup.parentId) {
        const freshGroup = findGroupInSubGroups(
          rootNode.children,
          selectedGroup.id,
          selectedGroup.parentId,
        );
        if (freshGroup) {
          setSelectedGroup(freshGroup);
          setSelectedOrg(rootNode);
        }
      }
    }
    getFirstGroupId();
  }, [allOrgs]);

  const setGroupByIdOrgs = (groupId: number, children: Children[]) => {
    children.forEach((child) => {
      if (child.id === groupId) {
        setSelectedGroup(child);
        const org = allOrgs.find((org) => org.id === child.rootId);
        if (org) {
          setSelectedOrg(org);
        }
      } else {
        if (child.classSize === null && child.children && child.children.length > 0) {
          setGroupByIdOrgs(groupId, child.children);
        }
      }
    });
  };

  // verify selectedOrg and selectedGroup are aligned:
  useEffect(() => {
    if (
      (!selectedOrg && selectedGroup) ||
      (selectedGroup && selectedOrg && selectedGroup.rootId !== selectedOrg.id)
    ) {
      const updatedOrg = allOrgs.find((org) => org.id === selectedGroup.rootId);
      if (updatedOrg) {
        setSelectedOrg(updatedOrg);
      }
    }
  }, [allOrgs, selectedGroup]);

  return (
    <PrivateGroupsContext.Provider
      value={{
        allOrgs,
        setSelectedGroupById,
        selectedGroup,
        setSelectedGroup,
        PendingGroups,
        firstGroupId,
        selectedOrg,
        setGroupByIdOrgs,
      }}
    >
      {children}
    </PrivateGroupsContext.Provider>
  );
};

// Custom hook to use the PrivateGroups context
export const usePrivateGroupsContext = () => {
  const context = useContext(PrivateGroupsContext);
  if (!context) {
    throw new Error('usePrivateGroupsContext must be used within a PrivateGroupsProvider');
  }
  return context;
};
