import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DynamicButton from '../common/DynamicButton';
import Loader from '../common/Loader';
import SessionDashboardDateNav from '../Session/SessionDashboardDateNav';
import SessionDashboardTable from '../Session/SessionDashboardTable';
import { useTimeZoneContext } from '../../providers/TimeZoneProvider';
import { useUserContext } from '../Providers/UserContext';
import useFetchPrivateDailySessions from '../../Query/useFetchPrivateDailySession';
import useFetchPrivateGroupHasSessionsById from '../../Query/useFetchPrivateGroupHasSessionsById';
import { Children } from '../../utils/types';

interface GroupScheduleProps {
  groupData: Children;
  orgData: Children;
  setTabValue: (val: number) => void;
}

const GroupSchedule = ({ groupData, orgData, setTabValue }: GroupScheduleProps) => {
  const { hasRole, userToken } = useUserContext();
  const { timeZone, setTimeZone, privateGroupScheduleDate, setPrivateGroupScheduleDate } =
    useTimeZoneContext();
  const [timezoneOffset, setTimezoneOffset] = useState<number>(0);
  const navigate = useNavigate();

  const {
    data: groupHasSessions = false,
    error: GroupsError,
    isPending: isFetching,
  } = useFetchPrivateGroupHasSessionsById(groupData.id, userToken);

  if (GroupsError) {
    console.log('An error has occurred: ' + GroupsError.message);
  }

  const getOffset = (timeZone: string) => {
    try {
      const offset = dayjs.utc(new Date()).tz(timeZone).utcOffset() / 60;
      setTimezoneOffset(offset);
    } catch (error) {
      // console.error('Error occurred while getting offset:', error);
      // return initial values:
      setTimezoneOffset(0);
    }
  };

  useEffect(() => {
    if (groupData.timezone) {
      getOffset(groupData.timezone);
      setTimeZone(groupData.timezone);
    }
  }, [groupData.timezone]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    data: dailyGroupSessions,
    isPending,
    error,
  } = useFetchPrivateDailySessions(
    userToken,
    groupData.id,
    privateGroupScheduleDate,
    timezoneOffset,
  );

  if (error) {
    console.log('An error has occurred: ' + error.message);
  }

  const isNoMandatoryFields =
    !groupData.GSELevels?.length || !groupData.locations?.length || !orgData?.margin;

  const handleTimeZoneChange = (selectedTimezone: string) => {
    setTimeZone(selectedTimezone);
  };

  return (
    <section className="group-schedule-section flex column">
      {isFetching && (
        <div style={{ paddingTop: '100px' }}>
          <Loader />
        </div>
      )}
      {!isFetching && isNoMandatoryFields && (
        <div className="fill-mandatory flex column flex-1 align-center">
          <p>
            Complete the missing details in the
            <span style={{ fontStyle: 'italic' }}>&nbsp;License details&nbsp;&nbsp;</span>
            tab to schedule sessions
          </p>
          <DynamicButton size="small" type="primary" onClick={() => setTabValue(1)}>
            Complete license details
          </DynamicButton>
        </div>
      )}
      {/* case 2: group has mandaoty field, but no sessions: */}
      {!isFetching && !isNoMandatoryFields && !groupHasSessions && (
        <div className="fill-mandatory flex column flex-1 align-center">
          <p>There are no sessions scheduled for this group</p>
          {orgData && orgData.licenses && orgData.licenses.length > 0 && (
            <div className="add-sessions-to-group flex gap-8">
              <DynamicButton
                size="small"
                type="primary"
                onClick={() => navigate('addmultipleprivatesession')}
              >
                <CalendarMonthOutlinedIcon />
                Add multiple sessions
              </DynamicButton>
              <DynamicButton
                size="small"
                type="secondary"
                addIcon
                onClick={() => navigate(`/privatesessions/${groupData.id}/addprivatesession`)}
              >
                Add single session
              </DynamicButton>
            </div>
          )}
        </div>
      )}
      {!isFetching && !isNoMandatoryFields && groupHasSessions && (
        <div className="session-dashboard-table-nav-container">
          {hasRole('Admin') && (
            <>
              <SessionDashboardDateNav
                setDailySessionsDate={setPrivateGroupScheduleDate}
                dailySessionsDate={privateGroupScheduleDate}
                groupId={groupData.id}
                orgData={orgData}
                isPrivateGroups
                groupTimezoneOffset={timezoneOffset}
              />
              <SessionDashboardTable
                sessions={dailyGroupSessions}
                isLoading={isPending}
                tableTimezone={timeZone}
                handleTimeZoneChange={handleTimeZoneChange}
              />
            </>
          )}
        </div>
      )}
    </section>
  );
};

export default GroupSchedule;
