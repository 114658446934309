import {
  createContext,
  useCallback,
  useContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { SessionData } from '@pages/SessionAdd';
import { getSessionList } from '../utils/Network';
import { useUserContext } from '../components/Providers/UserContext';
import { Session } from '../utils/types';

// Create a context
type SessionContextType = {
  session: SessionData | null;
  setSession: Dispatch<SetStateAction<SessionData | null>>;
  sessionsList: Session[] | null;
  updateSessionList: (date: Date | string) => void;
};

const SessionContext = createContext<SessionContextType | undefined>(undefined);

// Create a provider component
type SessionProviderProps = {
  children: ReactNode;
};

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const [session, setSession] = useState<SessionData | null>(null);
  const [sessionsList, setSessionsList] = useState<Session[] | null>(null);
  const { userToken } = useUserContext();

  // const fetchData = async () => {
  //   const dailySessions = await getSessionList(userToken);
  //   setSessionsList(dailySessions);
  // };

  // useEffect(() => {
  //   if (userToken) {
  //     fetchData();
  //   }
  // }, []);

  const updateSessionList = useCallback(async (date: Date | string) => {
    if (userToken) {
      const dailySessions = await getSessionList(userToken, date);
      setSessionsList(dailySessions);
    }
  }, []);

  return (
    <SessionContext.Provider value={{ session, setSession, sessionsList, updateSessionList }}>
      {children}
    </SessionContext.Provider>
  );
};

// Custom hook to use the session context
export const useSessionContext = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSessionContext must be used within a SessionProvider');
  }
  return context;
};
