import {
  createContext,
  useContext,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Topic } from "../utils/types";
import { useUserContext } from "../components/Providers/UserContext";
import useFetchTopics from "Query/useFetchTopics";

// Create a context
type TopicContextType = {
  topic: Topic | null;
  setTopic: Dispatch<SetStateAction<Topic | null>>;
  setTopicById: (id: number) => void;
};

const TopicContext = createContext<TopicContextType | undefined>(undefined);

// Create a provider component
type TopicProviderProps = {
  children: ReactNode;
};

export const TopicProvider = ({ children }: TopicProviderProps) => {
  const [topic, setTopic] = useState<Topic | null>(null);
  const { userToken } = useUserContext();
  const { data: allTopics } = useFetchTopics(userToken);

  const setTopicById = (id: number) => {
    if (allTopics?.length) {
      const chosenTopic = allTopics.find((topic) => topic.id === id);
      if (chosenTopic) {
        setTopic(chosenTopic);
      }
    }
  };

  return (
    <TopicContext.Provider value={{ topic, setTopic, setTopicById }}>
      {children}
    </TopicContext.Provider>
  );
};

// Custom hook to use the topic context
export const useTopicContext = () => {
  const context = useContext(TopicContext);
  if (!context) {
    throw new Error("useTopicContext must be used within a TopicProvider");
  }
  return context;
};
