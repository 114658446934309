import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

interface TextFieldCustomProps {
  onChange: (name: string, value: string) => void;
  value?: string;
  label?: string;
  name: string;
  error?: boolean;
  helperText?: string | undefined;
  multiline?: boolean;
  rows?: number;
  isNumber?: boolean;
}

const TextFieldCustom: React.FC<TextFieldCustomProps> = ({
  onChange,
  value,
  label,
  name,
  error = false,
  helperText = undefined,
  multiline = false,
  rows = 1,
  isNumber = false,

}: TextFieldCustomProps) => {
  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    onChange(name, value);
  };

  const textFieldSx = { margin: "0" };

  return (
    <section className="textfield-custom">
      <Box sx={{ minWidth: 120, margin: "16px 0 8px" }}>
        <FormControl fullWidth>
          {label && <p className="textfield-label">{label}</p>}
          <TextField
            type= {isNumber ? 'number' : ''}
            sx={textFieldSx}
            variant="outlined"
            name={name}
            value={value}
            onChange={(e) => {
              handleChange(e);
            }}
            required
            fullWidth
            margin="normal"
            error={error}
            multiline={multiline}
            helperText={helperText}
            rows={rows}
          />
        </FormControl>
      </Box>
    </section>
  );
};

export default TextFieldCustom;
