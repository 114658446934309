import { useNavigate } from 'react-router-dom';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
import ReportSharpIcon from '@mui/icons-material/ReportSharp';
import { useUserContext } from '../Providers/UserContext';
import { useTopicContext } from '../../providers/TopicContext';
import { Topic, contentSensitivity } from '../../utils/types';
import { tutoringBasePath } from '../../utils/Network';

interface TopicCardProps {
  topic: Topic;
}

export const TopicCard = ({ topic }: TopicCardProps) => {
  const { hasRole } = useUserContext();
  const { setTopic } = useTopicContext();
  const navigate = useNavigate();
  return (
    <section
      key={topic.id}
      className="topic-card-section"
      onClick={() => {
        if (hasRole('Admin')) {
          setTopic(topic);
          navigate('/topicview');
        }
      }}
    >
      <div style={{ padding: 20 }}>
        <div className="topic-title">
          <div className="flex">
            {topic.languageData?.find((t) => t.language === 'en_us')?.title}
          </div>

          <div className="topic-sensitivity">
            {topic.contentSensitivity ? <ReportSharpIcon /> : <ReportOffOutlinedIcon />}

            {topic.contentSensitivity ? contentSensitivity.Adapted : contentSensitivity.standard}
          </div>
        </div>
        <div style={{ display: 'flex', gap: 30 }}>
          <div style={{ width: 260, flexShrink: 0 }}>
            <div style={{ marginBottom: 10, fontWeight: 500 }}>
              {topic.category?.category}: {topic.GSELevel?.minGSE} - {topic.GSELevel?.maxGSE}
            </div>
          </div>
        </div>
        <div style={{ whiteSpace: 'pre-wrap', marginBottom: '20px' }}>
          {topic.languageData?.find((t) => t.language === 'en_us')?.description}
        </div>
        <div className="topic-file-list">
          {topic &&
            topic.classMaterial &&
            topic.classMaterial.map((file) => {
              return (
                <div
                  key={`${file.topicId}-${file.id}`}
                  className="topic-file"
                  title={file.file}
                  onClick={(e) => {
                    e.stopPropagation();
                    const fileUrl = `${tutoringBasePath()}/assets/ClassMaterial/${file.file}`;
                    window.open(fileUrl, '_blank')?.focus();
                  }}
                >
                  {file.file}
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};
