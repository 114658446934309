import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Children } from '../../utils/types';
import LicenseDetails from './LicenseDetails';
import GroupSchedule from './GroupSchedule';
import GradientLoader from '../common/Loader';
import { usePrivateGroupsContext } from '../../providers/privateGroupsContext';

interface PrivateGroupsHomeProps {
  PendingGroups: boolean;
  selectedParentMinInfo: Children | null;
  isScheduleTab: boolean;
}

const PrivateGroupsHome = ({
  PendingGroups,
  selectedParentMinInfo,
  isScheduleTab,
}: PrivateGroupsHomeProps) => {
  const { selectedGroup, selectedOrg, allOrgs } = usePrivateGroupsContext();
  const [tabValue, setTabValue] = useState(isScheduleTab ? 0 : 1);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  return (
    <div
      className="private-group-screens flex column"
      style={{ flex: '1', width: '890px', paddingTop: '36px', background: 'white' }}
    >
      <div
        className="private-group-section"
        style={{
          marginInline: 'auto',
          width: '905px',
        }}
      >
        <h1>{selectedGroup?.name}</h1>
        <div className="private-group-tabs" style={{ borderBottom: '1px solid #DFE1E1' }}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => handleTabChange(event, newValue)}
            aria-label="basic tabs example"
            sx={{
              button: {
                color: '#151515',
                fontFamily: 'OpenSans',
                textTransform: 'unset',
                '&.Mui-selected': {
                  color: '#151515',
                  fontWeight: '600',
                },
              },
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: '#9E007E',
                height: '4px',
                borderRadius: '2px 2px 0px 0px',
              },
            }}
          >
            <Tab label={'Schedule'} />
            <Tab label={'License details'} />
          </Tabs>
        </div>
        <div className="private-group-content">
          {PendingGroups && (
            <div
              className="flex column justify-center align-center"
              style={{ paddingTop: '136px' }}
            >
              <GradientLoader />
            </div>
          )}
          {tabValue === 1 && (
            <div>
              {selectedGroup && allOrgs && selectedOrg && (
                <LicenseDetails parentData={selectedParentMinInfo} />
              )}
            </div>
          )}
          {tabValue === 0 && (
            <div>
              {selectedGroup && allOrgs && selectedOrg && (
                <GroupSchedule
                  groupData={selectedGroup}
                  setTabValue={setTabValue}
                  orgData={selectedOrg}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrivateGroupsHome;
