import { useQuery } from "@tanstack/react-query";
import { tutoringApiUrl } from "../utils/Network";
import { Category } from "@utils/types";


const useFetchCategory = (token: string) => useQuery<Category[]>({
    queryKey: ['category'],
    queryFn: () =>
      fetch(`${tutoringApiUrl()}/_internal/getCategories`, 
      // fetch(`http://localhost:8080/_internal/getCategories`, 
      {method: 'Get', 
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }}).then((res) =>
        res.json(),
      ),
      staleTime: Infinity,
      gcTime: 3600 + 300,
  });

export default useFetchCategory;