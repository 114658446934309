import { useQueryClient, useMutation } from '@tanstack/react-query';
import { createMultipleSessions } from '../utils/Network';
import { TimeFrame, GSELevel } from '@utils/types';

type savePrivateSessionRequest = {
  groupId: number;
  timeFrame: TimeFrame;
  GSELevels: GSELevel[];
  userToken: string;
};

const _savePrivateSession = async (data: savePrivateSessionRequest) => {
  return createMultipleSessions(data.groupId, data.timeFrame, data.GSELevels, data.userToken);
};

const useSavePrivateSession = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: _savePrivateSession,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['privateGroups'],
      });
    },
  });
};

export default useSavePrivateSession;
