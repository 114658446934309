import { Autocomplete, TextField } from "@mui/material";
import { Tutor } from "../../utils/types";

interface TutorSelectProps {
  tutorList: Tutor[];
  onTutorChange: (tutor: Tutor | null) => void;
  value?: Tutor | null;
  optional?: boolean;
  disable?: boolean;
  disableMsg?: string;
}

const TutorSelect: React.FC<TutorSelectProps> = ({
  tutorList,
  optional = false,
  onTutorChange,
  value,
  disable = false,
  disableMsg,
}: // error = false,
TutorSelectProps) => {
  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: Tutor | null
  ) => {
    if (value) {
      onTutorChange(value);
    }
  };

  return (
    <section className="textfield-custom">
      <div className="flex gap-5">
        <p className="input-label">Tutor</p>
        {optional && <p className="input-label-optional">(optional)</p>}
      </div>
      <Autocomplete
        disabled={disable}
        value={value}
        onChange={(event, value) => handleChange(event, value)}
        options={tutorList}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        getOptionKey={(option) => option.id}
        renderInput={(params) => (
          <TextField {...params} fullWidth error={disable} />
        )}
      />
      {disable && disableMsg && (
        <span style={{ fontSize: "14px", color: "#919191" }}>{disableMsg}</span>
      )}
    </section>
  );
};

export default TutorSelect;
