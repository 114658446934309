import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import PrivateGroupsHome from '../components/private/PrivateGroupsHome';
import { useUserContext } from '../components/Providers/UserContext';
import { usePrivateGroupsContext } from '../providers/privateGroupsContext';
import { NestedSidebar } from '../components/private/NestedSidebar';
import PrivateSessionAdd from '../components/private/PrivateSessionAdd';
import AddMultipleSessionsPrivate from '../components/private/AddMultipleSessionsPrivate';
import { Children, Tutor, Topic } from '../utils/types';

interface PrivateSessionsProps {
  tutorList: Tutor[];
  topicList: Topic[];
}

const PrivateSessions = ({ tutorList, topicList }: PrivateSessionsProps) => {
  const navigate = useNavigate();
  const { hasRole } = useUserContext();
  const { allOrgs, PendingGroups, selectedGroup, setSelectedGroup } = usePrivateGroupsContext();
  const [selectedOrg, setSelectedOrg] = useState<Children | null>(null);
  const [selectedParentMinInfo, setSelectedParentMinInfo] = useState<Children | null>(null);
  const [isScheduleTab, setIsScheduleTab] = useState<boolean>(false);

  const handleSelectGroup = (
    orgId: number,
    subOrgId: number,
    subOrgName: string,
    group: Children,
  ) => {
    setSelectedGroup(group);
    const updatedOrg = allOrgs?.find((org) => org.id === orgId);
    if (updatedOrg) {
      setSelectedOrg(updatedOrg);
    }
    setSelectedParentMinInfo({ id: subOrgId, name: subOrgName });
    navigate(`/privatesessions/${group.id}`);
  };

  useEffect(() => {
    if (allOrgs && selectedGroup && (!selectedOrg || !selectedParentMinInfo)) {
      const rootOrg = allOrgs.find((org) => org.name === selectedGroup.organizationName);
      if (rootOrg) {
        setSelectedOrg(rootOrg);
      }
    }
    if (allOrgs && selectedGroup && !selectedParentMinInfo) {
      const subOrgInfo = allOrgs
        .filter((org) => org.children)
        .flatMap((org) => org.children)
        .find((subOrg) => subOrg?.id === selectedGroup.parentId);
      if (subOrgInfo) {
        setSelectedParentMinInfo({ id: subOrgInfo.id, name: subOrgInfo.name });
      }
    }
  }, [selectedGroup]);

  useEffect(() => {
    setIsScheduleTab(false);
  }, [selectedGroup]);

  // refresh selectedOrg data when allOrgs updates
  useEffect(() => {
    if (selectedOrg) {
      const updatedOrg = allOrgs?.find((org) => org.id === selectedOrg.id);
      if (updatedOrg) {
        setSelectedOrg(updatedOrg);
      }
    }
  }, [allOrgs]);

  return (
    <section className="private-group-section">
      {hasRole('Admin') && (
        <div
          className="private-sessions-wrapper flex"
          style={{
            background: '#F5F5F5',
          }}
        >
          <NestedSidebar handleSelectGroup={handleSelectGroup} />
          <Routes>
            <Route
              index
              element={
                <PrivateGroupsHome
                  PendingGroups={PendingGroups}
                  selectedParentMinInfo={selectedParentMinInfo}
                  isScheduleTab={isScheduleTab}
                />
              }
            />
            <Route
              path="/:groupId"
              element={
                <PrivateGroupsHome
                  PendingGroups={PendingGroups}
                  selectedParentMinInfo={selectedParentMinInfo}
                  isScheduleTab={isScheduleTab}
                />
              }
            />
            <Route
              path="addprivatesession"
              element={
                <PrivateSessionAdd
                  tutorList={tutorList}
                  topicList={topicList}
                  setIsScheduleTab={setIsScheduleTab}
                  selectedOrg={selectedOrg}
                />
              }
            />
            <Route
              path="addmultipleprivatesession"
              element={
                <AddMultipleSessionsPrivate
                  // orgData={selectedOrg}
                  setIsScheduleTab={setIsScheduleTab}
                />
              }
            />
          </Routes>
        </div>
      )}
    </section>
  );
};

export default PrivateSessions;
